<template>
  <div>
    <div class="">
      <div class="wrapper">
      <div class="flex flex-col linear-container px-6 md:px-14 py-5 md:py-12">

        <div class="flex">
          <img src="@/assets/white-back.svg" @click="goBack" style="cursor: pointer;" />
          <h3 class="header-text josefin_font">Provider Legal</h3>
        </div>
        <p class="py-3 m-0 text-white font-weight-light w8-12 sub_header">
          We stay up to date on current legal news and industry regulations to keep our members in the know. 
        </p>
      </div>
</div>
      <div class="comp_container flex flex-col md:flex-row">
          <div class="w-full md:w-2/4">

            <section class="improve_efficiency mt-40-pb-0">
              <h5 class="sub-heading josefin_font">About Provider Legal</h5>

              <p class="font-weight-normal provider_legal_sub-heading mt-8-pb-0" v-if="profile.subscriptionPlan === 'Essentials'">
                Healthcare providers face enormous pressure to provide great care, take care of their employees, keep
                the lights on, and run a successful practice. Provider Legal is dedicated to helping providers avoid
                liability, defend them when needed, and help their practice grow.
              </p>

              <p class="font-weight-normal provider_legal_sub-heading mt-8-pb-0" v-else>
                Healthcare providers face enormous pressure to provide great care, take care of their employees, keep
                the lights on, and run a successful practice. Provider Legal is dedicated to helping providers avoid
                liability, defend them when needed, and help their practice grow.
              </p>

              <div class="gray-text mt-8-pb-0">
                <p class="text" style="color: #666666;">Matt Stevens Esq</p>
              </div>

              <div id="hubspotForm" class="mt-24-pb-0" v-once></div>
            </section>

          </div>
          <div class="w-full md:w-2/4 flex justify-center mt-30">
            <img src="@/assets/legal.svg" alt="" class="legal_image">
          </div>
       
      </div>
      <div class=" flex flex-col md:flex-row pb-40">
        <div class="w-full p-0 md:w-4/4">
            <!-- <div class="pt-2" v-if="profile.subscriptionPlan !== 'Essentials'">
              <span @click="loadCalendlyLink" class="underline membershipLink">Schedule Legal Free Consultation</span>
            </div>
          </section> -->
        <!-- <div class="comp_container flex flex-col md:flex-row">
           <div class="w-full md:w-1/4 mt-10">
              <section class="improve_efficiency w-full">
                 <h5 class="sub-heading josefin_font">About Provider Legal</h5>
                 <p class="font-weight-normal gray-text provider_legal_sub-heading" v-if="profile.subscriptionPlan === 'Essentials'">
                    Healthcare providers face enormous pressure to provide great care, take care of their employees, keep
                    the lights on, and run a successful practice. Provider Legal is dedicated to helping providers avoid
                    liability, defend them when needed, and help their practice grow.
                 </p>
                 <p class="font-weight-normal gray-text provider_legal_sub-heading" v-else>
                    Healthcare providers face enormous pressure to provide great care, take care of their employees, keep
                    the lights on, and run a successful practice. Provider Legal is dedicated to helping providers avoid
                    liability, defend them when needed, and help their practice grow.
                 </p>
                 <div class="gray-text pb-6">
                    <p class="text" style="color: #666666;">Matt Stevens Esq</p>
                 </div>
                 <div id="hubspotForm" v-once></div>
              </section>
           </div>
           <div class="w-full md:w-4/4 flex mt-10 justify-center">
              <img src="@/assets/legal.svg" alt="" class="legal_image">
           </div>
        </div> -->
        <div class="comp_container flex flex-col md:flex-row mt-5">
           <div class="w-full md:w-4/4">
              <div class="mt-5 w-full p-0">
                 <section class="" v-if="profile.subscriptionPlan === 'Essentials'">
                    <h3 class="sub-heading josefin_font">Need to Speak to Our Legal Team?</h3>
                    <p class="font-weight-normal gray-text mt-8-pb-0">
                       Whether you have questions regarding telehealth, employee issues, or general medical practice provider
                       legal questions, our FAQs (found below) provide answers to many of today’s most common medical practice issues in telehealth.
                       You may ask our legal department questions using our online's “Ask a Question” form (found above). When you upgrade to our
                       <span @click="goToMemberShipPage" class="membershipLink">Enhanced Member Subscription</span> it includes
                       up to 1 hr. per month in phone consultations.
                    </p>
                 </section>
                 <section class="" v-else>
                    <h3 class="sub-heading josefin_font">Need to Speak to Our Legal Team?</h3>
                    <p class="font-weight-normal gray-text mt-8-pb-0">
                       Whether you have questions regarding telehealth, employee issues, or general medical practice provider
                       legal questions, our FAQs (found below) provide answers to many of today’s most common medical practice issues in
                       telehealth. You may ask our legal department questions using our online “Ask a Question” form(found above) or using
                       the <span @click="loadCalendlyLink" class="underline membershipLink">appointment scheduler</span> to schedule a 20-minute phone consultation.
                       Your {{ subscriptionPlan }} membership provides up to 1 hr. per month in provider legal phone consultations.
                    </p>
                 </section>
                 <!-- <div class="pt-2" v-if="profile.subscriptionPlan !== 'Essentials'">
                    <span @click="loadCalendlyLink" class="underline membershipLink">Schedule Legal Free Consultation</span>
                    </div>
                    </section> -->
                 <h3 class="mt-5 sub-heading josefin_font mt-40-pb-0">Frequently Asked Questions</h3>
                 <h5 class="faq-header-text py-2 indent_block mt-8-pb-0">HIPAA and Privacy Concerns</h5>
                 <div v-for="(item, index) in faqContent" :key="index" class="py-2">
                    <div class="faq_containter py-2 indent_block">
                       <div @click="handleClick(item)" style="margin-right: 18px;">
                          <img src="@/assets/minus.svg" alt="" v-if="selectedItem == item && selectedItem != null" class="icon">
                          <img src="@/assets/plus.svg" alt="" v-else class="icon">
                       </div>
                       <p class="text">{{ item.question }}</p>
                    </div>
                    <div class="accordion" v-if="selectedItem == item && selectedItem != null">
                       <p class="pt-2 faq-answer-text">
                          {{ item.answer }}
                       </p>
                    </div>
                 </div>
                 <h5 class="mt-5 faq-header-text py-2 indent_block">Patient Consent</h5>
                 <div v-for="(i, index) in faqContent2" :key="index" class="py-2">
                    <div class="faq_containter py-2 indent_block">
                       <div @click="handleClick2(i)" class="space">
                          <img src="@/assets/minus.svg" alt="" v-if="selectedItem2 == i && selectedItem2 != null" class="icon">
                          <img src="@/assets/plus.svg" alt="" v-else class="icon">
                       </div>
                       <p class="text">{{ i.question }}</p>
                    </div>
                    <div class="accordion" v-if="selectedItem2 == i && selectedItem2 != null">
                       <p class="pt-2 faq-answer-text">
                          {{ i.answer }}
                       </p>
                    </div>
                 </div>
                 <h5 class="mt-5 faq-header-text py-2 indent_block">Licensing</h5>
                 <div v-for="(i, index) in faqContent3" :key="index" class="py-2">
                    <div class="faq_containter py-2 indent_block">
                       <div @click="handleClick3(i)" class="space">
                          <img src="@/assets/minus.svg" alt="" v-if="selectedItem3 == i && selectedItem3 != null" class="icon">
                          <img src="@/assets/plus.svg" alt="" v-else class="icon">
                       </div>
                       <p class="text">{{ i.question }}</p>
                    </div>
                    <div class="accordion" v-if="selectedItem3 == i && selectedItem3 != null">
                       <p class="pt-2 faq-answer-text">
                          {{ i.answer }}
                       </p>
                       <a style="" :href="i.link" >{{i.link}}</a>
                    </div>
                 </div>
                 <h5 class="mt-5 faq-header-text py-2 indent_block">Malpractice Insurance</h5>
                 <div v-for="(i, index) in faqContent4" :key="index" class="py-2">
                    <div class="faq_containter py-2 indent_block">
                       <div @click="handleClick4(i)" class="space">
                          <img src="@/assets/minus.svg" alt="" v-if="selectedItem4 == i && selectedItem4 != null" class="icon">
                          <img src="@/assets/plus.svg" alt="" v-else class="icon">
                       </div>
                       <p class="text">{{ i.question }}</p>
                    </div>
                    <div class="accordion" v-if="selectedItem4 == i && selectedItem4 != null">
                       <p class="pt-2 faq-answer-text">
                          {{ i.answer }}
                       </p>
                       <a style="" :href="i.link" >{{i.link}}</a>
                    </div>
                 </div>
                 <h5 class="mt-5 faq-header-text py-2 indent_block">Reimbursement</h5>
                 <div v-for="(i, index) in faqContent5" :key="index" class="py-2">
                    <div class="faq_containter py-2 indent_block">
                       <div @click="handleClick5(i)" class="space">
                          <img src="@/assets/minus.svg" alt="" v-if="selectedItem5 == i && selectedItem5 != null" class="icon">
                          <img src="@/assets/plus.svg" alt="" v-else class="icon">
                       </div>
                       <p class="text">{{ i.question }}</p>
                    </div>
                    <div class="accordion" v-if="selectedItem5 == i && selectedItem5 != null">
                       <p class="pt-2 faq-answer-text">
                          {{ i.answer }}
                       </p>
                       <a style="" :href="i.link" >{{i.link}}</a>
                    </div>
                 </div>
                 <h5 class="mt-5 faq-header-text py-2 indent_block">Prescribing Through Telehealth</h5>
                 <div v-for="(i, index) in faqContent6" :key="index" class="py-2">
                    <div class="faq_containter py-2 indent_block">
                       <div @click="handleClick6(i)" class="space">
                          <img src="@/assets/minus.svg" alt="" v-if="selectedItem6 == i && selectedItem6 != null" class="icon">
                          <img src="@/assets/plus.svg" alt="" v-else class="icon">
                       </div>
                       <p class="text">{{ i.question }}</p>
                    </div>
                    <div class="accordion" v-if="selectedItem6 == i && selectedItem6 != null">
                       <p class="pt-2 faq-answer-text">
                          {{ i.answer }}
                       </p>
                       <a style="" :href="i.link" >{{i.link}}</a>
                    </div>
                 </div>
              </div>
              <!-- <p class="text">{{ i.question }}</p> -->
            </div>
            <div class="accordion" v-if="selectedItem6 == i && selectedItem6 != null">
              <p class="pt-2 faq-answer-text">
                {{ i.answer }}
              </p>
              <a style="" :href="i.link" >{{i.link}}</a>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        subscriptionPlan: '',
        firstName: '',
        lastName: '',
        email: '',
        selectedItem: null,
        selectedItem2: null,
        selectedItem3: null,
        selectedItem4: null,
        selectedItem5: null,
        selectedItem6: null,
        faqContent: [
          {
            question: 'What type of technology is allowed for face-to-face visits?',
            answer: 'Due to the pandemic the typical requirements for telehealth visits have been suspended. Any use of ' +
                'audio and/or video telecommunications system that permits real-time communication is allowed for ' +
                'face-to-face visits is permitted. In pre-pandemic times Office of Civil Rights (OCR, the body in charge ' +
                'of enforcing HIPAA) enforces requirements on the type of systems that could be used for telehealth. ' +
                'During the pandemic, they have given “discretion” in enforcing those guidelines. Those guidelines did ' +
                'not allow for zoom, facetime, and many of the other common video conferencing platforms. It’s unclear ' +
                'if those regulations will change at all as a result of the pandemic. When the pandemic is over it is ' +
                'possible that OCR will begin enforcement of HIPAA as normal and all telehealth services will need to be ' +
                'on HIPAA compliant platforms. Check back for updates as this topic evolves.'
          },
  
          {
            question: 'Can I record my face-to-face visit with the patient?',
            answer: 'Recorded sessions create a dangerous risk to the security of patient data, thus patient recording is ' +
                'not recommended unless you have a HIPAA compliant software platform. One issue here is that it is unclear ' +
                'how long the enforcement discretion will last and is limited to the use of non-HIPAA compliant software. ' +
                'But when you have recorded the session, where does that data go? Is that recorded session now required ' +
                'to be HIPAA compliant? I would argue that yes, that data should be stored in a HIPAA compliant format ' +
                'and that creates a host of other technical and privacy issues. Recording tele-health visits on non HIPAA ' +
                'compliant software brings significant risk. I recommend to not record your sessions unless your platform ' +
                'already has a built-in feature to record those sessions and store them in a HIPAA compliant format. ' +
                'Health providers should make sure that if they decide to record tele-health sessions that they ' +
                'specifically use HIPAA compliant software.'
          },
  
          {
            question: 'Does there have to be any special encryption for any saved data I do have?',
            answer: 'Technically no, but it is highly recommended. The security rule does not explicitly state that one ' +
                'needs to encrypt data but after a risk assessment, which is required by the HIPAA security rule, it’s ' +
                'almost always recommended.'
          },
  
          {
            question: 'Will I have to change the technology I use to see patients after the health emergency?',
            answer: 'That is unclear. Right now, OCR is using enforcement discretion and it is possible that all the same ' +
                'requirements will be enforced once the pandemic lifts. Those requirements are law. That being said, the ' +
                'pandemic will last for an extended period of time and the growth of telehealth and its efficacy is being ' +
                'demonstrated in ways that were not possible before.'
          }
        ],
  
        faqContent2: [
          {
            question: 'Do I need a special consent for patients I see in telehealth?',
            answer: 'Yes and no, any patient consent should spell out the risks and benefits of any type of medical visit. ' +
                'A small addition to the consent that spells out the risks and benefits of telehealth should be used.'
          }
        ],
  
        faqContent3: [
          {
            question: 'I’ve heard that because of COVID that I can see patients in any state, is that right?',
            answer: 'Kind of, that is a more complicated question than it appears to be. There are two issues to examine. ' +
                'First, are you licensed to see that patient? Second, will you get paid to see that patient and render ' +
                'services? This question of the FAQ will address licensing. Short answer, all state medical boards have ' +
                'waived some requirements or put in new procedures for out of state health providers to see patients of ' +
                'that state. Part of the reason I get asked this question a lot is because CMS has put out language stating ' +
                'that medical services can now be performed across state lines. But it is important to recognize that CMS ' +
                'only speaks to regulations for payment of Medicare services. Individual state boards are the body in each ' +
                'state that govern whether or not a health provider can practice in a given state. To fully answer that ' +
                'question a health provider needs to look at the information published by each state board.'
          },
  
          {
            question: 'How do I know if a state licensing board waiver applies to me?',
            answer: 'The Federation of Licensing Boards has a fantastic document outlining Covid Waivers as it pertains to ' +
                'licensing, here it is:',
            link: 'https://www.fsmb.org/siteassets/advocacy/pdf/states-waiving-licensure-requirements-for-telehealth-in-response-to-covid-19.pdf'
          },
  
          {
            question: 'Can I see a patient for the first time in a telehealth setting?',
            answer: 'Generally yes. This answer has two questions again, will the state licensing board that you have to ' +
                'answer to allow you to establish a patient relationship? Generally yes but please check. CMS used to have ' +
                'it as a requirement and for the duration of the pandemic once can establish a patient relationship for ' +
                'the first time via telehealth.'
          },
  
          {
            question: 'Can I get licensed in another state so that I can continue my health practice after the pandemic?',
            answer: 'Yes you can, depending on what kind of health provider you are you can get licensed in multiple ' +
                'states. It’s unclear if these waivers, CMS waivers, and OCR declination to enforce will play out post ' +
                'pandemic. We may see significant changes in how telehealth operates in the US generally or it may snap ' +
                'back to the same regulations that existed pre-pandemic. MD/DO: One can apply to each stated board, or ' +
                'for several states one can use the Intersate Medical Licensure Compact process. It is a streamlined ' +
                'process with one application format that expedites the process for licensure in many states. Mental ' +
                'Health: One can apply through PSYPACT, which is a Commission that has authority to actually grant one a ' +
                'license in that particular state. It has fewer states signed on to it but is still effective. Nursing: ' +
                'The Nursing Licensure Company is effective in the majority of the United States and once one has a ' +
                'license from there, one can practice in most of the states. Though please note that each state’s nursing ' +
                'board will have restrictions on the scope of practice for nursing in each state.'
          }
        ],
  
        faqContent4: [
          {
            question: 'Do I need to inform my carrier that I’m doing telehealth?',
            answer: 'One should always call their malpractice carrier when she/he is changing their scope of practice. ' +
                'More specifically, I’m a big advocate for having a strong relationship with your insurance broker and ' +
                'going to them for any questions or concerns. Using insurance can get complicated quickly and an ' +
                'experienced broker can help mitigate and inform you of many of the issues that can come up in your ' +
                'practice. Currently, many carriers do not have telehealth specific coverage for mal practice, so it’s ' +
                'important to check with your broker on what you’re covered for.'
          },
  
          {
            question: 'Is performing telehealth going to raise my malpractice rates?',
            answer: 'It can, though you should call your broker to have this conversation.',
          },
  
          {
            question: 'Is there a higher risk of malpractice because of telehealth?',
            answer: 'This is a more complicated question than it appears. I can’t speak as to whether malpractice is more ' +
                'likely due to telehealth, speak to your broker or clinical experts on telehealth for that. But I will say ' +
                'that if one is not licensed in a state or not practicing properly under a waiver, that can be a significant ' +
                'issue if a malpractice issue comes up.'
          }
        ],
  
        faqContent5: [
          {
            question: 'Can I get reimbursed for telehealth visits under Medicare?',
            answer: 'Yes, pursuant to authority granted by the Coronavirus Aid, Relief, and Economic Security Act (CARES Act), ' +
                'telehealth services now include all those that are eligible to bill Medicare for their professional services.'
          },
  
          {
            question: 'What services can I get reimbursed for under Medicare?',
            answer: 'Many mental health services were permanently added to what is reimbursable under Medicare because of ' +
                'the pandemic. In addition, CMS has added many other services. CMS updates the list of reimbursable services ' +
                'frequently here.'
          },
  
          {
            question: 'Can I get reimbursed for telehealth visits under Medicaid?',
            answer: 'So because this is such a huge topic one will have to delve into each state’s requirements. Some ' +
                'health policy centers have put summarized these requirements, but please realize that they are not always ' +
                'in the best format. Center for Connected health policy does have an interactive map, but much of the information ' +
                'is in summary format and I recommend going to the actual statute on the issue to answer your specific question.'
          },
  
          {
            question: 'Are private payers reimbursing for the same telehealth services that Medicare and Medicaid are?',
            answer: 'Yes but many payors have rapidly changing policies on this topic and one will need to check with each ' +
                'payor do determine what will be paid for. Currently most states (43 states including DC) have some private ' +
                'payer-related reimbursement laws.'
          },
  
          {
            question: 'What about telehealth parity laws? Don’t they force private payors to pay for telehealth services?',
            answer: 'That will vary widely from state to state. While state laws may mandate for telehealth parity, it is ' +
                'essential to find out from each payor what practices exist for telehealth reimbursement.'
          }
        ],
  
        faqContent6: [
          {
            question: 'Can I prescribe to patients that I have only seen via telehealth?',
            answer: 'Generally yes, but please realize that this question has two layers because there are two bodies that ' +
                'govern prescribing, federal and state. On a federal level, the DEA has given guidance on the circumstances ' +
                'that a provider can prescribe a controlled substance. (Please note that this guidance is only valid during ' +
                'the pandemic.) Each state will have their own regulations as to prescribing medicine, please see the chart ' +
                'at the footnoted link. Yes, telemedicine services allow prescription services through an agreement made ' +
                'between the provider and the patient. This allows for increased access to necessary care for patients, ' +
                'including access to specialty care.'
          }
        ]
      }
    },
    async mounted() {
      const firstName = this.$auth.user.nickname
      const lastName = this.$auth.user.name.split(' ')[1]
      const email = this.$auth.user.email
  
      const script = document.createElement("script");
  
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
  
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: "8355573",
            formId: "0e994d08-9bcc-49f2-b906-f4d08ff6540c",
            target: "#hubspotForm",
            onFormReady: function() {
  
              // Populate these values
              document.querySelector('#hubspotForm input[name=firstname]').value = firstName;
              document.querySelector('#hubspotForm input[name=lastname]').value = lastName;
              document.querySelector('#hubspotForm input[name=email]').value = email;
            }
          })
        }
      })
  
    },
    methods: {
  
      goBack() {
        this.$router.go(-1)
      },
  
      goToMemberShipPage() {
        this.$router.push({ name: 'memberships', params: {scroll: true}})
      },
  
      loadCalendlyLink() {
        window.open('https://calendly.com/providerlegalmatt/vitel-consult', '_blank');
      },
  
      handleClick(item) {
        if(this.selectedItem != item){
          this.selectedItem = item
        } else {
          this.selectedItem = null
        }
      },
  
      handleClick2(item) {
        if(this.selectedItem2 != item){
          this.selectedItem2 = item
        } else {
          this.selectedItem2 = null
        }
      },
  
      handleClick3(item) {
        if(this.selectedItem3 != item){
          this.selectedItem3 = item
        } else {
          this.selectedItem3 = null
        }
      },
  
      handleClick4(item) {
        if(this.selectedItem4 != item){
          this.selectedItem4 = item
        } else {
          this.selectedItem4 = null
        }
      },
  
      handleClick5(item) {
        if(this.selectedItem5 != item){
          this.selectedItem5 = item
        } else {
          this.selectedItem5 = null
        }
      },
  
      handleClick6(item) {
        if(this.selectedItem6 != item){
          this.selectedItem6 = item
        } else {
          this.selectedItem6 = null
        }
      }
    },
  
    computed: {
      ...mapState(['profile'])
    },
  }
</script>
<style>
.mt-8-pb-0 {
  margin-top: 8px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.mt-24-pb-0 {
  margin-top: 24px;
  padding-bottom: 0px;
}
.mt-32-pb-0 {
  margin-top: 32px;
  padding-bottom: 0px;
}
.mt-40-pb-0 {
  margin-top: 40px;
  padding-bottom: 0px;
}
  .wrapper {
  background: linear-gradient(90deg, #5e60ce 66.28%, #48bfe3 100%);
}
.pb-40 {
  padding-bottom: 40px !important;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}

.legal_image {
  height: 515px;
}
.linear-container {
  padding: 3rem 20px;
  max-width: 1440px;
  margin: auto;
  background: initial;
  }
  .comp_container {
  max-width: 1440px;
  margin: auto;
  padding: 0px 20px;
  }
  .membershipLink {
  cursor: pointer;
  color:#48BFE3;
  }
  .membershipLink:hover {
  cursor: pointer;
  color:#068eb8fa;
  }
  .comp_container .sub_header {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666666;
  }
  .comp_container .small_text {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #292929;
  margin-top: 1.5rem;
  }
  .comp_container .header-text {
  color: #292929;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding-left: 1rem;
  margin: 0;
  }

  .comp_container .text {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #292929;
  }
  .comp_container .header-2 {
  font-size: 0.875rem;
  }
  .comp_container .blue-text {
  color: #48BFE3;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  }
  .comp_container .faq_text {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: #292929;
  }
  .comp_container .faq_containter {
  display: flex;
  align-items: center;
  }
  .comp_container .faq_containter .icon {
  cursor: pointer;
  }
  .show {
  display: block;
  }
  .hide {
  display: hidden;
  }
  .text-area__container {
  width: 30.5rem;
  background: #F8F8F8;
  border-radius: 0.5rem;
  border: none;
  padding: 1rem;
  }
  .text-area__container:focus {
  outline: none;
  box-shadow: none;
  }
  #hubspotForm textarea { 
  width: 80%; 
  outline: none; 
  border: 1px solid #666666; 
  border-radius: 3px;
  height: 5rem;
  margin: 1rem 0;
  padding: 1rem;
  resize: none;
  }
  #hubspotForm input[type=submit] {
  background: #02E864;
  border-radius: 0.5rem;
  border: none;
  color: #ffffff;
  padding: 0.8rem 1.9rem;
  font-family: 'Josefin Sans', sans-serif;
  }
  #hubspotForm span {
  padding: 0.7rem 0;
  font-size: 1.2rem;
  font-weight: 600;
  }
  /* Responsive Design */
  @media (max-width: 575.98px) {
  .comp_container {
  padding: 1rem;
  }
  .legal_image {
  width: 20rem;
  margin-top: 3rem;
  }
  .provider_legal_sub-heading {
    width: auto;
    color: #9A9A9A;
    font-weight: 400;
    margin: 5px 0px;
  }
  .text-area__container {
  width: 100%;
  }
  }

  #xchange_ask_a_question-0e994d08-9bcc-49f2-b906-f4d08ff6540c {
    background: #EEEEEE;
    border-radius: 7px !important;
    border: none !important;
    padding: 16px !important;
    font-size: 16px !important;
    height: 108px !important;
  }
</style>