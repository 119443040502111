import moment from "moment-timezone";


export const VITALS = [
  {
    label: "Weight",
    attribute: "weight",
    unit: "lbs",
    icon: require("@/assets/rpm/Scale.png"),
  },
  {
    label: "Blood Pressure",
    attribute: "blood_pressure",
    unit: "mmHg",
    icon: require("@/assets/rpm/Sphygmomanometer.png"),
  },
  {
    label: "Pulse",
    attribute: "pulse",
    unit: "bpm",
    icon: require("@/assets/rpm/Electrodiogram.png"),
  },
  {
    label: "Oxygen Saturation",
    attribute: "oxy",
    unit: "%",
    icon: require("@/assets/rpm/Oximeter.png"),
  },
  {
    label: "Peak Expiratory Flow",
    attribute: "peak_expiratory_flow",
    unit: "L/min",
    icon: require("@/assets/rpm/Stethoscope.png"),
  },
  {
    label: "Temperature",
    attribute: "temperature",
    unit: "°F",
    icon: require("@/assets/rpm/Thermo Gun.png"),
  },
  {
    label: "Blood Glucose",
    attribute: "blood_glucose_nonfasting",
    unit: "mg/dL",
    icon: require("@/assets/rpm/Oximeter.png"),
  },
  {
    label: "Steps Hourly",
    attribute: "steps",
    unit: "steps",
    icon: require("@/assets/rpm/Pedometer.png"),
  },
  {
    label: "Steps Daily",
    attribute: "steps_daily",
    unit: "steps",
    icon: require("@/assets/rpm/Pedometer.png"),
  },
]

export function getReadings(vitalReadings, attribute) {
  return vitalReadings.filter((vitalReading) => {
    if (attribute === "steps_daily") {
      return vitalReading.attribute === "steps";
    } else {
      return vitalReading.attribute === attribute;
    }
  });
}

export function getChartjsData(vitalReadings, attribute) {
  let attributes = [];
  const data = vitalReadings.reduce((data, vitalReading) => {
    if (!attributes.includes(vitalReading.attribute)) {
      if (attribute === 'blood_pressure' && !attributes.includes('systolic') && !attributes.includes('diastolic')) {
        attributes.push('systolic');
        attributes.push('diastolic');
      } else if (attribute !== 'blood_pressure') {
        attributes.push(vitalReading.attribute);
      }
    }
    const key = moment(vitalReading.reading_at).format(attribute === 'steps_daily' ? "LL" : "LLL");
    if (key in data) {
      if (attribute === 'steps_daily' && data[key][vitalReading.attribute]) {
        data[key][vitalReading.attribute] = +data[key][vitalReading.attribute] + +vitalReading.value;
      } else {
        data[key][vitalReading.attribute] = vitalReading.value;
      }
    } else {
      if (attribute === 'blood_pressure') {
        const values = vitalReading.value.split("/");
        data[key] = {};
        data[key]['systolic'] = values[0];
        data[key]['diastolic'] = values[1];
      } else {
        let init = {};
        init[vitalReading.attribute] = vitalReading.value;
        data[key] = init;
      }
    }
    return data;
  }, {});

  const labels = [];
  const datasets = attributes.map((attribute) => ({
    data: [],
    borderColor: this.getAttributeColor(attribute),
  }));
  for (const [label, vitalReading] of Object.entries(data)) {
    labels.push(label);
    attributes.forEach((attribute, index) => {
      datasets[index].data.push(vitalReading[attribute]);
    });
  }

  return {labels, datasets};
}

export function getChartjsOptions(unit) {
  return {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: true,
          },
          offset: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: "grey",
            callback: function (value) {
              return value + ' ' + unit;
            },
          },
        },
      ],
    },
    elements: {
      line: {
        fill: false,
      },
    },
  };
}

export function getAttributeColor(attribute) {
  if (attribute === "systolic") {
    return "#5186EF";
  } else if (attribute === "diastolic") {
    return "#22C55E";
  } else {
    return "#5E60CE";
  }
}

export default {
  VITALS,
  getReadings,
  getChartjsData,
  getChartjsOptions,
  getAttributeColor,
}