<template>
  <div id="patient-report">
  </div>
</template>

<script>
import Vue from "vue";
import {mapState} from "vuex";
import Chart from "chart.js";
import moment from "moment-timezone";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import vitalsHelper from '../../helpers/vitals';

export default {
  props: {
    patient: Object,
    category: String,
  },
  data() {
    return {
      accessToken: null,
      patientInfo: null,
      charts: [],
    }
  },
  computed: {
    ...mapState(["patients"]),
    patientName() {
      return `${this.patient["Last Name"]}, ${this.patient["First Name"]} ${this.patient["Middle Initial"]}`
    },
    dateOfBirth() {
      return moment(this.patient["Date of Birth"]).format("MMMM D, YYYY")
    },
    reportMonth() {
      return moment(this.patient["Billing Month"]).format("MMMM YYYY")
    },
    reportPeriod() {
      let now = moment()
      if(this.reportMonth===now.format("MMMM YYYY")) {
        return now.day===1 ? now.format("MMMM 1, YYYY") : now.format("MMMM 1-D, YYYY")
      }
      else {
        return this.reportMonth;
      }
    },
    filename() {
      return `${moment(this.patient["Billing Month"]).format("YYYYMMM")}_${this.patient["First Name"]}${this.patient["Last Name"]}${this.patient["Middle Initial"]}.pdf`
    }
  },
  methods: {
    async getAccessToken() {
      this.accessToken = await this.$auth.getTokenSilently();
    },
    placeOfService() {
      var client = this.patients.clients.find((c) => c.name === this.patient["Facility Name"])
      return [client.company, client.address1, client.city, client.state, client.zip].filter(Boolean).join(", ");
    },
    async getPatientDetails() {
      return Vue.$http
        .get("/api/rpm_ccm_dashboard/patient_details", {
          params: {
            patient_id: this.patient.id,
            token: this.accessToken,
          },
        })
        .then((res) => (this.patientInfo = res.data.data));
    },
    billableCptCodes() {
      var cptCodes = Object.keys(this.patient)
        .filter((key) => key.includes(`${this.category.toUpperCase()} CPT`) && this.patient[key])
      if(cptCodes.length === 0) {
        return ['N/A']
      } else {
        return cptCodes.map((cptCode) => {
          return `${cptCode.replace(`${this.category.toUpperCase()} CPT `,'')} ${this.patient[cptCode] !== 1 ? '('+this.patient[cptCode]+')' : ''}`
        })
      }
    },
    insuranceProvider() {
      const payer = this.patients.payers.find((p) => p.id === this.patientInfo.payer_id);
      return payer ? payer.name : "?";
    },
    devices() {
      return this.patientInfo.devices.map((device) => {
        return [
          `${device.name}`,
          { ul: [`Added ${moment(device.created_at).format("MMM D, YYYY, h:mm a")}`] }
        ]
      })
    },
    getProvider(id) {
      return this.patients.providers.find((p) => p.id === id);
    },
    getCareTeamTitle(id) {
      var careTeam = this.patientInfo.cache.careTeam
      if (careTeam.pcp === id) {
        return "(PCP)"
      } else if (careTeam.care_manager === id) {
        return "(Care Manager)"
      } else if (careTeam.specialty.includes(id)) {
        return "(Specialty)"
      } else if (careTeam.other.includes(id)) {
        return "(Other)"
      } else {
        return ""
      }
    },
    eventLog() {
      return this.patientInfo.tasks
        .filter((task) => task.category === this.category && moment(task.created_at).format("MMMM YYYY") === this.reportMonth)
        .map((task) => {
          return [
            {
              columns: [
                {
                  width: 175,
                  stack: [
                    {
                      text: `${moment(task.created_at).format("MMM D, YYYY, h:mm a")}`,
                      bold: true,
                      fontSize: 10,
                      color: 'gray',
                    },
                    {
                      text: [
                        `${this.getProvider(task.assigned_id).first_name} ${this.getProvider(task.assigned_id).last_name} `,
                        `${this.getCareTeamTitle(task.assigned_id)}`,
                      ]
                    },
                    {
                      text: `${this.getProvider(task.assigned_id).email}`,
                      fontSize: 10,
                    },
                  ]
                },
                {
                  stack: [
                    {
                      text: `(+${task.duration}:00)`,
                      fontSize: 10,
                      color: 'gray',
                    },
                    `${task.description}`,
                    {
                      text: `${task.note}`,
                      fontSize: 12,
                      italics: true,
                    },
                  ]
                }
              ],
              margin: [10, 10, 10, 10],
            },
            {"canvas": [{ "type": "line", "lineColor": "#c0c0c0", "lineWidth": 1, "x1": 0, "y1": 0, "x2": 475, "y2": 0 }]}
          ]
        })
    },
    async drawCharts() {
      var month_readings = this.patientInfo.vital_readings.filter((vital_reading) => moment(vital_reading.reading_at).format("MMMM YYYY") === this.reportMonth)
      vitalsHelper.VITALS.forEach((vital) => {
        if(vital.attribute==="steps") return // exclude steps hourly graph
        
        var readings = vitalsHelper.getReadings(month_readings, vital.attribute)
        if(readings.length>0) {
          var data = vitalsHelper.getChartjsData(readings, vital.attribute)
          var options = vitalsHelper.getChartjsOptions(vital.unit)
          var content = {
            type: 'line',
            data: data,
            options: {
              ...options,
              animation: {
                duration: 0,
              }
            }
          }

          var canvas = document.createElement("canvas");
          canvas.id = vital.attribute;
          document.getElementById("patient-report").appendChild(canvas);

          var chart = new Chart(vital.attribute, content)
          chart.render('1s')
          this.charts.push({
            ...vital,
            data: data,
            chart: chart.toBase64Image()
          })
        }
      })
    },
    vitals() {
      return this.charts.map((chart) => {
        return {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  stack: [
                    {
                      text:  `${chart.label} (${chart.unit})`,
                      style: 'subheader'
                    },
                    {
                      image: `${chart.chart}`,
                      width: 450,
                      alignment: 'center',
                      style: 'marginBottom'
                    },
                    {
                      table: {
                        widths: ['*', '*'],
                        body: [
                          [{text: `Timestamp`, bold: true}, {text: `${chart.label} (${chart.unit})`, bold: true, alignment: "center"}],
                          ...chart.data.labels.map((label, index) => {
                            var value = chart.attribute==="blood_pressure" ? `${chart.data.datasets[1].data[index]}/${chart.data.datasets[0].data[index]}` : `${chart.data.datasets[0].data[index]}`
                            return [label, {text: value, alignment: "center"}]
                          })
                        ],
                        alignment: 'center'
                      },
                      layout: 'noBorders',
                      fontSize: 10,
                      margin: [75, 0, 75, 0]
                    }
                  ],
                  style: 'margin'
                }
              ]
            ]
          },
          layout: 'customLayout',
          style: 'marginBottom'
        }
      })
    }
  },
  async created() {
    await this.$store.dispatch('getAuthUser');
    await this.getAccessToken()
    await this.getPatientDetails()
    await this.drawCharts()
    pdfMake.vfs = pdfFonts.pdfMake.vfs
    pdfMake.fonts = {
      Lato: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/lato-font/3.0.0/fonts/lato-normal/lato-normal.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/lato-font/3.0.0/fonts/lato-bold/lato-bold.woff',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/lato-font/3.0.0/fonts/lato-normal-italic/lato-normal-italic.woff',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/lato-font/3.0.0/fonts/lato-bold-italic/lato-bold-italic.woff'
      }
    }
    pdfMake.tableLayouts = {
      customLayout: {
        hLineColor: function () {
          return '#c0c0c0';
        },
        vLineColor: function () {
          return '#c0c0c0';
        },
      }
    }
    var docDefinition = {
      content: [
        {
          text:  `Patient Report for ${this.patientName}`,
          style: 'header',
          alignment: 'center'
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  stack: [
                    {
                      text:  `Report Overview`,
                      style: 'subheader'
                    },
                    {
                      table: {
                        widths: [120, "*"],
                        body: [
                          [`Report Period:`, `${this.reportPeriod}`],
                          [`Place of Service:`, `${this.placeOfService()}`],
                          [`Days with Data:`, `${this.patient["Days with Data"]}`],
                          [`Total Review Time:`, `${this.patient["Total Review Time (minutes)"] || "00"}:00`],
                          [`Billable CPT Codes:`, this.billableCptCodes()],
                          [`Billable HCPCS Codes:`, `N/A`], // TODO: Leaving as N/A for now, will resolve in a future ticket
                        ]
                      },
                      layout: 'noBorders'
                    },
                  ],
                  style: 'margin'
                }
              ]
            ]
          },
          layout: 'customLayout',
          style: 'marginBottom',
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  stack: [
                    {
                      text:  `Patient Overview`,
                      style: 'subheader'
                    },
                    {
                      table: {
                        widths: [120, "*"],
                        body: [
                          [`Patient Name:`, `${this.patientName}`],
                          [`Date of Birth:`, `${this.dateOfBirth}`],
                          [`Condition:`, `${this.patient["Health Condition (DX)"]}`],
                          [`Ordering Physician:`, `${this.patient["Ordering Physician"]}`],
                          [`Health Coach:`, `${this.patient["Health Coach"]}`],
                          [`Insurance Provider:`, `${this.insuranceProvider()}`],
                        ]
                      },
                      layout: 'noBorders'
                    },
                  ],
                  style: 'margin'
                }
              ]
            ]
          },
          layout: 'customLayout',
          style: 'marginBottom',
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  stack: [
                    {
                      text:  `Devices Overview`,
                      style: 'subheader'
                    },
                    {
                      ol: this.devices(),
                    }
                  ],
                  style: 'margin'
                }
              ]
            ]
          },
          layout: 'customLayout',
          style: 'marginBottom',
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  stack: [
                    {
                      text:  `Event Log`,
                      style: 'subheader'
                    },
                    ...this.eventLog()
                  ],
                  style: 'margin'
                }
              ]
            ]
          },
          layout: 'customLayout',
          style: 'marginBottom',
          pageBreak: 'after',
        },
        ...this.vitals()
      ],
      styles: {
        header: {
          fontSize: 24,
          bold: true,
          margin: [0, 0, 0, 5]
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 5]
        },
        margin: {
          margin: [10, 10, 10, 10]
        },
        marginLeft: {
          margin: [10, 0, 0, 0]
        },
        marginBottom: {
          margin: [0, 0, 0, 10]
        }
      },
      defaultStyle: {
        font: "Lato",
        fontSize: 12,
        color: '#1F2937',
        lineHeight: 1.25,
      }
    }
    pdfMake.createPdf(docDefinition).download(this.filename)
    this.$emit('downloaded');
  }
}
</script>

<style scoped>
</style>
