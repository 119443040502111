<template>
  <div>
    <div class="">
      <div class="wrapper">
      <div class="flex flex-col linear-container px-6 md:px-14 py-5 md:py-12">
        <div class="flex josefin_font">
          <img src="@/assets/white-back.svg" @click="goBack" style="cursor: pointer;" />
          <h3 class="header-text">Doctors Lounge</h3>
        </div>
        <p class="py-3 m-0 text-white font-light w8-12 sub_header">
          The medical providers' online telehealth community for sharing and growing with colleagues.
      </p>
    </div>
      </div>
      <div class="comp_container  flex flex-col md:flex-row">
        <div class="w-full md:w-[60%] items-center">
          <div class="mt-4">
            <button @click="handzin_sso" class="mt-2 josefin_font green_button">Join the Lounge</button>
          </div>
          <section class="improve_efficiency">
            <h5 class="josefin_font list-width sub-heading">
              Welcome to the Doctors Lounge
            </h5>
            <p class="m-0 font-weight-normal text-description--doctor lato">
              The Doctors Lounge is a telehealth community for physicians, innovators, and technology architects
              focused on increasing healthcare access improving healthcare outcomes, while reducing healthcare costs.
            </p>
          </section>
        </div>
        <div class="w-full md:w-[40%] flex justify-center img_wrapper">
          <img src="@/assets/physical_vault.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  created() {
    this.fetchSecretKeys()
  },
  methods: {
    ...mapActions(['fetchSecretKeys']),
    goBack() {
      this.$router.go(-1)
    },
    handzin_sso() {
      return window.open(this.secretKeys.handzinURL, '_blank')
    }
  },
  computed: {
    ...mapState(['secretKeys'])
  }
}
</script>

<style>
.wrapper {
  background: linear-gradient(90deg, #5e60ce 66.28%, #48bfe3 100%);
}
.linear-container {
  padding: 3rem 20px;
  max-width: 1440px;
  margin: auto;
  background: initial;
}
.comp_container {
  max-width: 1440px;
  margin: auto;
  padding: 0px 20px;
}

.comp_container .sub_header {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666666;
}
.comp_container .header-text {
  color: #292929;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding-left: 1rem;
  margin: 0;
}
.improve_efficiency {
  margin-top: 2rem;
}
.img_wrapper {
  margin-top: 4rem;
}
/* Responsive Design */
@media (max-width: 575.98px) {
  .comp_container {
    padding: 1rem;
  }
  .img_wrapper {
    margin-top: 0rem;
  }
}

.text-description--doctor {
  color: #9A9A9A !important;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px !important;
}
</style>