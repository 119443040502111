<template>
  <main class="col-lg-12 col-sm-12 col-md-12 mt-16">
   
    <h3 class="my-3 text-center josefin_font text-2xl font-bold">Membership Comparison</h3>
    <div class="overflow-hidden !overflow-x-scroll">
      <div class="mt-4 comparison-chart-container">
        <div class="border-table">
        <table class="border border-gray-200 pb-8">

          <thead>

            <tr>
              <th class="w-[50%] blue-text border border-gray-200 py-1.5">Features</th>
              <th class="text-center border border-gray-200 py-1.5 w-16">Essentials</th>
              <th class="text-center border border-gray-200 py-1.5 w-1">Enhanced</th>
              <th class="text-center border border-gray-200 py-1.5 w-14">Premier</th>
            </tr>

          </thead>

          <tbody>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Physician Vault</th>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
            </tr>

<!--            <tr>-->
<!--              <th scope="row" class="text">Global Health Impact Network</th>-->
<!--              <td><img src="@/assets/check-row.svg" alt="" /></td>-->
<!--              <td><img src="@/assets/check-row.svg" alt="" /></td>-->
<!--              <td><img src="@/assets/check-row.svg" alt="" /></td>-->
<!--            </tr>-->

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Doctors Lounge</th>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Provider Legal Consultation</th>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Telehealth Training</th>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Events Calendar</th>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Accredited CME</th>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Access to the ViTel Job Board “TeleBoard”</th>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Optimized Calendar and Scheduling Tools</th>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Accredited Credentialing</th>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Physician Network Contracts</th>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Telehealth/EHR Platform</th>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Billing and Collections Support</th>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">ViTel Xchange Specialty Telehealth & EHR Platforms</th>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Telehealth Practice Management</th>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt=""  class="center_img"/></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Business and Legal Support</th>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Revenue Cycle Management</th>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Personalized Website</th>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
            </tr>

            <tr>
              <th scope="row" class="text border border-gray-200 py-1.5">Marketing & SEO Services</th>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"></td>
              <td class="border border-gray-200 py-1.5"><img src="@/assets/check-row.svg" alt="" class="center_img" /></td>
            </tr>

            <!--            <tr>-->
<!--              <th scope="row" class="text">View Job Board Opportunities</th>-->
<!--              <td><img src="@/assets/check-row.svg" alt="" /></td>-->
<!--              <td><img src="@/assets/check-row.svg" alt="" /></td>-->
<!--              <td><img src="@/assets/check-row.svg" alt="" /></td>-->
<!--            </tr>-->

            <tr>
              <th scope="row" class="border-r border-gray-200"></th>
              <td class="border-r border-gray-200">
                <div class="flex justify-center py-2">
                  <button
                  @click="loadStripe('Essentials')"
                  :class="[profile.subscriptionPlan === 'Essentials' ? 'current_plan_chart' : 'select mx-auto']"
                >
                  {{ profile.subscriptionPlan === 'Essentials' ? 'Current Plan' : 'Select'}}
                  </button>
                </div>
              </td>
              <td class="border-r border-gray-200">
                <div class="flex justify-center">
                  <button
                    @click="loadStripe('Enhanced')"
                    :class="[profile.subscriptionPlan === 'Enhanced' ? 'current_plan_chart' : 'select mx-auto']"
                  >
                    {{ profile.subscriptionPlan === 'Enhanced' ? 'Current Plan' : 'Select'}}
                  </button>
                </div>
              </td>
              <td>
                <div class="flex justify-center">
                  <button
                    @click="loadStripe('Premier')"
                    :class="[profile.subscriptionPlan === 'Premier' ? 'current_plan_chart' : 'select mx-auto']"
                  >
                    {{ profile.subscriptionPlan === 'Premier' ? 'Current Plan' : 'Select'}}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
      </div>
    </div>
      <!-- <el-tab-pane label="A La Carte">
        <section class="border border-gray-100 rounded flex flex-col md:flex-row gap-20 py-10 px-8 md:px-16">
          <div>
            <h2 class="josefin_font font-bold text-4xl">A La Carte</h2>
            <p class="text-gray pb-3">Individual Products and Services Pricing</p>
            <button class="text-white w-48 py-3 rounded" style="background: #48BFE3;">Contact sales</button>
          </div>
          <div>
            <div class="my-3 d-flex" v-for="(x, index) in listItems" :key="index">

            <span>

              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="#DFE0FF"/>
                <path d="M16 9L10.5 14.5L8 12" stroke="#5E60CE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </span>

            <p class="font-medium pl-2 text-gray">{{ x }}</p>

          </div>
          </div>
        </section>
      </el-tab-pane> -->

  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      userData: {
        email: ''
      },
      subscriptionPlan: '',
      listItems: [
        'Credentialing- By Quote (provider and payor networks)',
        'RPM-CCM - No cost to the physician or patient',
        'Telehealth HR Platform for General Health - $249/mo./physician',
        'Specialty telehealth EH platforms - $299/mo./physician'
      ],
    }
  },
  methods: {
    ...mapActions([
      'fetchSecretKeys',
      'retrieveSubscription',
      'createCheckoutSession',
      'updateSubscription'
    ]),
    handleClick(tab, event) {

    },
    async fetchSubscriptionData() {
      const payload = {
       auth0_sub: this.$auth.user.sub
     }
    await this.retrieveSubscription(payload)
    },
    loadStripe(item) {
      if(item === 'Essentials') return false
      if(this.profile.customerID) {
        const payload = {
          customer_id: this.profile.customerID
        }
        this.updateSubscription(payload)
        .then((res) => {
        if(res.status === 200) {
          const link = document.createElement('a')
          link.href = res.data
          link.click()
        }
      })
      } else {
        const payload = {
        email: this.userData.email,
        price_id: item.name === 'Enhanced' ? this.secretKeys.enchancedPriceId : this.secretKeys.premierPriceId
      }
      this.createCheckoutSession(payload)
      .then((res) => {
        if(res.status === 200) {
          const link = document.createElement('a')
          link.href = res.data
          link.click()
        }
      })
      }
    },
  },
  mounted() {
    this.userData = this.$auth.user
    this.fetchSubscriptionData()
    this.fetchSecretKeys()
  },
  computed: {
    ...mapState(['profile', 'secretKeys'])
  }

}
</script>

<style scoped>
.center_img {
  margin: 0 auto;
}

/* .border-collapse {
  border-collapse: collapse;
} */

* >>> .el-tabs--card>.el-tabs__header .el-tabs__item {
  border-left: 0px;
  color: #9A9A9A;
}

* >>> .el-tabs--card>.el-tabs__header {
  border-bottom: 0px;
  font-family: 'Lato', sans-serif;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

* >>> .el-tabs--card>.el-tabs__header .el-tabs__nav {
  border: none !important;
}

* >>> .el-tabs__item.is-active {
  color: #292929 !important;
}

* >>> .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  border-bottom: 4px solid#48BFE3 !important;
  background: #ffffff;
}

.comparison-chart-container th,.comparison-chart-container td{
  border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    
}
.comparison-chart-container tr{
text-align: left;
}
.comparison-chart-container tr th{
  padding: 17px 24px;
    font-family: 'Lato';
    color: #9A9A9A;
    font-size: 16px;
    line-height: 24px;
}
.comparison-chart-container thead tr th{
  color: #292929;
  font-weight: 800;
font-size: 16px;
line-height: 24px;
}

.comparison-chart-container thead tr th.blue-text{
  color: #48BFE3;

}
.comparison-chart-container .border-table {
  border-left: 1px solid rgba(229, 231, 235, 1);
    border-right: 1px solid rgba(229, 231, 235, 1);
}
.comparison-chart-container .current_plan_chart {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #5E60CE;
}
.comparison-chart-container .select {
  font-family: 'Josefin Sans';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 16px;
display: flex;
align-items: center;
text-align: center;
color: #FFFFFF;
justify-content: center;
}

.comparison-chart-container .w-1{
  width: 1%;
}
.comparison-chart-container .w-16{
  width: 16%;
}

.comparison-chart-container .w-14{
  width: 14%;
}
@media (max-width: 1050px) {
.border-table {
  overflow: scroll;
    width: 1000px;
}

}


</style>