import Vue from 'vue';

export default {
  state: {
    loader: false,
  },
  mutations: {
    mutate(state, payload) {
      state[payload.property] = payload.with
    }
  },
  actions: {
    createCheckoutSession({ commit }, data) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.post(`/api/xchange/profile/subscription/checkout_session`, data)
          .then((res) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            resolve(res)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err)
          })
      })
    },
    updateSubscription({ commit }, data) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.post(`/api/xchange/profile/subscription/create_customer_portal_session`, null, {
          params: {
            customer_id: data.customer_id
          }
        })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

  }
}