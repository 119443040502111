<script>
export default {
  name: "TeamMember",
  props: {
    person: {},
    type: '',
  },
  // computed: {
  //   role: function() {
  //     return this.person.roles.map(r => r.name).reduce((acc, cur) => acc + ', ' + cur);
  //   },
  // }
}
</script>

<template>
  <li class="flex justify-between gap-x-6 py-1">
    <div class="flex min-w-0 gap-x-4">
<!--      <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="person.imageUrl" alt="" />-->
      <div class="min-w-0 flex-auto">
        <p class="text-sm font-semibold leading-6 text-gray-900">{{ person.first_name }} {{person.last_name}}</p>
        <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{ person.email }}</p>
      </div>
    </div>
    <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
      <p class="text-sm leading-6 text-gray-900">{{ type }}</p>
<!--      <p v-if="person.lastSeen" class="mt-1 text-xs leading-5 text-gray-500">-->
<!--        Last seen <time :datetime="person.lastSeenDateTime">{{ person.lastSeen }}</time>-->
<!--      </p>-->
<!--      <div v-else class="mt-1 flex items-center gap-x-1.5">-->
<!--        <div class="flex-none rounded-full bg-emerald-500/20 p-1">-->
<!--          <div class="h-1.5 w-1.5 rounded-full bg-emerald-500" />-->
<!--        </div>-->
<!--        <p class="text-xs leading-5 text-gray-500">Online</p>-->
<!--      </div>-->
    </div>
  </li>
</template>

<style scoped>

</style>