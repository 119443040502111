<template>
  <main>
    <div class="wrapper">
    <div class="flex flex-col linear-container px-6 md:px-14 py-5 md:py-12">
      <div class="flex josefin_font">
        <img src="@/assets/white-back.svg" @click="goBack" style="cursor: pointer;" />
        <h3 class="header-text">Remote Patient Monitoring and Chronic Care Management</h3>
      </div>
      <p class="py-3 m-0 text-white font-light w-auto">
        Offer effective ongoing care services and create additional revenue for your practice with ViTel.
      </p>
    </div>
  </div>
     <div class="comp_container flex flex-col md:flex-row">
        <div class="w-full md:w-3/4">
          <section class="improve_efficiency">
          <h5 class="josefin_font sub-heading">
            ViTel delivers RPM (Remote Patient Monitoring) and CCM (Chronic Care Management) services to your
            patients providing a secure HIPAA compliant technology platform that meets all CMS guidelines.
          </h5>
          <p class="mt-2 font-weight-normal gray-text mt-16-pb-0">
            Features include: administration, billing, patient setup, case management, and devices to capture patient
            data remotely.  ViTel delivers a turnkey program and implements and manages the RPM-CCM program for your
            practice with our Care Coach team.
          </p>
            <p class="mt-2 font-weight-normal gray-text mt-16-pb-0">
            RPM-CCM technology and services are designed to transition healthcare from intervention to prevention.
              68% of individuals 65 or older have two or more chronic conditions and can greatly benefit from remote
              monitoring.
          </p>
<!--            TODO: Find relevant URL link. Ask Keith-->
          <p class="mt-2 font-weight-normal gray-text mt-16-pb-0">You can also check out our
            <span class="font-bold" style="color: #48BFE3; cursor: pointer;">ViTel RPM-CCM Program Overview
            </span> for more information</p>
        </section>
          <div class="mt-4 button__container">
            <button class="mt-3 green_button josefin_font" @click="goToRPMDashboard">View Data Management System</button>
            <button class="mt-3 blue_button josefin_font" @click="goToCalculator">Calculate Revenue Potential</button>
            <button class="mt-3 blue_button josefin_font">Meeting Request</button>
        </div>
        </div>
        <div class="w-full md:w-2/4 flex justify-center img_wrapper">
        <img src="@/assets/tele-training.svg" alt="" class="mobile-image">
      </div>
    </div>
  </main>
</template>

<script>
export default {
 methods: {
    goBack() {
      window.location.href="/access"
    },
    goToCalculator() {
      this.$router.push('/rpm-calculator')
    },

    goToRPMDashboard() {
      this.$router.push('/rpm-dashboard')
    }
 }
}
</script>

<style>
.pb-0px {
  padding-bottom: 0px;
}
.mt-16-pb-0 {
  margin-top: 16px;
  padding-bottom: 0px;
}
.button__container {
  display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.wrapper {
  background: linear-gradient(90deg, #5e60ce 66.28%, #48bfe3 100%);
}
.linear-container {
  padding: 3rem 20px;
  max-width: 1440px;
  margin: auto;
  background: initial;
}
.comp_container {
  max-width: 1440px;
  margin: auto;
  padding: 0px 20px;
}
/* Responsive Design */
@media (max-width: 1439px) {
 .improve_efficiency {
  margin-top: 30px;
 }
}
@media (max-width: 575.98px) {
  .comp_container {
    padding: 1rem;
  }
  .mobile-image {
    width: 95%;
  }
}
</style>