import moment from "moment-timezone";

export default {
  data: function () {
    return {
      selectedTemplate: null,
      notes: null,
      notesOriginal: null,
    };
  },
  props: {
    patient: Object,
  },
  methods: {
    templateChange: function() {
      if (!this.selectedTemplate) {
        this.notes = this.notesOriginal;
        return;
      }

      let templateMapping = {
        '@FNAME@': (patient) => patient.first_name,
        '@LNAME@': (patient) => patient.last_name,
        '@AGE@': function(patient) {
          const dob = moment(patient.dob);
          return moment.duration(moment().diff(dob)).years();
        },
        '@SEX@': (patient) => patient.sex,
      };

      let notesReplaced = this.selectedTemplate.contents;
      for (const [templateVar, templateFunc] of Object.entries(templateMapping)) {
        notesReplaced = notesReplaced.replace(templateVar, templateFunc(this.patient));
      }

      this.notes = notesReplaced;
    },
    tabTemplate: function(ref) {
      const textarea = this.$refs[ref];
      const text = textarea.value;
      let startPos = textarea.selectionEnd;
      let foundPos = text.indexOf('***', startPos);

      if (foundPos === -1 && startPos > 0) {
        foundPos = text.indexOf('***');
      }

      if (foundPos === -1) {
        return;
      }

      textarea.setSelectionRange(foundPos, foundPos + 3);
    }
  }
}