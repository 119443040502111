<script>
import TeamMember from "@/components/TeamMember.vue";
import RpmDashboardErrors from "@/components/RpmDashboardErrors.vue";
import Vue from "vue";

export default {
  name: 'CareTeam',
  components: {RpmDashboardErrors, TeamMember},
  props: [
    'patient',
    'editCareTeamVisible',
  ],
  created() {
    this.setCareTeam();
  },
  data() {
    return {
      editCareTeamVisibleInternal: false,
      editCareTeamErrors: [],
      pcp: null,
      care_manager: null,
      specialty: [],
      other: [],
      errors: [],
    };
  },
  watch: {
    editCareTeamVisible: function (v) {
      this.editCareTeamVisibleInternal = v;
    },
    patient: function () {
      this.setCareTeam();
    },
    pcp(val) {
      if (val === '') {
        this.pcp = null;
      }
    },
    care_manager(val) {
      if (val === '') {
        this.care_manager = null;
      }
    },
  },
  methods: {
    setCareTeam() {
      this.pcp = this.patient.cache.careTeam ? this.patient.cache.careTeam.pcp : null;
      this.care_manager = this.patient.cache.careTeam ? this.patient.cache.careTeam.care_manager : null;
      this.specialty = this.patient.cache.careTeam ? this.patient.cache.careTeam.specialty : [];
      this.other = this.patient.cache.careTeam ? this.patient.cache.careTeam.other : [];
    },
    async updateCareTeam() {
      this.errors = [];
      const token = await this.$auth.getTokenSilently();
      const data = {
        patient_id: this.patient.id,
        pcp: this.pcp,
        care_manager: this.care_manager,
        specialty: this.specialty,
        other: this.other,
      };
      Vue.$http.put(`/api/rpm_ccm_dashboard/care-team`, data, {params: {token: token}})
          .then(() => {
            this.$emit("get-patient-details");
            this.$emit('hide-edit-care-team');
          })
          .catch((error) => this.handleEhrApiErrors(error, this.errors, this.$refs.editCareTeamRef));
    },
  },
  computed: {
    ct: function () {
      return {
        pcp: this.patient.cache.careTeam ? this.patient.cache.careTeam.pcp : null,
        care_manager: this.patient.cache.careTeam ? this.patient.cache.careTeam.care_manager : null,
        specialty: this.patient.cache.careTeam ? this.patient.cache.careTeam.specialty : [],
        other: this.patient.cache.careTeam ? this.patient.cache.careTeam.other : [],
      };
    },
    careTeam: function () {
      return {
        pcp: this.$store.state.patients.providers.find(p => p.id === this.ct.pcp),
        care_manager: this.$store.state.patients.providers.find(p => p.id === this.ct.care_manager),
        specialty: this.$store.state.patients.providers.filter(p => this.ct.specialty.find(t => t === p.id)),
        other: this.$store.state.patients.providers.filter(p => this.ct.other.find(t => t === p.id)),
      };
    },
    providers: function () {
      return this.$store.state.patients.providers.filter(p => p.behaviors.includes('is_provider'));
    },
    healthCoaches: function () {
      return this.$store.state.patients.providers.filter(p => p.behaviors.includes('is_health_coach'));
    },
  }
}
</script>
<template>
  <div class="grid gap-4">
    <ul role="list" class="divide-y divide-gray-100">
      <team-member v-if="careTeam.pcp" :person="careTeam.pcp" type="PCP" :key="'pcp' + careTeam.pcp.id"/>
      <team-member v-if="careTeam.care_manager" :person="careTeam.care_manager" type="Care Manager" :key="'cm' + careTeam.care_manager.id"/>
      <team-member v-if="careTeam.specialty" v-for="person in careTeam.specialty" :key="'sp' + person.id" :person="person" type="Specialist"/>
      <team-member v-if="careTeam.other" v-for="person in careTeam.other" :key="'o' + person.id" :person="person" type="Other"/>
    </ul>
    <el-dialog :close-on-click-modal="false" :title="'Edit Care Team'" :visible.sync="editCareTeamVisibleInternal"  @close="$emit('hide-edit-care-team')">
      <div class="dialog__body">
        <div ref="editCareTeamRef">
          <RpmDashboardErrors v-bind:errors="errors" v-bind:title="'Edit Care Team Errors'"/>
          <div class="grid gap-4 grid-cols-2">
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="select_pcp"> PCP </label>
              <el-select id="select_pcp" v-model="pcp" value-key="id" clearable placeholder="PCP" filterable class="w-full">
                <el-option v-for="provider in providers" :value="provider.id" :key="provider.id" :label="`${provider.first_name} ${provider.last_name} ${provider.email}`"/>
              </el-select>
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="select_care_manager"> Health Coach </label>
              <el-select id="select_care_manager" v-model="care_manager" value-key="id" clearable placeholder="Health Coach" filterable class="w-full">
                <el-option v-for="provider in healthCoaches" :value="provider.id" :key="provider.id" :label="`${provider.first_name} ${provider.last_name} ${provider.email}`"/>
              </el-select>
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="select_specialty"> Specialty </label>
              <el-select id="select_specialty" v-model="specialty" value-key="id" multiple clearable placeholder="Specialty" filterable class="w-full">
                <el-option v-for="provider in providers" :value="provider.id" :key="provider.id" :label="`${provider.first_name} ${provider.last_name} ${provider.email}`"/>
              </el-select>
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="select_other"> Other </label>
              <el-select id="select_other" v-model="other" value-key="id" clearable placeholder="Other" multiple filterable class="w-full">
                <el-option v-for="provider in providers" :value="provider.id" :key="provider.id" :label="`${provider.first_name} ${provider.last_name} ${provider.email}`"/>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <el-button type="primary" @click="updateCareTeam">Update Care Team</el-button>
    </el-dialog>
  </div>
</template>
<style scoped>

</style>