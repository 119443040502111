<template>
  <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4" role="alert" v-show="errors.length">
    <p class="font-bold text-lg">{{ title }}</p>
    <p class="font-bold" v-for="(error, propertyName, index)  in errors" v-bind:key="index">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: "RpmDashboardErrors",
  props: ['errors', 'title'],
};
</script>