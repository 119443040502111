<template>
  <div>
    <div class="wrapper">
      <div class="d-flex flex-column linear-container">
        <div class="flex josefin_font">
          <img
            src="@/assets/white-back.svg"
            @click="goBack"
            style="cursor: pointer"
          />
          <h3 class="header-text">Care Coach Activity Report </h3>
        </div>
      </div>
    </div>
    <div class="row comp_container">
      <div class="flex flex-wrap">
        <div class="w-full">
          <div class="relative inline-block text-left w-full">
            <div class="details__containerInfo flex">
              <div class="info__container">
                <h3 class="lato">Kelani Anderson</h3>
                <p class="lato">Caretaker</p>
              </div>
              <div class="info__container">
                <h3 class="lato">Colin Siaw</h3>
                <p class="lato">Supervisor</p>
              </div>
              <div class="info__container">
                <h3 class="lato">ABC-123</h3>
                <p class="lato">MPI Code</p>
              </div>
              <div class="info__container">
                <h3 class="lato">5h 22m 35s</h3>
                <p class="lato">Total Hours</p>
              </div>
            </div>
            <div class="flex justify-between medical__container mb-5">
              <div class="flex filter__container">
                  <label for="filter" class="txt__filter lato"
                    >FILTERS</label
                  >
                  <div class="relative mt-1 ml-4">
                    <input
                      type="date"
                      class="lato"
                    />
                    <input
                      type="text"
                      class="lato"
                      placeholder="Search patients..."
                    />
                  </div>
              
              </div>

              <div>
                <p class="txt__refresh lato">Refresh</p>
              </div>
            </div>
            <table
                class="w-full text-sm text-left text-gray-500 dark:text-gray-400 table__modify"
                id=""
              >
                <thead
                  class="text-xs text-gray-700 uppercase border__gray"
                >
                  <tr>
                    <th scope="col" class="px-6 py-6">
                      <div class="flex items-center">
                        Patient name 
                        <a href="#">
                          <svg xmlns="http://www.w3.org/2000/svg"
                                class="w-3 h-3 ml-1"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 320 512">
                                <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"/>
                          </svg>
                        </a>
                       </div>
                    </th>
                 
                    <th scope="col" class="px-6 py-6">
                      <div class="flex items-center">
                        Date
                        <a href="#">
                          <svg xmlns="http://www.w3.org/2000/svg"
                                class="w-3 h-3 ml-1"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 320 512">
                                <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"/>
                          </svg>
                        </a>
                      </div>
                    </th>
                    <th scope="col" class="px-6 py-6">
                      <div class="flex items-center">
                        Task
                        <a href="#">
                          <svg xmlns="http://www.w3.org/2000/svg"
                                class="w-3 h-3 ml-1"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 320 512">
                                <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"/>
                          </svg>
                        </a>
                      </div>
                    </th>
                    <th scope="col" class="px-6 py-6">
                      <div class="flex items-center">
                        CPT Code
                        <a href="#">
                          <svg xmlns="http://www.w3.org/2000/svg"
                                class="w-3 h-3 ml-1"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 320 512">
                                <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"/>
                          </svg>
                        </a>
                      </div>
                    </th>
                    <th scope="col" class="px-6 py-6">
                      <div class="flex items-center">
                        Hours
                        <a href="#">
                          <svg xmlns="http://www.w3.org/2000/svg"
                                class="w-3 h-3 ml-1"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 320 512">
                                <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"/>
                          </svg>
                        </a>
                      </div>
                    </th>
                    <th scope="col" class="px-6 py-6">
                      <div class="flex items-center">
                        Actions
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
       
                  <tr
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td scope="row" class="px-6 py-5 col__blue">
                      <a
                        class="col__blue"
                        href="/rpm-dashboard/patient-information"
                        >Esther Howard</a
                      >
                    </td>
                    <td class="px-6 py-5">Dec 12, 2021</td>
                    <td class="px-6 py-5">Contact patient via telephone</td>
                    <td class="px-6 py-5">99396</td>
                    <td class="px-6 py-5">01:31:22</td>
                    <td class="px-6 py-5"></td>
                  </tr>
                </tbody>
              </table>
          </div>
          
        </div>
       
      </div>
    </div>
  
  </div>
</template>

<script>
export default {
  data() {
    return {
      openTabModal: 1,
      dialogMenu: false,
      dialogAction:false,
      dialogAction2:false,
      dialogVisibleMedical: false
    }
  },
  computed: {


  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    toggleTabs: function(tabNumber){
      this.openTabModal = tabNumber
    },
    closeDialogModalMedical() {
      this.dialogVisibleMedical= false;
    },
    openModalMedical() {

     this.dialogVisibleMedical = true;

   },


  },
}
</script>

<style scoped>
.wrapper {
  background: linear-gradient(90deg, #5e60ce 66.28%, #48bfe3 100%);
}
.border__gray {
  border-bottom: 1px solid #EEEEEE;
  border-top: 1px solid #EEEEEE;
}

.linear-container {
  padding: 3rem 20px;
  max-width: 1440px;
  margin: auto;
  background: initial;
}

.comp_container {
  display: block !important;
  max-width: 1440px;
  margin: auto;
  padding: 0px 20px;
  margin-top: 50px;
}

.table__modify tr th div {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  text-transform: capitalize;
  align-items: center;
  color: #292929;
}

.table__modify tr th div svg {
  color: #9a9a9a;
}

.table__modify tbody tr .col__blue {
  color: #48bfe3;
  font-weight: 400;
}

.table__modify tbody tr .col__red {
  color: #df5959;
  font-weight: 400;
}
.table__modify tbody tr .col__italic {
  color: #9a9a9a;
  font-weight: 400;
  font-style: italic;
}
.table__modify tbody tr {
  font-family: "Lato";
  font-style: normal;
  color: #292929;
}

/* Button Menu Css */


.medical__container  {
  align-items: center;
}

.medical__container .flex p:nth-child(1) {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #292929;
}

.medical__container .flex p:nth-child(1) span {
  color: #9a9a9a;
  font-weight: 400;
}
.txt__refresh,
.medical__container .flex a:nth-child(2) {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #48bfe3 !important;
}



/* Modal Assign DMP */


.col__blue {
  color: #48bfe3 !important;
  font-weight: 400;
}
.table__modify tr th div {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  text-transform: capitalize;
  align-items: center;
  color: #292929;
}
.table__modify tbody tr {
  font-family: "Lato";
  font-style: normal;
  color: #292929;
}



.border__bottom-gray {
  border-bottom-width: 1px;
    border-color: #EEEEEE;
}

/* Filters */

.filter__container {
  align-items:center;
}

.filter__container .txt__filter{
  letter-spacing: 0.2px;
text-transform: uppercase;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #292929;
}
.filter__container .relative input{
  background: #EEEEEE;
  border-radius: 8px;
  border:none;
  padding:10.5px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin-right:24px;
  color:#9A9A9A;
  width:220px;
}
.filter__container .relative input:nth-child(1){
  color: #292929;
}
.details__containerInfo {
  margin-bottom:40px;
  gap:80px
}
.details__containerInfo .info__container h3{
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
  color: #292929;
  margin-bottom:8px;
}

.details__containerInfo .info__container p{
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #9A9A9A;
}


</style>
