<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title=""
      :visible.sync="showModal"
      width="70%"
      :show-close="true"
      :before-close="closeModal"
    >
    <div class="border-b border-gray-300">
      <h3 class="absolute top-7 text-3xl text-black font-semibold josefin pl-10">Physician Advisor</h3>
    </div>
    <section class="flex px-4 py-4 align-left--mobile">
      <div class="w-3/12 flex justify-items-start items-center flex-col w-100-mb">
        <img src="@/assets/hiring-img.svg" />
        <h5 class="font-semibold text-xl pt-4 pb-1 text-black josefin">MedSchoolCoach</h5>
        <div class="flex items-center">
          <img src="@/assets/location.svg" alt="">
          <span class="pl-2 pt-1 text-secondary">Austin, TX</span>
        </div>
      </div>
      <div class="w-9/12 py-3 pr-8 lato body-container">
        <h4 class="text-xl fint-semibold text-black josefin pb-1">Job description</h4>
        <div class="flex items-center pt-1 pb-4 job-desc-mobile">
          <div class="bg-lightpurple text-mainpurple font-semibold px-3 py-2 rounded-full">$80/hr</div>
          <div class="bg-gray-100 text-secondary mx-3 px-3 py-2 rounded-full physician-text">Physician</div>
          <div class="bg-gray-100 text-secondary px-3 py-2 rounded-full">Posted 19 August 2021</div>
        </div>
        <p class="text-base">
          MedSchoolCoach is a medical school admissions consulting company and is seeking
          talented physicians to join our team! We currently work with over a thousand
          pre-medical students each year to help them become successful applicants to
          medical school. As physicians, we know how difficult the process of applying
          to medical school can be and we use our experience and expertise to guide
          them through the entire application.
        </p>
        <p class="pt-2 pb-6 text-base">
          We are especially interested in physicians who are retired and want to stay
          active with medical education or those who are no longer practicing clinical
          medicine full time and can devote 20+ hours a week to our students.
        </p>
        <div class="lato">
          <h4 class="font-semibold text-base">In this role, you’ll get to</h4>
          <ul class="ml-7">
            <li class="list-disc">
              Work directly with pre-medical applicants who are applying to medical school
            </li>
            <li class="list-disc mt-2">
              Work one-on-one with the student through advising sessions, personal statement
              and essay editing, medical school list creation, and interview preparation (the
              interaction with clients is virtual, via email, video conferencing and telephone)
            </li>
          </ul>
        </div>
      </div>
    </section>
      <span slot="footer" class="josefin dialog-footer">
        <!-- <el-button type="primary" @click="activate">Apply Now</el-button> -->
      </span>
    </el-dialog>
    <submit-application-modal v-if="showMe" v-model="showMe"></submit-application-modal>
  </div>
</template>

<script>
import SubmitApplicationModal from './SubmitApplication.vue'

export default {
  components: {
    SubmitApplicationModal
  },
  model: {
    prop: 'visible',
    event: 'setVisibility'
  },
  props: ['visible'],
  data() {
    return {
      showModal: false,
      showMe: false,
    }
  },
  watch: {
    visible(val) {
      this.showModal = val
    },
    showModal(val) {
      this.$emit('setVisibility', val)
    }
  },
  mounted() {
    this.showModal = this.visible
  },
  methods: {
    closeModal() {
      this.$emit('setVisibility', false)
    },
    activate() {
      this.showMe = true
    }
  }
}
</script>

<style scoped>
* >>> .el-dialog__header {
  padding: 20px 20px 71px;
}

* >>> .el-dialog__body {
  padding: 0;
}

.border-b {
  border-bottom-width: 0;
}
.align-left--mobile .text-base{
  word-break: break-word;
}
@media (max-width: 991px) {
* >>>  .el-dialog {
    width: 95% !important;
  }

  .align-left--mobile {
    gap: 20px;
  }
}

@media (max-width: 700px) {


  * >>>  .el-dialog .el-dialog__body {
    padding: 12px 0px!important;
  }

  .align-left--mobile {
    flex-direction: column;
  }
  .w-100-mb {
  width: 100% !important;
}

.body-container {
  width: 100%;
    padding: 0px;
}
}

@media (max-width: 450px) {
.job-desc-mobile {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

.job-desc-mobile .physician-text {
margin: 0px;
}
}

</style>