<template>
  <div class="d-flex justify-content-center flex-column align-items-center details_container">

    <h2 class="josefin_font font-2xl">Thank you for confirming your account</h2>

    <br>

    <p class="lato details">
      Logout using the button below, and click on <strong>Forgot Password?</strong> to reset your password
      and get full access.
    </p>

    <p class="mt-4 logout" @click="logout">Logout</p>

  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$auth.logout()
    }
  },
}
</script>

<style>
.details {
  width: 33.5rem;
  text-align: center;
  color: #666666;
  font-size: 1rem;
  line-height: 1.5rem;
}

.details_container {
  margin-top: 10rem;
}

.logout {
  color: #48BFE3;
  cursor: pointer;
}
.details_container {
  margin-top: 10rem;
}
/* Responsive Design */
@media (max-width: 991.98px) {
  .details {
    width: auto;
  }
  .details_container {
    padding: 0 0.2rem;
    margin-top: 0rem;
  }
  .details_container h2 {
    font-size: 1.625rem;
  }
  .details_container p {
    font-size: 0.875rem;
  }

}
</style>