<template>
  <div class="bg-white rounded-lg text-gray-700 p-10">
    <div class="mb-4">
      <div class="text-md text-gray-700">Patient Local Datetime ({{ patient.timezone ? patient.timezone.replace('_', ' ') : 'No Timezone Set' }}):</div>
      <div class="clock">{{ currentDT }}</div>
    </div>
    <task-timer :patient="patient" :health-coaches="healthCoaches" v-on:get-patient-details="$emit('get-patient-details')" />
    <div class="grid md:grid-cols-2 lg:grid-cols-1 gap-2">
      <div class="grid md:col-span-2 lg:col-span-1 mb-2">
        <div class="flex flex-col items-center">
          <img v-if="patient.profilePicSmall" :src="patient.profilePicSmall"  width="100" height="100" />
          <img v-else-if="patient.sex === 'f'" :src="require('@/assets/profiles/female.svg')">
          <img v-else :src="require('@/assets/profiles/male.svg')" width="100" height="100">
          <div class="flex flex-col items-center mt-2">
            <p class="text-xl font-bold">
              {{ patient.first_name }} {{ patient.middle_name }}
              {{ patient.last_name }}
            </p>
            <p v-if="this.patient.dob" class="text-sm">{{ age }} years old</p>
            <p class="text-sm text-center">
              {{ patient.address1 }} {{ patient.address2 }} {{ patient.city }}
              {{ patient.state }} {{ patient.zip }}
            </p>
          </div>
        </div>
      </div>
      <div class="grid col-span-1 md:mx-5 lg:mx-0">
        <div class="text-sm mt-2">
          <div class="mt-2" v-for="comment in patient.patient_comments" :key="comment.id">
            <div class="">
              <p>{{ comment.note }}</p>
              <div class="flex justify-between">
                <div class="pt-2 truncate text-xs leading-5 text-gray-500">{{providerName(comment.user_id)}}</div>
                <el-button size="mini" @click="showEditComment(comment)" >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                  </svg>
                </el-button>
              </div>
            </div>
            <hr>
          </div>
        </div>
        <div class="text-sm mt-5">
          <el-button size="mini" @click="showEditComment({})" class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Create Comment</el-button>
          <el-button size="mini" @click="showOrderForm()" class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Order Forms</el-button>
        </div>
      </div>
      <div class="grid col-span-1 md:mx-5 lg:mx-0">
        <div class="text-sm mt-5">
          <div class="flex justify-between">
            <p>Sex</p>
            <p>{{ patient.sex === 'm' ? 'Male' : 'Female' }}</p>
          </div>
          <div class="flex justify-between">
            <p>Client</p>
            <p>{{ this.$store.getters.clientName(patient.primary_client_id) }}</p>
          </div>
          <div class="flex justify-between">
            <p>Status</p>
            <p>{{ patient.status }}</p>
          </div>
          <div class="flex justify-between">
            <p>Phone</p>
            <p>{{ patient.phone }}</p>
          </div>
          <div class="flex justify-between">
            <p>Cell</p>
            <p><el-button @click="smsModalActive = true" size="mini" type="primary">SMS</el-button></p>
            <el-dialog :close-on-click-modal="false" center title="Secure Messaging Service" :visible.sync="smsModalActive" width="80%">
              <div class="dialog__body">
                <Sms :patient="patient" v-on:get-patient-details="$emit('get-patient-details')" :templates="smsTemplates" />
              </div>
            </el-dialog>
            <p><el-button @click="smsScheduleModalActive = true" size="mini" type="primary">Schedule SMS</el-button></p>
            <el-dialog :close-on-click-modal="false" center title="SMS Schedule" :visible.sync="smsScheduleModalActive" width="80%">
              <div class="dialog__body">
                <div class="mb-4">
                  <label class="block text-gray-700 text-sm font-bold mb-2" for="rpm_note"> Select Template </label>
                  <select id="rpm_note" v-on:change="templateChange" v-model="selectedTemplate"
                          class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    <option value=""></option>
                    <option v-for="template in smsTemplates" v-bind:value="template" v-bind:key="template.id">
                      {{ template.name }}
                    </option>
                  </select>
                </div>

                <div class="border rounded mb-2">
                  <h1 class="m-2 font-weight-bold">Schedule New Message</h1>
                  <input type="datetime-local" v-model="smsScheduleNewMessage.scheduled_at" />
                  <textarea type="text" ref="scheduleMessageRef" @keydown.tab.prevent="tabTemplate('scheduleMessageRef')" class="w-full" v-model="smsScheduleNewMessage.message" placeholder="SMS Message"></textarea>
                  <v-btn :disabled="!smsScheduleNewMessage.message || !smsScheduleNewMessage.scheduled_at || loading" class="m-2 blue" @click="scheduleSms">&plus; Schedule</v-btn>
                </div>
                <div>
                  <v-data-table
                      :headers="smsScheduleHeaders"
                      :items="patient.sms_schedules"
                      :sort-desc="[true]"
                      :sort-by="['scheduled_at']"
                      class="elevation-1"
                  >
                    <template v-slot:[`item.scheduled_at`]="{ item }">
                      {{ item.scheduled_at | datetime }}
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <span v-if="item.status != 'sent'">
                        <v-btn class="m-2 red" @click="scheduleSmsDelete(item.id)" :disabled="loading">Delete</v-btn>
                        <v-btn class="m-2 green" @click="scheduleSmsSendNow(item.id)" :disabled="loading">Send Now</v-btn>
                      </span>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </el-dialog>
          </div>
          <div class="flex justify-between">
            <p>Call Patient</p>
            <p><RingCentralEmbeddable :cell="patient.cell" :phone="patient.phone" class="text-right break-all" ref="ringCentralEmbeddableRef"></RingCentralEmbeddable></p>
          </div>
          <div class="flex justify-between">
            <p class="mr-3">Email</p>
            <a v-if="patient.email" class="text-right break-all" @click="openEmail(patient.email)">{{ patient.email }}</a>
          </div>
        </div>
      </div>
      <div class="grid col-span-1 md:mx-5 lg:mx-0">
        <div class="text-sm mt-5">
          <div class="flex justify-between">
            <p>Appointment Date</p>
            <edit-appointment-date :patient-id="patient.id" :date="patient.nad" v-on:get-patient-details="$emit('get-patient-details')">
              {{patient.nad | dateNonLocal}}
            </edit-appointment-date>
          </div>
          <div class="flex justify-between">
            <p>RPM</p>
            <p>{{ patient.cache.rpm_date | date }}</p>
          </div>
          <div class="flex justify-between">
            <p>CCM</p>
            <p>{{ patient.cache.ccm_date | date }}</p>
          </div>
          <div class="flex justify-between">
            <p>DOB</p>
            <p>{{ dob }}</p>
          </div>
          <div class="flex justify-between">
            <p>eMHR ID</p>
            <p>{{ patient.id || "-" }}</p>
          </div>
          <div class="flex justify-between">
            <p>TOS Signed</p>
            <p v-if="patient.agreed_to_client_form">{{ patient.agreed_to_client_form | date }}</p>
            <p v-else>-</p>
          </div>
          <div class="flex justify-between">
            <p>Tags</p>
            <p>{{ patient.tags.join(', ') }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 grid grid-cols-1">
      <Collapse :text="'Emergency Contact'">
        <div class="flex justify-between">
          <p>First Name</p>
          <p>{{ patient.emergency_contact.first_name }}</p>
        </div>
        <div class="flex justify-between">
          <p>Last Name</p>
          <p>{{ patient.emergency_contact.last_name }}</p>
        </div>
        <div class="flex justify-between">
          <p>Email</p>
          <p>{{ patient.emergency_contact.email }}</p>
        </div>
        <div class="flex justify-between">
          <p>Phone</p>
          <p>{{ patient.emergency_contact.phone }}</p>
        </div>
        <div class="flex justify-between">
          <p>Cell</p>
          <p>{{ patient.emergency_contact.cell }}</p>
        </div>
        <div class="flex justify-between">
          <p>Address</p>
          <p class="justify-end"><br/>{{ patient.emergency_contact.address1 }} {{ patient.emergency_contact.address2 }}<br/>{{ patient.emergency_contact.city }} {{ patient.emergency_contact.state }} {{ patient.emergency_contact.zip }}</p>
        </div>
      </Collapse>
    </div>

    <div class="mt-2 grid grid-cols-2">
      <div>Care Team:</div>
      <div>
        <el-button size="mini" @click="showEditCareTeam" class="float-right rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Edit Care Team</el-button>
      </div>
    </div>
    <care-team v-on:hide-edit-care-team="hideEditCareTeam" v-on:get-patient-details="$emit('get-patient-details')" :editCareTeamVisible="editCareTeamVisible" :patient="patient" />
    <comment v-on:hide-edit-comment="hideEditComment" v-on:get-patient-details="$emit('get-patient-details')" v-if="editCommentVisible" :patient="patient" :comment="editComment" />

    <el-dialog :close-on-click-modal="false" :title="'Order Form'" width="40%" @close="hideOrderForm" :visible="orderFormVisible">
      <div class="dialog__body">
        <div ref="orderFormRef">
          <order-form :patient="patient" @get-patient-details="$emit('get-patient-details')" @hide-order-form="hideOrderForm" />
        </div>
      </div>
    </el-dialog>

    <hr class="mt-4 mb-4"/>

    <div class="mb-4">
      <label>Order Form Files</label>
      <v-data-table :headers="dtheaders" :items="patient.order_form_files" :sort-by="['date_created']" :sort-desc="[true]" fixed-header height="400">
        <template v-slot:[`item.original_filename`]="{ item }">
          <a target="_blank" :href="orderFormLink(item.id)">{{ item.type === 'rpm' ? "RPM File" : "CCM File" }}</a>
        </template>
        <template v-slot:[`item.date_created`]="{ item }">
          {{ dateFormatter(item, {property: 'date_created'}) }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import TaskTimer from './TaskTimer.vue';
import CareTeam from "@/components/CareTeam.vue";
import Comment from "@/components/Comment.vue";
import OrderForm from "@/components/OrderForm.vue";
import Sms from "@/views/rpm/patient-info/components/Sms.vue";
import RingCentralEmbeddable from "@/components/RingCentralEmbeddable.vue";
import Collapse from "@/components/Collapse.vue";
import {mapState} from "vuex";
import Vue from "vue";
import Template from "@/mixins/Template";
import EditAppointmentDate from "@/views/rpm/patient-info/components/EditAppointmentDate.vue";

export default {
  components: {EditAppointmentDate, RingCentralEmbeddable, Sms, OrderForm, CareTeam, TaskTimer, Comment, Collapse},
  props: {
    patient: Object,
  },
  mixins: [Template],
  data() {
    return {
      loading: false,
      currentDT: '',
      editCareTeamVisible: false,
      editCommentVisible: false,
      editComment: null,
      orderFormVisible: false,
      accessToken: null,
      smsModalActive: false,
      smsScheduleModalActive: false,
      smsScheduleNewMessage: {
        message: '',
        scheduled_at: null,
      }
    };
  },
  mounted() {
    this.getAccessToken();
    this.updateTime();
    setInterval(this.updateTime, 1000);
  },
  methods: {
    async getAccessToken() {
      this.accessToken = await this.$auth.getTokenSilently();
    },
    updateTime() {
      if (this.patient.timezone) {
        this.currentDT = moment().tz(this.patient.timezone).format('MMM Do, h:mm:ss a');
      } else {
        this.currentDT = moment().format('MMM Do, h:mm:ss a');
      }
    },
    orderFormLink(file_id) {
      return `${process.env.VUE_APP_API_URL || 'http://localhost:8000/'}api/rpm_ccm_dashboard/get_order_form_doc?file_id=${file_id}&token=${this.accessToken}`;
    },
    dateFormatter(row, column) {
      return moment(row[column.property]).format("MMM D, YYYY hh:mm A");
    },
    providerName(id) {
      return this.$store.getters.providerName(id);
    },
    hideEditCareTeam() {
      this.editCareTeamVisible = false;
    },
    hideEditComment() {
      this.editCommentVisible = false;
    },
    showEditCareTeam() {
      this.editCareTeamVisible = true;
    },
    showEditComment(comment) {
      this.editComment = comment || {id: null, note: null};
      this.editCommentVisible = true;
    },
    showOrderForm() {
      this.orderFormVisible = true;
    },
    hideOrderForm() {
      this.orderFormVisible = false;
    },
    openEmail(email) {
      const url = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(email)}&su=RPM%2FCCM&body=${this.patient.first_name}%20${this.patient.last_name}`;
      window.open(url, '_blank');
    },
    async scheduleSms() {
      if (!this.smsScheduleNewMessage.message || !this.smsScheduleNewMessage.scheduled_at || this.loading) {
        return false;
      }
      this.loading = true;

      var utcDateTime = moment(this.smsScheduleNewMessage.scheduled_at).utc().format("YYYY-MM-DD HH:mm:ss");

      Vue.$http.post(`/api/rpm_ccm_dashboard/schedule-sms`, null, { params: { token: await this.$auth.getTokenSilently(), recipient_id: this.patient.id, scheduled_at: utcDateTime, message: this.smsScheduleNewMessage.message } })
          .then(async () => {
            this.$emit('get-patient-details');
            this.$awn.success('SMS Scheduled');

          }).catch((error) => {
        this.$awn.alert(error.response.data.message);
      })
      .catch((error) => this.$awn.warning(error))
      .finally(() => {
        this.loading = false;
        this.smsScheduleNewMessage.scheduled_at = null;
        this.smsScheduleNewMessage.message = '';
        this.selectedTemplate = null;
        this.notes = null;
      });
      return false;
    },
    async scheduleSmsSendNow(id) {
      if (this.loading) {
        return false;
      }
      this.loading = true;
      Vue.$http.post(`/api/rpm_ccm_dashboard/schedule-sms-send-now`, null, { params: { token: await this.$auth.getTokenSilently(), sms_id: id } })
          .then(async () => {
            this.$awn.success('SMS Sent Now');
            this.$emit('get-patient-details');
          }).catch((error) => {
            this.$awn.alert(error.response.data.message);
            this.$emit('get-patient-details');
        })
        .finally(() => {
          this.loading = false;
        });
      return false;
    },
    async scheduleSmsDelete(id) {
      this.loading = true;
      Vue.$http.delete(`/api/rpm_ccm_dashboard/schedule-sms-delete`, { params: { token: await this.$auth.getTokenSilently(), sms_id: id } })
          .then(async () => {
            this.$emit('get-patient-details');
            this.$awn.success('SMS Scheduled Deleted');
          }).catch((error) => {
        this.$awn.alert(error.response.data.message);
      })
          .catch((error) => this.$awn.warning(error))
          .finally(() => {
            this.loading = false;
          });
    },
  },
  computed: {
    authUser() {
      return this.$store.state.patients.authUser;
    },
    payers() {
      return this.$store.state.patients.payers;
    },
    ...mapState(["patients"]),
    dtheaders() {
      return [
        {text: 'File', value: 'original_filename'},
        {text: 'Created At', value: 'date_created'},
      ]
    },
    smsScheduleHeaders() {
      return [
        {text: 'ID', value: 'id'},
        {text: 'Message', value: 'message', width: '400px'},
        {text: 'Scheduled At', value: 'scheduled_at'},
        {text: 'Scheduled By', value: 'sender_user_name'},
        {text: 'Status', value: 'status'},
        {text: 'Actions', value: 'actions', sortable: false},
      ]
    },
    dob() {
      return this.patient.dob ? moment(this.patient.dob).format("MMM D, YYYY") : ''
    },
    age() {
      return this.patient.dob ? moment.duration(moment().diff(this.patient.dob)).years() : '';
    },
    templates: function () {
      return this.patients.templates;
    },
    smsTemplates: function () {
      return this.templates.filter(t => t.category === 'patient_sms');
    },
    healthCoaches() {
      return this.$store.state.patients.providers.filter(p => p.behaviors.includes('is_health_coach') && !p.disabled);
    },
  },
  watch: {
    notes: function(notes) {
      this.smsScheduleNewMessage.message = notes;
    },
  }
};
</script>

<style scoped>
.text-right {
  text-align: right;
}

.clock {
  font-size: 22px;
  font-weight: 500;
 }
</style>