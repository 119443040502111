export default {
  methods: {
    goToPatient(id, newTab = false) {
      if (newTab) {
        window.open(`/rpm-dashboard/patient-information/${id}`, '_blank');
      } else {
        this.$router.push({ name: 'rpm-dashboard-patient-information', params: { id: id } });
      }
    },
  }
}