<template>
  <div class="mt-10 spacing_container" id="membership" v-if="this.secretKeys">

    <h2 class="text-center josefin_font font-bold text-4xl">
      Flexible Plans
    </h2>

    <p class="text-center font-medium mt-4 mb-5 text-xl josefin_font">Choose a plan that best suits your needs</p>
    <div class="membership_container">

      <div class="membership_card" v-for="(item, index) in allSubscriptions" :key="index">

        <div>

          <p class="font-medium text-gray">{{item.name}}</p>
          <h3 v-if="item.amount === null" class="josefin_font py-2 font-bold text-3xl">Free</h3>
          <h3 v-else class="py-2 font-bold text-3xl">${{item.amount}}<span class="month josefin_font">/month</span></h3>
          <p class="font-medium text-md text-gray">{{item.description}}</p>

          <div class="my-3 flex" v-for="(x, index) in item.list_items" :key="index">

            <span>

              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="#DFE0FF"/>
                <path d="M16 9L10.5 14.5L8 12" stroke="#5E60CE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </span>

            <p class="font-medium pl-2 text-gray">{{ x.name }}</p>

          </div>
          <div v-if="item.other" class="mt-4">
            <p class="text-gray pb-4 md:pb-4 xl:pb-0">{{ item.other }}</p>
          </div>
        </div>

        <div class="">
         <div class="josefin_font membership-button__container">
            <button
              @click="loadStripe(item)"
              :class="[profile.subscriptionPlan === 'Premier' ? 'current_button' : 'membership_card_button']"
              v-show="item.name === 'Premier'"
            >
              {{ profile.subscriptionPlan === 'Premier' ? 'Current Plan' : 'Select' }}
            </button>
          </div>
          <div class="josefin_font membership-button__container">
            <button
              @click="loadStripe(item)"
              :class="[profile.subscriptionPlan === 'Essentials' ? 'current_button' : 'membership_card_button']"
              v-show="item.name === 'Essentials'">
              {{ profile.subscriptionPlan === 'Essentials' ? 'Current Plan' : 'Select' }}
            </button>
          </div>
          <div class="josefin_font membership-button__container">
            <button
              @click="loadStripe(item)"
              :class="[profile.subscriptionPlan === 'Enhanced' ? 'current_button' : 'membership_card_button']"
              v-show="item.name === 'Enhanced'"
            >
              {{ profile.subscriptionPlan === 'Enhanced' ? 'Current Plan' : 'Select' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <comparison-chart></comparison-chart>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ComparisonChart from '@/views/ComparisonChart.vue'

export default {
  components: {
    ComparisonChart
  },
  data() {
    return {
      allSubscriptions: this.$store.state.allSubscriptions
    }
  },
  mounted() {
    window.scrollTo(0,0);
  },
  methods: {
    ...mapActions([
      'fetchSecretKeys',
      'createCheckoutSession',
      'updateSubscription',
      'retrieveSubscription',
      // 'createHubspotRecord',
      // 'updateHubspotRecord',
      // 'getHubspotRecord'
    ]),
    // loadStripe(item) {
    //  this.getHubspotRecord(this.$auth.user.email.replace('+', ''))
    //   .then((res) => {
    //     // Update Hubspot if the getHubspotRecord API response is not null and working
    //     const payloadHubspotUpdate = {
    //       id: res.data,
    //       membership_tier: item.name.charAt(0).toUpperCase() + item.name.slice(1)
    //     }
    //     this.updateHubspotRecord(payloadHubspotUpdate)
    //   })
    //   .catch(() => {
    //     // Create Hubspot if the getHubspotRecord API response is null or error
    //     const full_name = this.$auth.user.name.split(" ");
    //     const payloadHubspotCreate = {
    //       email: this.$auth.user.email.replace('+', ''),
    //       first_name: full_name[0],
    //       last_name: full_name[1],
    //       membership_tier: item.name.charAt(0).toUpperCase() + item.name.slice(1)
    //     }
    //      this.createHubspotRecord(payloadHubspotCreate)
    //   })
    //
    // // This is for subscription functionalities
    //   if (item.name === 'Essentials') return false
    //   if(this.profile.customerID) {
    //     const payload = {
    //       customer_id: this.profile.customerID
    //     }
    //     this.updateSubscription(payload)
    //     .then((res) => {
    //     if(res.status === 200) {
    //       const link = document.createElement('a')
    //       link.href = res.data
    //       link.click()
    //     }
    //   })
    //   } else {
    //     const payload = {
    //     email: this.$auth.user.email,
    //     price_id: item.name === 'Enhanced' ? this.secretKeys.enchancedPriceId : this.secretKeys.premierPriceId
    //   }
    //   this.createCheckoutSession(payload)
    //   .then((res) => {
    //     if(res.status === 200) {
    //       const link = document.createElement('a')
    //       link.href = res.data
    //       link.click()
    //     }
    //   })
    //   }
    // },

    goToMemberShip() {
      this.$router.push({ name: 'access' });
    },
    
  },
  computed: {
    ...mapState(['secretKeys', 'profile']),
  },
}
</script>

<style>
.spacing_container {
  padding: 0 12.75rem;
}
@media (max-width: 1280px) {
#membership.spacing_container {
  padding: 0 20px;
}

}
.memberShip-Header {
  color: #00E764;
}

.font-xl {
  font-weight: bold;
  font-size: 1.55rem;
  line-height: 2rem;
}

.font-2xl {
  font-size: 2.188rem;
  line-height: 2.625rem;
}

.membership_container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
}

.membership_card {
  border: 0.063rem solid #5E60CE;
  border-radius: 0.5rem;
  padding: 1.5rem 2rem;
  margin-bottom: 1.5rem;
  position: relative;
  height: 45rem;
}

.membership-button__container {
  position: absolute;
  bottom: 2rem;
  width: 80.3%;
}

.month {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
}

.membership_card_button {
  color: white !important;
}

.membership_card_button:hover{
  color: white !important;
}

.membership_card:nth-child(3) > div:nth-child(2) {
  top: 5rem;
}

.select {
  width: 146px;
  text-align: center;
  color: white !important;
  background: #02E864;
  padding: 0.8rem 0;
  border-radius: 8px;
  border: 0;
  font-size: 12px;
}

.current_plan_chart {
  color: #5E60CE !important;
  background: #F2F3FF;
  width: auto;
  text-align: center;
  padding: 0.8rem 2rem;
  border-radius: 8px;
  border: 0;
  font-size: 12px;
}

.current_plan_chart:hover {
  color: #5E60CE !important;
}

.submit_button {
  background: #02E864;
  border: none;
  color: #ffffff;
  margin-left: 1rem;
  padding: 0.5rem;
}

.submit_button:active {
  outline: none;
}

.membership_card_button:focus {
  outline: none;
}

.membership_card_button:hover {
  color: #ffffff !important;
}

.current_button {
  background: #F2F3FF;
  width: 100%;
  margin-top: 2rem;
  text-align: center;
  margin-top: 0.35rem;
  color: #5E60CE !important;
  padding: 0.8rem 0;
  border-radius: 8px;
}

.current_button:hover {
  color: #5E60CE !important;
}

.font-lg {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #292929;
}

.blue-text {
  color: #48BFE3;
}

.text {
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  font-weight: normal;
}

.generate_button {
  display: flex;
  justify-content: flex-end;
  border: none;
  margin-top: 1rem;
  background: #02E864;
  border-radius: 8px;
  padding: 0.5rem 2rem;
  color: #fff;
}

.membership_card_button {
  border: none;
  background: #02E864;
  font-weight: bold;
  border-radius: 8px;
  padding: 0.8rem 0;
  width: 100%;
  text-align: center;
  color: #ffffff !important;
}

.membership_card_button:focus {
  outline: none;
}
.membership_card_button:hover {
  color: #ffffff !important;
}

/* Responsive Design */
@media (max-width: 1050.98px) {
  .membership_container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 2rem;
  }

  .membership_card {
    height: auto;
    width: 100%;
  }

  .el-dialog {
    width: 83% !important;
  }

  .current_button {
    text-align: center;
    position: relative;
    bottom: 0;
    left: 0;
    padding: 0.8rem 2rem;
  }

  .membership-button__container {
    position: relative;
    bottom: 0;
    width: 100%;
  }

  .membership_card_button {
    text-align: center;
    color: white !important;
    background: #02E864;
    padding: 0.5rem 0;
    position: relative;
  }

  .spacing_container {
    padding: 0 2rem;
  }
}

@media (max-width: 1280px) {
  .membership_card {
    padding: 1.5rem 1rem;
  }

  .membership-button__container {
    width: 87.3%;
  }
}

</style>