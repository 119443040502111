<template>
  <div>
    <div class="">
      <div class="wrapper">
      <div class="flex flex-col linear-container px-6 md:px-14 py-5 md:py-12">
        <div class="flex josefin_font">
          <img src="@/assets/white-back.svg" @click="goBack" style="cursor: pointer;" />
          <h3 class="header-text">Telehealth Education</h3>
        </div>
        <p class="py-3 m-0 text-white font-light sub_header w-auto">
          Join us for biweekly training webinars to expand your telehealth skills.
        </p>
      </div>
    </div>
      <div class="comp_container flex flex-col md:flex-row">
        <div class="w-full md:w-3/4 items-center">
          <div class="mt-5">
            <button @click="$router.push({ name: 'webinars'})" class="mt-2 josefin_font green_button">View Our Upcoming Webinars</button>
          </div>
          <section class="improve_efficiency">

            <h5 class="josefin_font sub-heading list-width pb-0px">
              Learn, share, and engage in the latest telehealth best-practices.
            </h5>

            <p class="small_text list-width gray-text mt-16-pb-0">
              Join our biweekly telehealth webinar series to learn more about telehealth, technology innovation, and
              industry trends.
            </p>

            <p class="small_text list-width gray-text mt-16-pb-0">
              Looking to explore telehealth or see what’s new? Our “ViTel Signs” webinar series is designed to keep our
              members up-to-date on the exciting transformations of healthcare delivery.
            </p>

            <p class="small_text list-width gray-text">

            </p>

          </section>
          </div>
          <div class="w-full md:w-2/4 flex justify-center">
            <img src="@/assets/tele-training.svg" alt="" class="mobile-image">
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style>
.wrapper {
  background: linear-gradient(90deg, #5e60ce 66.28%, #48bfe3 100%);
}
.pb-0px {
  padding-bottom: 0px;
}
.mt-16-pb-0 {
  margin-top: 16px;
  padding-bottom: 0px;
}
.linear-container {
  padding: 3rem 20px;
  max-width: 1440px;
  margin: auto;
  background: initial;
}
.comp_container {
  max-width: 1440px;
  margin: auto;
  padding: 0px 20px;
}
.comp_container .sub_header {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666666;
}
.comp_container .header-text {
  color: #292929;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding-left: 1rem;
  margin: 0;
}
.improve_efficiency {
  margin-top: 2rem;
}
.comp_container p {
  /* font-size: 0.875rem; */
  line-height: 1.55rem;
  color: #666666;
}
/* Responsive Design */
@media (max-width: 575.98px) {
  .comp_container {
    padding: 1rem;
  }
  .mobile-image {
    width: 95%;
  }
}
</style>