<template>
  <div>
    <v-progress-circular indeterminate v-if="loading" />
    <div v-if="!loading"><chart :data="data" :options="options" /></div>
  </div>
</template>
<script>
import Chart from '@/components/Chart.vue'
import Vue from "vue";
import moment from "moment-timezone";

export default {
  name: 'task-duration-kpi-chart-small',
  components: {Chart},
  props: {
    patient: {}
  },
  data() {
    return {
      patientDetails: null,
      loading: true,
    };
  },
  async mounted() {
    await this.getPatientDetails();
  },
  methods: {
    async getPatientDetails() {
      this.loading = true;

      return Vue.$http
          .get("/api/rpm_ccm_dashboard/patient_details", {
            params: {
              patient_id: this.patient.id,
              token: await this.$auth.getTokenSilently(),
            },
          })
          .then((res) => (this.patientDetails = res.data.data))
          .catch((error) => this.handleEhrApiErrors(error, this.errors))
          .finally(() => {
            this.loading = false;
          });
    },
  },
  computed: {
    authUser () {
      return this.$store.state.patients.authUser;
    },
    data() {
      const month = moment().startOf('month');
      const labels = [];
      const monthName = month.format('MMM');
      const endOfMonth = month.clone().endOf('month').format('D');
      for (let day = 1; day <= endOfMonth; day++) {
        labels.push(`${monthName} ${day}`);
      }
      const lines = [];
      if (this.patientDetails && this.patientDetails.cache.task_duration_rpm_sum_monthly) {

        const rpmDatesObj = {};
        this.patientDetails.tasks.filter(t => t.category === 'rpm')
            .filter(t => t.status === 'closed')
            .filter(t => month.isSameOrBefore(t.created_at))
            .reduce(function (acc, task) {
              rpmDatesObj[moment(task.created_at).format('MMM D')] = acc + task.duration;
              return acc + task.duration;
            }, 0);

        const rpmLine = [];
        for (const p in rpmDatesObj) {
          rpmLine.push({x: p, y: rpmDatesObj[p]});
        }
        rpmLine.push({x: moment().format('MMM D'), y: rpmLine[rpmLine.length - 1]});

        lines.push(
            {
              type: 'line',
              lineTension: 0,
              backgroundColor: '#eeeeee',
              borderColor: '#eeeeee',
              data: [
                ...rpmLine
              ],
              label: 'rpm',
              fill: false,
            },
        );
      }
      if (this.patientDetails && this.patientDetails.cache.task_duration_ccm_sum_monthly) {
        const ccmDatesObj = {};
        this.patientDetails.tasks.filter(t => t.category === 'ccm')
            .filter(t => t.status === 'closed')
            .filter(t => month.isSameOrBefore(t.created_at))
            .reduce(function (acc, task) {
              ccmDatesObj[moment(task.created_at).format('MMM D')] = acc + task.duration;
              return acc + task.duration;
            }, 0);

        let ccmLine = [];
        for (const p in ccmDatesObj) {
          ccmLine.push({x: p, y: ccmDatesObj[p]});
        }
        ccmLine.push({x: moment().format('MMM D'), y: ccmLine[ccmLine.length - 1]});

        lines.push(
            {
              type: 'line',
              lineTension: 0,
              backgroundColor: '#eeeeee',
              borderColor: '#eeeeee',
              data: [
                  ...ccmLine
              ],
              label: 'ccm',
              fill: false,
            },
        );
      }

      // const days = this.patientDetails.map(function () {});

      return {
        datasets: [
          {
            pointRadius: 0,
            borderWidth: 0,
            lineTension: 0,
            backgroundColor: 'rgba(93, 98, 206, .7)',
            data: [
              // {x:0, y:0},
              {x: month.clone().add(this.authUser.config.start_red_offset, 'days').format('MMM D'), y:0},
              {x: month.clone().endOf('month').subtract(this.authUser.config.end_red_offset, 'days').format('MMM D'), y: this.authUser.config.task_dur_goal},
              {x: month.clone().endOf('month').format('MMM D'), y: this.authUser.config.task_dur_goal},
            ],
            fill: 'origin',
          },
          {
            pointRadius: 0,
            borderWidth: 0,
            lineTension: 0,
            backgroundColor: 'rgba(71, 191, 224, .7)',
            data: [
              // {x:0, y:0},
              {x: month.clone().add(this.authUser.config.start_yellow_offset, 'days').format('MMM D'), y:0},
              {x: month.clone().endOf('month').subtract(this.authUser.config.end_yellow_offset, 'days').format('MMM D'), y: this.authUser.config.task_dur_goal},
              // {x: month.clone().endOf('month').format('MMM D'), y: this.authUser.config.task_dur_goal},
            ],
            fill: 0,
          },
          {
            pointRadius: 0,
            borderWidth: 0,
            lineTension: 0,
            backgroundColor: 'rgba(5, 230, 103, .7)',
            data: [
              {x:0, y:0},
              {x: month.clone().add(this.authUser.config.start_yellow_offset, 'days').format('MMM D'), y:0},
              {x: month.clone().endOf('month').subtract(this.authUser.config.end_yellow_offset, 'days').format('MMM D'), y: this.authUser.config.task_dur_goal},
              {x: month.clone().endOf('month').format('MMM D'), y: this.authUser.config.task_dur_goal},
            ],
            fill: 'end',
          },
          ...lines,
        ],
        labels: [
            ...labels
        ],
      };
    },

    options() {
      // var options = vitalsHelper.getChartjsOptions(this.unit);
      // const vitalReadings = this.vitalReadings;
      // const attribute = this.attribute;
      return {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                display: true,
                beginAtZero: false,
              },
              offset: true,
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: "grey",
                beginAtZero: false,
                // callback: function (value) {
                //   return value + ' ' + unit;
                // },
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        // annotation: this.getAnnotation(),
        // tooltips: {
        //   callbacks: {
        //     label: function (tooltipItem, data) {
        //       if (attribute === 'blood_pressure') {
        //         // Vital readings are in same order as tooltip items
        //         const bp = vitalReadings[tooltipItem.index].value;
        //         return `${tooltipItem.value} (${bp})`;
        //       } else {
        //         return `${tooltipItem.value}`;
        //       }
        //     },
        //   }
        // }

        // plugins: {
        //   filler: {
        //     propagate: true
        //   }
        // },
      };
    },
  },
}
</script>
<style>
.v-data-table th {
  white-space: nowrap;
}
</style>