<template>
  <div>
    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
      <svg fill="rgb(107, 114, 128)" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
        <path
            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
      </svg>
    </div>
    <input id="table-search" type="text" placeholder="Search..." v-bind:search="search" v-on:input="$emit('input', $event.target.value)"
         class="w-full p-3 pl-10 text-sm text-gray-700 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"/>
  </div>
</template>
<script>
export default {
  name: 'RpmSearch',
  props: ['search'],
}
</script>