Calculator.vue<template>
  <div>
    <div class="wrapper">
    <div class="d-flex flex-column linear-container">
      <div class="flex josefin_font">
        <img src="@/assets/white-back.svg" @click="goBack" style="cursor: pointer;" />
        <h3 class="header-text">RPM / CCM Calculator</h3>
      </div>
      <p class="py-3 m-0 text-white font-weight-light w8-12 sub_header">
       Discover your revenue potential with using VItel Health
      </p>
    </div>
  </div>
     <div class="row spacing_container mt-20 comp_container" id="calculator">

    <div class="col-lg-6 col-sm-12 col-md-12 pr-5">
      <div class="mw-85">
      <div class="pb-5">
        <h6 class="josefin_font big-gray-text pt-4">Determine Eligible Patients</h6>
        <p class="gray-text lato">The CMS states that a patient must be a Medicare beneficiary to be eligible. 70% of Medicare recipients have at least one chronic condition. Both RPM and CCM medical services are billable per patient.
        </p>
      </div>

      <div class="d-flex justify-content-between align-items-center pb-4 container__between lato flex-containter">
        <p class="gray-text pt-3">Total number of physicians in practice</p>
        <input min="0" oninput="this.value = Math.abs(this.value)" type="number" class="calculator-input" v-model="noOfPhysiciansInPractice" />
      </div>

      <div class="d-flex justify-content-between align-items-center pb-4 container__between lato flex-containter">
        <p class="gray-text pt-3">Patients per physician (U.S. Average 1,500)</p>
        <input min="0" oninput="this.value = Math.abs(this.value)" type="number" class="calculator-input" v-model="patientsPerPhysician" />
      </div>

      <div class="d-flex justify-content-between align-items-center pb-4 container__between lato flex-containter">
        <p class="gray-text pt-2">Total number of patients</p>
        <h6 class="p-16">{{ noOfPatients }}</h6>
      </div>

      <div class="d-flex justify-content-between align-items-center pb-4 container__between lato flex-containter">
        <p class="gray-text pt-2">% eligible (proxy is 70% >= 1 chronic condition)</p>
        <input min="0" oninput="this.value = Math.abs(this.value)" type="number" class="calculator-input" v-model="proxyCondition" />
      </div>

      <div class="d-flex justify-content-between align-items-center pb-4 container__between lato flex-containter">
        <p class="gray-text pt-2">Total potential RPM patients</p>
        <h6 class="p-16">{{ Math.round(potentialRPMPatients) }}</h6>
      </div>

      <div class="d-flex justify-content-between align-items-center pb-4 container__between lato flex-containter">
        <p class="gray-text pt-3">% covered by Medicare (average 22%)</p>
        <input min="0" oninput="this.value = Math.abs(this.value)" type="number" class="calculator-input" v-model="medicare" />
      </div>

      <div class="d-flex justify-content-between align-items-center pb-4 container__between lato flex-containter">
        <p class="gray-text pt-3">Total Medicare patients</p>
        <h6 class="p-16">{{ Math.round(totalMedicarePatients) }}</h6>
      </div>

      <div class="d-flex justify-content-between align-items-center pb-4 container__between lato flex-containter">
        <p class="gray-text pt-3">% covered by Other Payers (average 33%)</p>
        <input min="0" oninput="this.value = Math.abs(this.value)" type="number" class="calculator-input" v-model="otherPayers" />
      </div>

      <div class="d-flex justify-content-between align-items-center pb-4 container__between lato flex-containter">
        <p class="gray-text pt-3">Total Other Payer patients</p>
        <h6 class="p-16">{{ totalOtherPayersPatient }}</h6>
      </div>

      <div class="d-flex justify-content-between align-items-center pb-4 container__between lato flex-containter">
        <p class="gray-text pt-3">Number of covered RPM patients</p>
        <h6 class="p-16">{{ coveredRPMPatients }}</h6>
      </div>

      <div class="d-flex justify-content-between align-items-center pb-4 container__between lato flex-containter">
        <p class="gray-text pt-3">% of patients participating</p>
        <h6 class="p-16">{{ RMPParticipating }}</h6>
      </div>

      <div class="d-flex justify-content-between align-items-center pb-4 container__between lato flex-containter">
        <p class="gray-text pt-3">Number of covered CCM patients</p>
        <h6 class="p-16">{{ coveredCCMPatients }}</h6>
      </div>

      <div class="d-flex justify-content-between align-items-center pb-4 container__between lato flex-containter">
        <p class="gray-text pt-3">% of patients participating</p>
        <h6 class="p-16">{{ CCMParticipating }}</h6>
      </div>

      <div class="d-flex justify-content-between align-items-center pb-4 container__between lato flex-containter">
        <p class="gray-text pt-3">Average Number of Patients</p>
        <h6 class="p-16">{{ averageNumberOfPatients }}</h6>
      </div>
    </div>
    </div>

    <div class="col-lg-6 col-sm-12 col-md-12 bg-purple">
      <h3 class="text-white josefin_font">Your potential revenue</h3>

      <div class="py-24 lato">

        <el-tabs type="card" @tab-click="handleClick">
          <el-tab-pane label="Annually">
            <div class="text-white pb-4">

              <h5 class="txt-white">ViTel Provides Full Service Program</h5>
              <p class="mt-1 txt-white">Including nursing staff and medical management, equipment, RPM - CCM platform, training</p>

              <div class="d-flex flex-column pb-3">
                <p class="m-0 pb-2 mt-3 txt-white">One Time Setup Fee Revenue Potential</p>
                <span class="money-text txt-white">${{ currencyFormatter( oneTimeSetupFeeRevenuePotential / 2 )}}</span>
              </div>

              <div class="d-flex flex-column pb-3">
                <p class="m-0 pb-2 txt-white">Annual Revenue to Medical Practice RPM and CCM</p>
                <span class="money-text txt-white">${{ currencyFormatter( annualRPMCCMRevenuePotential ) }}</span>
              </div>

              <div class="d-flex flex-column pb-3">
                <p class="m-0 pb-2 txt-white">Annual Revenue to Medical Practice RPM</p>
                <span class="money-text txt-white">${{ currencyFormatter( annualRPMRevenuePotential ) }}</span>
              </div>

              <div class="d-flex flex-column pb-3">
                <p class="m-0 pb-2 txt-white">Annual Revenue to Medical Practice CCM</p>
                <span class="money-text txt-white">${{ currencyFormatter( Math.round(annualCCMRevenuePotential ) ) }}</span>
              </div>

            </div>
          
          </el-tab-pane>

          <el-tab-pane label="Monthly">
            <div class="text-white pb-4">

              <h5 class="txt-white">ViTel Provides Full Service Program</h5>
              <p class="mt-1 txt-white">Including nursing staff and medical management, equipment, RPM - CCM platform, training</p>

              <div class="d-flex flex-column pb-3">
                <p class="m-0 pb-2 mt-3 txt-white">Monthly Revenue Per Patient RPM and CCM</p>
                <span class="money-text txt-white">${{ currencyFormatter( monthlyRPMCCMRevenuePotential )  }}</span>
              </div>

              <div class="d-flex flex-column pb-3">
                <p class="m-0 pb-2 txt-white">Monthly Revenue Per Patient RPM</p>
                <span class="money-text txt-white">${{ currencyFormatter( monthlyRevenuePerPatientRPM ) }}</span>
              </div>

              <div class="d-flex flex-column pb-3">
                <p class="m-0 pb-2 txt-white">Monthly Revenue Per Patient CCM</p>
                <span class="money-text txt-white">${{ currencyFormatter( monthlyRevenuePerPatientCCM ) }}</span>
              </div>

            </div>
        
          </el-tab-pane>

        </el-tabs>
      </div>

    </div>
  </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      noOfPhysiciansInPractice: 1,
      patientsPerPhysician: 1500,
      proxyCondition: 70,
      medicare: 22,
      otherPayers: 33,
      twentyMinuteBillingAverage: 38,
      twentyMinuteRate: 51.63,
      fortyMinuteBillingAverage: 42,
      fortyMinuteRate: 93.86,
      sixtyMinuteBillingAverage: 20,
      sixtyMinuteRate: 136.09,
      monthlyReimbursement: 56.64,
      ccm80: 80,
      CPT99490: 39.13,
      CPT99487: 85.62,
      HCPCS_GO506: 59.57,
    }
  },
  computed: {
    noOfPatients() {
      return this.noOfPhysiciansInPractice * this.patientsPerPhysician
    },

    noOfEnrolledCCMPatients() {
      return Math.round((this.ccm80 / 100) * this.coveredRPMPatients)
    },

    total20minutes() {
      let number = this.twentyMinuteRate
      let percentToGet =  this.twentyMinuteBillingAverage
      return (percentToGet / 100) * number
    },

    total40minutes() {
      let number = this.fortyMinuteRate
      let percentToGet =  this.fortyMinuteBillingAverage
      return (percentToGet / 100) * number
    },

    total60minutes() {
      let number = this.sixtyMinuteRate
      let percentToGet =  this.sixtyMinuteBillingAverage
      return (percentToGet / 100) * number
    },

    sumAllMinutes() {
      return this.total20minutes + this.total40minutes + this.total60minutes
    },

    totalMonthlyDisbursement() {
      return this.sumAllMinutes + this.monthlyReimbursement
    },

    potentialRPMPatients() {
      let number = this.noOfPatients
      let percentToGet =  this.proxyCondition
      return (percentToGet / 100) * number
    },

    totalMedicarePatients() {
      let number = this.potentialRPMPatients
      let percentToGet =  this.medicare
      return (percentToGet / 100) * number
    },

    totalOtherPayersPatient() {
      let number = this.potentialRPMPatients
      let percentToGet =  this.otherPayers
      return Math.round((percentToGet / 100) * number)
    },

    coveredRPMPatients() {
      return Math.round(this.totalOtherPayersPatient + this.totalMedicarePatients)
    },

    RMPParticipating() {
      return ((this.coveredRPMPatients / this.noOfPatients) * 100).toFixed(1)
    },

    coveredCCMPatients() {
      let number = this.coveredRPMPatients
      let percentToGet =  this.ccm80
      return Math.round((percentToGet / 100) * number)
    },

    CCMParticipating() {
      return ((this.coveredCCMPatients / this.noOfPatients) * 100).toFixed(1)
    },

    averageNumberOfPatients() {
      let patientAverage = arr => arr.reduce((a,b) => a + b, 0) / arr.length
      return patientAverage([this.coveredCCMPatients, this.coveredRPMPatients])
    },

    oneTimeSetupFeeRevenuePotential() {
      return this.estimatedRPMRevenue + this.estimatedCCMRevenue
    },

    estimatedRPMRevenue() {
      return Math.round((this.coveredRPMPatients * 16.9 * 12) * 0.15)
    },

    estimatedCCMRevenue() {
      return Math.round((this.noOfEnrolledCCMPatients * this.HCPCS_GO506 * 12) * 0.15)
    },

    monthlyCCMRevenuePotential() {
      return (this.coveredCCMPatients * (this.CPT99490 + this.CPT99487)) * 0.15
    },

    annualCCMRevenuePotential() {
      return this.monthlyCCMRevenuePotential * 12
    },

    monthlyRevenuePerPatientCCM() {
      return (Math.round(this.annualCCMRevenuePotential) / this.coveredCCMPatients) / 12
    },

    monthlyRPMRevenuePotential() {
      let _c36 = this.sumAllMinutes + this.monthlyReimbursement // 142.90
      return (this.coveredRPMPatients * _c36) * 0.15
    },

    annualRPMRevenuePotential() {
      return this.monthlyRPMRevenuePotential * 12
    },

    monthlyRevenuePerPatientRPM() {
      return Math.round(this.annualRPMRevenuePotential / this.coveredRPMPatients) / 12
    },

    monthlyRPMCCMRevenuePotential() {
      return (this.annualRPMCCMRevenuePotential / this.averageNumberOfPatients) / 12
    },

    annualRPMCCMRevenuePotential() {
      return this.annualRPMRevenuePotential + this.annualCCMRevenuePotential
    },

  },
  methods: {
     goBack() {
      this.$router.go(-1)
    },
    handleClick(tab, event) {

    },
   currencyFormatter(number) {
    return number ? number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0
    }
  },
}
</script>

<style scoped>
.mw-85 {
  max-width: 85%;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 18px;
  margin-bottom: 30px;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid white;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

.el-tab-pane p {
  color: white;
}
.wrapper {
  background: linear-gradient(90deg, #5e60ce 66.28%, #48bfe3 100%);
}
.txt-white {
  color:white;
}

.linear-container {
  padding: 3rem 20px;
  max-width: 1440px;
  margin: auto;
  background: initial;
}

.container__between {
  display:flex;
  justify-content: space-between;
}

.comp_container {
  max-width: 1440px;
  margin: auto;
  padding: 0px 20px;
}
.spacing_container {
  padding: 2rem 20px;
  display: flex;
}

.line-container {
  position: relative;
  margin: 1rem 0;
  border-bottom: 1px solid #ffffff
}

.line-text {
  position: absolute;
  top: -14px;
  left: 50%
}

.line-text__container {
  position: relative;
  font-size: 1rem;
  background: #5E60CE;
  padding: 0 0.3rem;
}

.p-16 {
  padding: 0 1rem;
}

.bg-purple {
  background: #5E60CE;
  border-radius: 0.5rem;
  padding: 2.5rem;
}

.calculator-input {
  width: 129px;
  height: 48px;
  background: #EEEEEE;
  border-radius: 8px;
  border: 0px;
  text-align: right;
  padding: 0 16px;
  color: #292929;
}

.py-24 {
  padding: 24px 0;
}

.calculator-input:focus {
  border: 0;
  outline: none;
}

* >>> .el-tabs--card>.el-tabs__header .el-tabs__nav {
  border: 0px;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
}

* >>> .el-tabs--card>.el-tabs__header .el-tabs__item {
  border-left: 0px;
}

* >>> .el-tabs--card>.el-tabs__header {
  border-bottom: 0px;
  font-family: 'Lato', sans-serif;
  background: #595BB8;
  border-radius: 5px;
}

* >>> .el-tabs--card>.el-tabs__header .el-tabs__item {
  text-align: center;
  width: 50% !important;
  border-radius: 5px;
}

* >>> .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: transparent !important;
  background: #ffffff;
}

* >>> .el-tabs__item.is-active {
  color: #292929;
}

* >>> .el-tabs__item {
  color: #ffffff;
}

* >>> .el-tabs--card>.el-tabs__header .el-tabs__nav {
  padding: 0.6rem;
  width: 100%;
}

.white-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.money-text {
  font-size: 22px;
  line-height: 26px;
  font-weight: 800;
}

@media (max-width: 767.98px) {
  .flex-containter {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }

  #calculator.row {
    display: block;
    margin-top: 20px;
  }

  #calculator.row .mw-85 {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .calculator-input {
    width: 100%;
    text-align: left;
  }

  .spacing_container {
    padding: 0 20px;
    display: flex;
  }

  .p-16 {
    padding: 0rem;
  }
}

@media (max-width: 1280px) {
  .el-tabs--card>.el-tabs__header .el-tabs__item {
    width: 50% !important;
  }
}

@media (max-width: 990px) {
  .el-tabs--card>.el-tabs__header .el-tabs__item {
   width: 50% !important;
  }
}

</style>