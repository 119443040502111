<template>
  <div>
    <div @click="goBack" class="pt-16 pb-14 pl-6 md:pl-40 flex items-center job_board_container">
      <span class="cursor-pointer">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 8H1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8 15L1 8L8 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>
      <p class="pl-4 font-bold text-2xl md:text-3xl text-white josefin_font">Discover Available Openings</p>
    </div>
    <section>
      <div class="py-10">
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick" 
        >
          <el-tab-pane label="Available jobs" name="first">
            <available-jobs></available-jobs>
          </el-tab-pane>
          <el-tab-pane label="Applied (2)" name="second">
            <applied-jobs></applied-jobs>
          </el-tab-pane>
        </el-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import AvailableJobs from '../components/AvailableJobs.vue'
import AppliedJobs from '../components/AppliedJobs.vue'

export default {
  components: {
    AvailableJobs,
    AppliedJobs
  },
  data() {
    return {
      activeName: 'first'
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    handleClick(tab, event) {

    }
  }

}
</script>

<style>
.job_board_container {
  background: linear-gradient(90deg, #5E60CE 66.28%, #48BFE3 100%);
}

</style>