<template>
  <div>
    <slot></slot>
    <span @click="() => this.editAppointmentDateModal = true">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 inline-block cursor-pointer">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
      </svg>
    </span>
    <el-dialog :append-to-body="true" :close-on-click-modal="false" title="Edit Appointment Date" :visible.sync="editAppointmentDateModal" width="50%" @close="cancel">
      <div ref="editAppointmentDateModalBody" class="dialog__body">
        <RpmDashboardErrors v-bind:errors="updateAppointmentDateErrors" v-bind:title="'Edit Appointment Date Errors'"/>
        <div class="grid gap-4">
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="appointment_date"> Appointment Date </label>
            <input v-model="appointmentDate" type="date" class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="appointment_date"/>
          </div>
        </div>
      </div>
      <div slot="footer" class="josefin dialog-footer">
        <el-button @click="updateAppointmentDate" type="primary" :disabled="loading">Save</el-button>
        <el-button @click="cancel" type="cancel">Cancel</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import RpmDashboardErrors from "@/components/RpmDashboardErrors.vue"
import Vue from "vue";

export default {
  name: 'edit-appointment-date',
  components: {RpmDashboardErrors},
  props: {
    patientId: Number,
    date: null,
  },
  computed: {
  },
  created() {
  },
  data() {
    return {
      loading: false,
      appointmentDate: this.date,
      editAppointmentDateModal: false,
      updateAppointmentDateErrors: [],
    };
  },
  methods: {
    cancel() {
      this.appointmentDate = this.date;
      this.loading = false;
      this.editAppointmentDateModal = false;
    },
    async updateAppointmentDate(date) {
      if (this.loading) return;
      this.updateAppointmentDateErrors = [];
      this.loading = true;

      Vue.$http.post(`/api/rpm_ccm_dashboard/update-nad`, {}, { params: { patient_id: this.patientId, date: this.appointmentDate, token: await this.$auth.getTokenSilently() } })
          .then(async () => {
            this.$awn.success("Appointment Date Saved");
            await this.$emit('get-patient-details');
            this.loading = false;
            this.editAppointmentDateModal = false;
          })
          .catch((error) => this.handleEhrApiErrors(error, this.updateAppointmentDateErrors, this.$refs.editAppointmentDateModalBody)).finally(() => {
        this.loading = false;
      });
    },
  },
}
</script>