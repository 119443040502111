<template>
  <div id="app" :class="{'h-screen': hScreen}">
    <v-app>
      <nav-bar />

      <div>
        <error />

        <div>
          <router-view />
        </div>

      </div>
    </v-app>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import Error from "./components/Error";

export default {
  components: {
    NavBar,
    Error
  },

  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
    hScreen() {
      return this.$route.name === 'sms-min';
    },
  },

};
</script>

<style>
#app {
  font-family: 'Lato', sans-serif;
}

</style>
