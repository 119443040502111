import Vue from 'vue'

export default {
  state: {
    loader: false,
  },
  mutations: {
    mutate(state, payload) {
      state[payload.property] = payload.with
    }
  },
  actions: {
    fetchProviderAvailability({ commit }, data) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get(`/api/provider/availability`, {
          params: {
            user_id: data.user_id
          }
        })
          .then((res) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            resolve(res);
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err)
          })
      })
    },
    submitProviderAvailability({ commit }, data) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.post(`/api/provider/availability`, data)
          .then((res) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            resolve(res)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err)
          })
      })
    }
  }
}