<script>
import moment from "moment-timezone";
import Vue from "vue";
import MessageList from "@/views/MessageList.vue";
import Template from "@/mixins/Template";

export default {
  components: {MessageList},
  mixins: [Template],
  created: async function () {
    // await this.$store.dispatch('getAuthUser');
    // await this.$store.dispatch('getPatients');
    this.selectedUser = this.authUser;
    this.loading = false;
  },
  props: ["patient", "templates"],
  data() {
    return {
      // sms: '',
      loading: true,
      selectedUser: null,
      message: '',
    };
  },
  computed: {
    authUser() {
      return this.$store.state.patients.authUser
    },
    messages() {
      return this.patient.sms;
    },
    selectedMessages() {
      if (!this.selectedUser) return this.messages;
      return this.messages.filter(m => m.sender_id === this.selectedUser.id || m.recipient_id === this.selectedUser.id);
    },
    recentSenders() {
      return this.messages.map(m => ({id: (m.sender_id === this.patient.id ? m.recipient_id : m.sender_id), created_at: m.created_at}))
          .filter((v, i, a) => a.findIndex(val => val.id === v.id) === i)//unique
          .sort((a,b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
          .map(m => this.$store.state.patients.providers.find(p => p.id === m.id))
          .filter(u => u !== undefined);
    },
  },
  methods: {
    async refresh() {
      this.$emit('get-patient-details');
    },
    selectUser(user) {
      this.selectedUser = user;
    },
    imageSrc(id) {
      return this.userField(id, 'profilePicSmall');
    },
    userField(id, field) {
      if (id === this.authUser.id) return this.authUser[field];
      const user = this.$store.state.patients.patients.find(p => p.id === id) || this.$store.state.patients.providers.find(p => p.id === id);
      if (user) return user[field];
    },
    async send() {
      if (!this.message || this.loading) {
        return false;
      }
      this.loading = true;

      Vue.$http.post(`/api/rpm_ccm_dashboard/send-sms`, null, { params: { token: await this.$auth.getTokenSilently(), patient_id: this.patient.id, message: this.message } })
          .then(async () => {
            this.message = '';
            this.selectedTemplate = null;
            this.notes = null;
            this.$emit('get-patient-details');
            // this.$awn.success(res.message);

          }).catch((error) => {
            this.$awn.alert(error.response.data.message);
          })
          // .catch((error) => this.$awn.warning(error))
          .finally(() => {
            this.loading = false;
          });
      return false;
    }
  },
  watch: {
    notes: function(notes) {
      this.message = notes;
    },
  }
}
</script>

<template>
  <div class="grid grid-cols-12 gap-1 h-[calc(100vh-20rem)]">
    <div class="col-span-3">
      <ul role="list" class="divide-y divide-gray-100">
        <li @click="selectUser(authUser)" class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 lg:px-8 cursor-pointer">
          <div class="flex min-w-0 gap-x-4">
            <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="imageSrc(authUser.id)" alt="" />
            <div class="min-w-0 flex-auto">
              <p class="text-sm font-semibold leading-6 text-gray-900">
                <!--                <a :href="person.href">-->
                <span class="absolute inset-x-0 -top-px bottom-0" />
                {{ authUser.first_name }} {{authUser.last_name}}
                <!--                </a>-->
              </p>
              <p class="mt-1 flex text-xs leading-5 text-gray-500">
<!--                <a :href="`mailto:${authUser.email}`" class="relative truncate hover:underline">-->
                  <span class="relative truncate">{{ authUser.email }}</span>
<!--                </a>-->
              </p>
            </div>
          </div>
        </li>
        <li @click="selectUser(user)" v-for="user in recentSenders.filter(s => s.id !== authUser.id)" v-bind:key="user.id" class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 lg:px-8 cursor-pointer">
          <div class="flex min-w-0 gap-x-4">
            <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="imageSrc(user.id)" alt="" />
            <div class="min-w-0 flex-auto">
              <p class="text-sm font-semibold leading-6 text-gray-900">
<!--                <a :href="person.href">-->
                  <span class="absolute inset-x-0 -top-px bottom-0" />
                  {{ user.first_name }} {{user.last_name}}
<!--                </a>-->
              </p>
              <p class="mt-1 flex text-xs leading-5 text-gray-500">
<!--                <a :href="`mailto:${user.email}`" class="relative truncate hover:underline">-->
                  <span class="relative truncate">{{ authUser.email }}</span>
<!--                </a>-->
              </p>
            </div>
          </div>
<!--          <div class="flex shrink-0 items-center gap-x-4">-->
<!--            <div class="hidden sm:flex sm:flex-col sm:items-end">-->
<!--              <p class="text-sm leading-6 text-gray-900">{{ person.role }}</p>-->
<!--              <p v-if="person.lastSeen" class="mt-1 text-xs leading-5 text-gray-500">-->
<!--                Last seen <time :datetime="person.lastSeenDateTime">{{ person.lastSeen }}</time>-->
<!--              </p>-->
<!--              <div v-else class="mt-1 flex items-center gap-x-1.5">-->
<!--                <div class="flex-none rounded-full bg-emerald-500/20 p-1">-->
<!--                  <div class="h-1.5 w-1.5 rounded-full bg-emerald-500" />-->
<!--                </div>-->
<!--                <p class="text-xs leading-5 text-gray-500">Online</p>-->
<!--              </div>-->
<!--            </div>-->
<!--&lt;!&ndash;            <ChevronRightIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />&ndash;&gt;-->
<!--            <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--              <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />-->
<!--            </svg>-->
<!--          </div>-->
        </li>
      </ul>
    </div>
    <div class="col-start-4 col-span-9 h-full overflow-x-scroll">
      <div class="relative flow-root">
        <MessageList :patient="patient" :messages="selectedMessages" />
      </div>
    </div>

    <div class="self-end col-start-4 col-span-9">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="rpm_note"> Select Template </label>
        <select id="rpm_note" v-on:change="templateChange" v-model="selectedTemplate"
                class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <option value=""></option>
          <option v-for="template in templates" v-bind:value="template" v-bind:key="template.id">
            {{ template.name }}
          </option>
        </select>
      </div>
      <div v-if="authUser.id === selectedUser.id" class="flex items-start space-x-4">
        <div class="flex-shrink-0">
          <img class="inline-block h-10 w-10 rounded-full" :src="authUser.profilePicSmall" alt="" />
        </div>
        <div class="min-w-0 flex-1">
          <form v-on:submit.prevent action="#" class="relative">
            <div class="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
              <label for="comment" class="sr-only">Add your comment</label>
              <textarea ref="messageRef" @keydown.tab.prevent="tabTemplate('messageRef')"  v-model="message" rows="3" name="comment" id="comment" class="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="Add your comment..." />

              <!-- Spacer element to match the height of the toolbar -->
              <div class="py-2" aria-hidden="true">
                <!-- Matches height of button in toolbar (1px border + 36px content height) -->
                <div class="py-px">
                  <div class="h-9" />
                </div>
              </div>
            </div>

            <div class="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
              <div class="flex items-center space-x-5">
<!--                <div class="flex items-center">-->
<!--                  <button type="button" class="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">-->
<!--                    &lt;!&ndash; paperclip &ndash;&gt;-->
<!--                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--                      <path fill-rule="evenodd" d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z" clip-rule="evenodd" />-->
<!--                    </svg>-->
<!--                    <span class="sr-only">Attach a file</span>-->
<!--                  </button>-->
<!--                </div>-->
                <div class="flex items-center">
  <!--                <Listbox as="div" v-model="selected">-->
  <!--                  <ListboxLabel class="sr-only">Your mood</ListboxLabel>-->
  <!--                  <div class="relative">-->
  <!--                    <ListboxButton class="relative -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">-->
  <!--                    <span class="flex items-center justify-center">-->
  <!--                      <span v-if="selected.value === null">-->
  <!--                        <FaceSmileIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />-->
  <!--                        <span class="sr-only">Add your mood</span>-->
  <!--                      </span>-->
  <!--                      <span v-if="!(selected.value === null)">-->
  <!--                        <span :class="[selected.bgColor, 'flex h-8 w-8 items-center justify-center rounded-full']">-->
  <!--                          <component :is="selected.icon" class="h-5 w-5 flex-shrink-0 text-white" aria-hidden="true" />-->
  <!--                        </span>-->
  <!--                        <span class="sr-only">{{ selected.name }}</span>-->
  <!--                      </span>-->
  <!--                    </span>-->
  <!--                    </ListboxButton>-->

  <!--                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">-->
  <!--                      <ListboxOptions class="absolute z-10 -ml-6 mt-1 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:w-64 sm:text-sm">-->
  <!--                        <ListboxOption as="template" v-for="mood in moods" :key="mood.value" :value="mood" v-slot="{ active }">-->
  <!--                          <li :class="[active ? 'bg-gray-100' : 'bg-white', 'relative cursor-default select-none px-3 py-2']">-->
  <!--                            <div class="flex items-center">-->
  <!--                              <div :class="[mood.bgColor, 'flex h-8 w-8 items-center justify-center rounded-full']">-->
  <!--                                <component :is="mood.icon" :class="[mood.iconColor, 'h-5 w-5 flex-shrink-0']" aria-hidden="true" />-->
  <!--                              </div>-->
  <!--                              <span class="ml-3 block truncate font-medium">{{ mood.name }}</span>-->
  <!--                            </div>-->
  <!--                          </li>-->
  <!--                        </ListboxOption>-->
  <!--                      </ListboxOptions>-->
  <!--                    </transition>-->
  <!--                  </div>-->
  <!--                </Listbox>-->
                </div>
              </div>
              <div class="flex-shrink-0">
                <button v-if="authUser.id === selectedUser.id" @click.stop="send" class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Send</button>
              </div>
              <div class="flex-shrink-0">
                <button @click.stop="refresh" class="ml-2 inline-flex items-center rounded-md bg-indigo-600 px-2 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>