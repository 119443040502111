<template>
  <rpm-layout>
    <div class="bg-white rounded-lg p-2">
      <div class="flex justify-between items-center">
        <v-select
            v-model="client"
            :items="availableClients"
            item-text="name"
            item-value="id"
            label="Client"
            id="client"
            dense
            outlined
            :filterable="true"
        ></v-select>
        <v-select
            v-model="category"
            :items="['rpm', 'ccm']"
            label="Category"
            dense
            outlined
        ></v-select>
        <v-select
            v-model="year"
            :items="['2020', '2021', '2022', '2023', '2024']"
            label="Year"
            id="year"
            dense
            outlined
            :filterable="true"
        ></v-select>
        <v-select
            v-model="month"
            :items="monthsArray"
            item-text="text"
            item-value="value"
            label="Month"
            id="month"
            dense
            outlined
            :filterable="true"
        ></v-select>
      </div>

      <div class="flex justify-between items-center p-2">
        <div>
          <v-text-field
              placeholder="Search"
              v-model="search"
              label="Search"
              dense
              outlined
          ></v-text-field>
        </div>
        <div>
          Total: ${{ total }}
        </div>
        <div>
          <a
              target="_blank"
              :download="`${clientName}-${year}-${month}-billing-report.csv`"
              :href="csvLink()"
              class="m-2 py-2.5 px-5 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
              v-if="tableDataFiltered.length"
          >
            Download Report CSV
          </a>
        </div>
      </div>

      <v-data-table
          :loading="loading"
          :items="tableDataFiltered"
          :headers="headers"
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="elevation-1"
          :fixed-header="true"
          height="600px"
      >

        <template v-slot:[patientNameSlot]="{ item }">
          <v-menu bottom open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-chip small v-bind="attrs" v-on="on">
                {{ item['First Name'] }}
              </v-chip>
            </template>
            <v-card>
              <v-card-text>
                <p>Name: {{ item['Patient Name'] }}</p>
                <v-btn text small @click="goToPatient(item.id)">Details</v-btn>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>

<!--        <template v-slot:[rpmCpt99458Slot]="{ item }">-->
<!--          <td class="freeze">{{ item['RPM CPT 99458'] }}</td>-->
<!--        </template>-->

<!--        <template v-slot:[rpmCpt99457Slot]="{ item }">-->
<!--          <td class="freeze">{{ item['RPM CPT 99457'] }}</td>-->
<!--        </template>-->

<!--        <template v-slot:[rpmCpt99454Slot]="{ item }">-->
<!--          <td class="freeze">{{ item['RPM CPT 99454'] }}</td>-->
<!--        </template>-->

<!--        <template v-slot:[rpmCpt99453Slot]="{ item }">-->
<!--          <td class="freeze">{{ item['RPM CPT 99453'] }}</td>-->
<!--        </template>-->

<!--        <template v-slot:[ccmCpt99490Slot]="{ item }">-->
<!--          <td class="freeze">{{ item['CCM CPT 99490'] }}</td>-->
<!--        </template>-->

<!--        <template v-slot:[ccmCpt99439Slot]="{ item }">-->
<!--          <td class="freeze">{{ item['CCM CPT 99439'] }}</td>-->
<!--        </template>-->

<!--        <template v-slot:[ccmCpt99437Slot]="{ item }">-->
<!--          <td class="freeze">{{ item['CCM CPT 99437'] }}</td>-->
<!--        </template>-->

<!--        <template v-slot:[ccmCpt99491Slot]="{ item }">-->
<!--          <td class="freeze">{{ item['CCM CPT 99491'] }}</td>-->
<!--        </template>-->

        <template v-for="col in dateColumns" v-slot:[`item.${col}`]="{ item }">
          {{ dateFormatter(item[col]) }}
        </template>
</v-data-table>
</div>
</rpm-layout>
</template>

<script>
import RpmLayout from '../../layouts/RpmLayout.vue';
import moment from 'moment-timezone';
import Vue from 'vue';
import PatientMixin from '@/mixins/Patient';

export default {
  mixins: [PatientMixin],
  components: {RpmLayout},
  data() {
    return {
      search: '',
      client: null,
      category: 'rpm',
      year: moment().format('YYYY'),
      month: Number(moment().format('M')),
      tableData: [],
      token: null,
      loading: true,
      sortBy: 'Patient Name',
      sortDesc: false,
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      },
    };
  },
  created: async function () {
    this.token = await this.$auth.getTokenSilently();
    Promise.all([
      !this.$store.state.patients.authUser.id
          ? this.$store.dispatch('getAuthUser')
          : null,
    ]).finally(() => {
      this.client = this.availableClients[0].id;
      this.loading = false;
    });
  },
  computed: {
    patientNameSlot() {
      return 'item.' + this.getPropertyName('patientName');
    },
    rpmCpt99458Slot() {
      return 'item.' + this.getPropertyName('rpmCpt99458');
    },
    rpmCpt99457Slot() {
      return 'item.' + this.getPropertyName('rpmCpt99457');
    },
    rpmCpt99454Slot() {
      return 'item.' + this.getPropertyName('rpmCpt99454');
    },
    rpmCpt99453Slot() {
      return 'item.' + this.getPropertyName('rpmCpt99453');
    },
    ccmCpt99490Slot() {
      return 'item.' + this.getPropertyName('ccmCpt99490');
    },
    ccmCpt99439Slot() {
      return 'item.' + this.getPropertyName('ccmCpt99439');
    },
    ccmCpt99437Slot() {
      return 'item.' + this.getPropertyName('ccmCpt99437');
    },
    ccmCpt99491Slot() {
      return 'item.' + this.getPropertyName('ccmCpt99491');
    },
    total() {
      return this.tableDataFiltered
          .reduce((a, r) => r['Sub Total'] + a, 0)
          .toFixed(2);
    },
    clientName() {
      if (!this.client) return '';
      return this.availableClients.find((c) => c.id == this.client).name;
    },
    tableDataFiltered() {
      if (!this.tableData || this.tableData.length === 0) return [];
      if (this.search === '') {
        return this.tableData;
      }
      return this.tableData.filter(
          (r) =>
              Object.values(r).filter((val) =>
                  String(val).toLowerCase().includes(this.search.toLowerCase())
              ).length
      );
    },
    availableClients() {
      return this.$store.state.patients.authUser.available_clients;
    },
    monthsArray() {
      return Object.entries(this.months).map(([value, text]) => ({
        value: Number(value),
        text,
      }));
    },
    headers() {
      const commonHeaders = [
        {text: 'Patient Name', value: 'Patient Name', sortable: true, width: '120px'},
        {text: 'First Name', value: 'First Name', sortable: true, width: '110px'},
        {text: 'Last Name', value: 'Last Name', sortable: true, width: '110px'},
        {text: 'Middle Initial', value: 'Middle Initial', sortable: true, width: '120px'},
        {text: 'Street 1', value: 'Street 1', sortable: true, width: '100px'},
        {text: 'Street 2', value: 'Street 2', sortable: true, width: '100px'},
        {text: 'City', value: 'City', sortable: true, width: '100px'},
        {text: 'State', value: 'State', sortable: true, width: '100px'},
        {text: 'Zip Code', value: 'Zip Code', sortable: true, width: '100px'},
        {text: 'Phone 1', value: 'Phone 1', sortable: true, width: '110px'},
        {text: 'Phone 3', value: 'Phone 3', sortable: true, width: '110px'},
        {text: 'Date of Birth', value: 'Date of Birth', sortable: true, width: '120px'},
        {text: 'Sex', value: 'Sex', sortable: true, width: '100px'},
        {text: 'Soc Sec Num', value: 'Soc Sec Num', sortable: true, width: '120px'},
        {text: 'Marital Status', value: 'Marital Status', sortable: true, width: '130px'},
        {text: 'Plan Name', value: 'Plan Name', sortable: true, width: '120px'},
        {text: 'Subscriber ID', value: 'Subscriber ID', sortable: true, width: '120px'},
        {text: 'Group No', value: 'Group No', sortable: true, width: '100px'},
        {text: 'Secondary Plan Name', value: 'Secondary Plan Name', sortable: true, width: '170px'},
        {text: 'Secondary Subscriber ID', value: 'Secondary Subscriber ID', sortable: true, width: '190px'},
        {text: 'Secondary Group No', value: 'Secondary Group No', sortable: true, width: '160px'},
        {text: 'Insured Authorization', value: 'Insured Authorization', sortable: true, width: '170px'},
        {text: 'Signature on File', value: 'Signature on File', sortable: true, width: '150px'},
        {text: 'SOF Date', value: 'SOF Date', sortable: true, width: '100px'},
        {text: 'Email', value: 'Email', sortable: true, width: '100px'},
        {text: 'Facility Name', value: 'Facility Name', sortable: true, width: '120px'},
        {text: 'Billing Month', value: 'Billing Month', sortable: true, width: '120px'},
        {text: 'Ordering Physician', value: 'Ordering Physician', sortable: true, width: '150px'},
        {text: 'Health Coach', value: 'Health Coach', sortable: true, width: '150px'},
        {text: 'Health Condition (DX)', value: 'Health Condition (DX)', sortable: true, width: '170px'},
        {text: 'ICD Codes', value: 'ICD Codes', sortable: true, width: '170px'},
        {text: 'Care Plan Start Date', value: 'Care Plan Start Date', sortable: true, width: '170px'},
        {text: 'Days in Billing Cycle', value: 'Days in Billing Cycle', sortable: true, width: '170px'},
        {text: 'Days From Start Date', value: 'Days From Start Date', sortable: true, width: '170px'},
        {text: 'Total Review Time', value: 'Total Review Time (minutes)', sortable: true, width: '150px'},
        {text: 'Locality Code', value: 'Locality Code', sortable: true, width: '120px'},
        {text: 'Sub Total', value: 'Sub Total', sortable: true, width: '120px'},
        {text: 'Billing Start', value: 'start', sortable: true, width: '110px'},
        {text: 'Billing End', value: 'end', sortable: true, width: '110px'},
      ];

      const ccmHeaders = [
        {text: 'Physician Time', value: 'Physician Time', sortable: true, width: '130px'},
        {text: 'Non-Physician Time', value: 'Non-Physician Time', sortable: true, width: '160px'},
        {text: '99437 value', value: 'CCM CPT 99437 value', sortable: true, width: '120px'},
        {text: '99439 value', value: 'CCM CPT 99439 value', sortable: true, width: '120px'},
        {text: '99490 value', value: 'CCM CPT 99490 value', sortable: true, width: '120px'},
        {text: '99491 value', value: 'CCM CPT 99491 value', sortable: true, width: '120px'},
        {text: '99491', value: 'CCM CPT 99491', sortable: true, width: '80px', align: 'end'},
        {text: '99490', value: 'CCM CPT 99490', sortable: true, width: '80px', align: 'end'},
        {text: '99439', value: 'CCM CPT 99439', sortable: true, width: '80px', align: 'end'},
        {text: '99437', value: 'CCM CPT 99437', sortable: true, width: '80px', align: 'end'},
      ];

      const rpmHeaders = [
        {text: 'RPM CPT 99453 value', value: 'RPM CPT 99453 value', sortable: true, width: '120px'},
        {text: 'RPM CPT 99454 value', value: 'RPM CPT 99454 value', sortable: true, width: '120px'},
        {text: 'RPM CPT 99457 value', value: 'RPM CPT 99457 value', sortable: true, width: '120px'},
        {text: 'RPM CPT 99458 value', value: 'RPM CPT 99458 value', sortable: true, width: '120px'},
        {text: '99458', value: 'RPM CPT 99458', sortable: true, width: '80px', align: 'end'},
        {text: '99457', value: 'RPM CPT 99457', sortable: true, width: '80px', align: 'end'},
        {text: '99454', value: 'RPM CPT 99454', sortable: true, width: '80px', align: 'end'},
        {text: '99453', value: 'RPM CPT 99453', sortable: true, width: '80px', align: 'end'},
      ];

      if (this.category === 'ccm') {
        return [...commonHeaders, ...ccmHeaders];
      } else if (this.category === 'rpm') {
        return [...commonHeaders, ...rpmHeaders];
      } else {
        return commonHeaders;
      }
    },
    dateColumns() {
      return [
        'Date of Birth',
        'SOF Date',
        'Billing Month',
        'Care Plan Start Date',
        'start',
        'end',
      ];
    },
  },
  methods: {
    getPropertyName(name) {
      const mapping = {
        patientName: 'Patient Name',
        rpmCpt99458: 'RPM CPT 99458',
        rpmCpt99457: 'RPM CPT 99457',
        rpmCpt99454: 'RPM CPT 99454',
        rpmCpt99453: 'RPM CPT 99453',
        ccmCpt99490: 'CCM CPT 99490',
        ccmCpt99439: 'CCM CPT 99439',
        ccmCpt99437: 'CCM CPT 99437',
        ccmCpt99491: 'CCM CPT 99491',
      };
      return mapping[name] || name;
    },
    dateFormatter(value) {
      return value ? moment(value).format('MMM D, YYYY') : '';
    },
    async getData() {
      if (!(this.client && this.category)) {
        return;
      }
      this.loading = true;
      return Vue.$http
          .get('/api/rpm_ccm_dashboard/billing-data', {
            params: {
              token: await this.token,
              category: this.category,
              client: this.client,
              month: this.month,
              year: this.year,
            },
          })
          .then((res) => (this.tableData = res.data.data))
          .catch((error) => this.$awn.warning(error.response.exception))
          .finally(() => {
            this.loading = false;
          });
    },
    csvLink() {
      const file = new File([this.arrayToCSV()], 'report.csv', { type: 'text/csv' });
      return URL.createObjectURL(file);
    },
    arrayToCSV() {
      if (!this.tableDataFiltered.length) {
        return '';
      }
      const escapeField = field => `"${('' + field).replace(/"/g, '""').replace(/\n/g, '\\n')}"`;

      const csvHeaders = Object.keys(this.tableDataFiltered[0]).map(escapeField).join(',');
      const csvRows = this.tableDataFiltered.map(row => Object.values(row).map(escapeField).join(',')).join('\n');

      return `${csvHeaders}\n${csvRows}`;
    },
  },
  watch: {
    year() {
      this.getData();
    },
    month() {
      this.getData();
    },
    category() {
      this.getData();
    },
    client() {
      this.getData();
    },
  },
};
</script>

<style scoped>
.freeze {
  position: sticky;
  right: 0;
  background: white;
  z-index: 1;
}
</style>