<template>
  <div>
    <div id="rc-widget-container"></div>

    <p v-if="cell"><el-button @click="fillPhoneNumber(cell)" size="mini" type="primary">Cell: {{ cell }}</el-button></p>
    <p v-if="phone"><el-button @click="fillPhoneNumber(phone)" size="mini" type="primary">Phone: {{ phone }}</el-button></p>

  </div>
</template>

<script>
export default {
  props: {
    phone: String,
    cell: String
  },
  name: 'RingCentralEmbeddable',
  mounted() {
    const script = document.createElement('script');
    const redirectUri = encodeURI("https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/redirect.html");
    const clientId = encodeURI(process.env.VUE_APP_RINGCENTRAL_CLIENT_ID);
    script.innerHTML = `
      (function() {
        var rcs = document.createElement("script");
        rcs.src = "https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/adapter.js?clientId=${clientId}&appServer=${encodeURI("https://platform.ringcentral.com")}&redirectUri=${redirectUri}";
        var rcs0 = document.getElementsByTagName("script")[0];
        rcs0.parentNode.insertBefore(rcs, rcs0);
      })();
    `;
    script.onload = () => {
      const number = this.cell || this.phone;
      if (number) {
        this.fillPhoneNumber(number);
      }
    };
    document.body.appendChild(script);
  },
  methods: {
    fillPhoneNumber(number) {
      const iframe = document.getElementById('rc-widget-adapter-frame');
      if (iframe) {
        iframe.contentWindow.postMessage({
          type: 'rc-adapter-new-call',
          phoneNumber: number
        }, '*');
      }
    }
  }
}
</script>