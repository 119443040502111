<template>
  <div>
    <p class="txt__title">PATIENT CONSENT AND AUTHORIZATION - 3/4</p>
    <p class="txt__date">Dated: {{ patient.agreed_to_client_form | datetime }}</p>
    <div>
      <div class="w-50 txt__location">
        <strong>To:</strong> {{ authUser.primary_clients[0]['name'] }}<br/>
        {{ authUser['first_name'] }} {{ authUser['last_name'] }}<br/>
        {{ authUser.primary_clients[0]['address1'] }}<br/>
        {{ authUser.primary_clients[0]['city'] }} {{ authUser.primary_clients[0]['state'] }} {{ authUser.primary_clients[0]['zip'] }}<br/>
        {{ authUser.primary_clients[0]['phone'] }}<br/>
        {{ authUser['email'] }}<br/>
      </div>
      <br/>
      <div class="w-50 txt__location">
        <strong>From:</strong> {{ patient.first_name }} {{ patient.last_name }}<br/>
        DOB: {{ patient.dob }}<br/>
        {{ patient.address1 }}<br/>
        <div v-if="patient.address2">{{ patient.address2 }}</div>
        <br/>
        {{ patient.city }} {{ patient.state }} {{ patient.zip }}<br/>
        {{ patient.phone }}<br/>
        {{ patient.email }}<br/>
      </div>
    </div>
    <p class="txt__content text-block mt-5">
      {{ legalForm.text }}
    </p><br/>
    <div>
      <el-checkbox v-model="legalCheck.firstContact" class="mb-3"><span class="text-lg">First Contact</span></el-checkbox>
      <br/>
      <el-checkbox v-model="legalCheck.cellService" class="mb-3"><span class="text-lg">Cell Service / Wi-Fi</span></el-checkbox>
      <br/>
      <el-checkbox v-model="legalCheck.dailyCommitment" class="mb-3"><span class="text-lg">Daily Commitment</span></el-checkbox>
      <br/>
      <el-checkbox v-model="legalCheck.reviewTos" class="mb-3"><span class="text-lg">Review Terms of Agreement</span></el-checkbox>
    </div>
  </div>
</template>
<script>


import moment from "moment-timezone";

export default {
  name: 'Agreement',
  methods: {moment},
  props: {
    legalCheck: {},
    authUser: {},
    patient: {},
    legalForm: {},
  }
}
</script>