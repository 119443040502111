var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('rpm-layout',[_c('div',{staticClass:"bg-white rounded-lg p-2"},[_c('p',{staticClass:"text-gray-700 text-2xl font-bold mb-2"},[_vm._v(" Task Report "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}])},[_c('span',[_vm._v(" Report shows total hours entered by task for each health coach."),_c('br'),_vm._v(" The first line displays hours between the 1st and 15th of the month."),_c('br'),_vm._v(" The second line displays hours between the 16th and the end of the month."),_c('br'),_vm._v(" The third line displays the total hours for the month. ")])])],1),_c('div',{staticClass:"flex justify-between items-center p-2"},[_c('div',[(Object.keys(_vm.tableData).length)?_c('a',{staticClass:"m-2 py-2.5 px-5 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200",attrs:{"target":"_blank","download":(_vm.clientName + "-" + _vm.date_from + "-" + _vm.date_to + "-task-report.csv"),"href":_vm.csvLink()}},[_vm._v("Download Report CSV")]):_vm._e()]),_c('div',[_c('el-button',{attrs:{"disabled":_vm.loading,"type":"default"},on:{"click":_vm.getData}},[_vm._v("Refresh")])],1)]),_c('div',{staticClass:"flex gap-2 items-center mb-2"},[_c('v-text-field',{attrs:{"placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date From","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date To","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}})],1),_c('v-select',{attrs:{"items":_vm.availableClients.map(function (client) { return ({ value: client.id, text: client.name }); }),"label":"Client","placeholder":"Select Client","filterable":""},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableRows,"items-per-page":-1,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"freeze"},[_vm._v(_vm._s(props.item.name))]),_vm._l((_vm.tableHeaders.slice(1)),function(header,index){return _c('td',{key:index,domProps:{"innerHTML":_vm._s(props.item[header.value] || '0')}})})],2)]}}])}),(_vm.downloadReportPatient)?_c('div',[_c('patient-report',{attrs:{"patient":_vm.downloadReportPatient,"category":_vm.category},on:{"downloaded":function($event){return _vm.downloadReport(null)}}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }