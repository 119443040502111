<template>

  <main>
    
    <div class="wrapper__credentialing">
    <div class="flex flex-col linear-container px-6 md:px-14 py-5 md:py-12">

      <div class="flex josefin_font">

        <img src="@/assets/white-back.svg" @click="goBack" style="cursor: pointer;" />

        <h3 class="header-text">Credentialing</h3>

      </div>

      <p class="py-3 m-0 text-white font-light">
        ViTel provides Digital Credentialing services for medical groups, physician and payor networks, and for
        employment.
      </p>

    </div>
    </div>

    <div class="comp_container flex flex-col md:flex-row" >
    
      <div class="w-full md:w-3/4 items-center">

        <section class="improve_efficiency" v-if="profile.subscriptionPlan === 'Essentials'">
          <div>

            <p class="josefin_font black-text">
              ViTel provides expedited digital credentialing for physicians into provider and payor networks on a per
              network basis or you may upgrade to our
              <span @click="goToMemberShipPage" class="membershipLink">Enhanced Member Subscription</span> which
              includes unlimited credentialing.
            </p>
            <br>

          </div>
        </section>

        <section class="improve_efficiency" v-if="profile.subscriptionPlan === 'Enhanced'">
          <div>

            <p class="josefin_font black-text">
              ViTel provides expedited digital credentialing and contracting for physicians into provider and payor
              networks on a per network basis. Your {{profile.subscriptionPlan }} membership includes
              PSV (Primary Source Verification) credentialing based on information entered into your Physician Vault.
              Provider and payor network contracting, based on your PSV, is quoted by contract.
            </p>

            <a href="https://calendly.com/colin-siaw/credentialing-at-vitel-health" target="_blank">
              <button class="josefin_font green_button mb-4 mt-3">Request Credentialing</button>
            </a>

          </div>
        </section>

        <section class="improve_efficiency" v-if="profile.subscriptionPlan === 'Premier'">
          <div>

            <p class="josefin_font black-text">
              ViTel provides expedited digital credentialing and contracting for physicians into provider and payor
              networks on a per network basis. Your {{profile.subscriptionPlan }} membership includes
              PSV (Primary Source Verification) credentialing based on information entered into your Physician Vault.
              Provider and payor network contracting, based on your PSV, is quoted by contract.
            </p>

            <a href="https://calendly.com/colin-siaw/credentialing-at-vitel-health" target="_blank">
              <button class="josefin_font green_button mb-4 mt-3">Request Credentialing</button>
            </a>

          </div>
        </section>
        <section class="improve_efficiency " v-if="profile.subscriptionPlan === ''">
          <p class="josefin_font black-text">
              ViTel provides expedited digital credentialing and contracting for physicians into provider and payor
              networks on a per network basis. Your {{profile.subscriptionPlan }} membership includes
              PSV (Primary Source Verification) credentialing based on information entered into your Physician Vault.
              Provider and payor network contracting, based on your PSV, is quoted by contract.
            </p>
            <p class="desc-txt lato">
              Click the button below to request a per network credentialing quote 
            </p>
            <div class="button-group">
            <a href="#">
              <button class="josefin_font green_button mt-3">Request Quote</button>
            </a>

            <a href="#">
              <button class="josefin_font blue_button mb-4 mt-3">Meeting Request</button>
            </a>
          </div>
        </section>
      </div>

      <div class="w-full md:w-2/4 flex justify-center img_wrapper">
        <img src="@/assets/telehealth.svg" alt="" class="telehealth_image">
      </div>

    </div>

  </main>

</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  methods: {
    ...mapActions(['fetchSecretKeys']),
    goBack() {
      this.$router.go(-1)
    },
    goToMemberShipPage() {
      this.$router.push({ name: 'memberships', params: { scroll: true } })
    },
  },
  computed: {
    ...mapState(['secretKeys', 'profile'])
  }
}
</script>

<style>
.wrapper__credentialing {
  background: linear-gradient(90deg, #5e60ce 66.28%, #48bfe3 100%);
}
.button-group {
  display: flex;
  flex-direction: column;
}
.desc-txt {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9A9A9A;
  margin-top: 10px;
  margin-bottom: 5px;
}
.linear-container {
  padding: 3rem 20px;
  max-width: 1440px;
  margin: auto;
  background: initial;
}
.comp_container {
  max-width: 1440px;
  margin: auto;
  padding: 0px 20px;
}
.black-text {
  color: #292929 !important;
  font-size: 22px !important;
  line-height: 1.55rem !important;
}
/* Responsive Design */
@media (max-width: 991.98px) {
  .w8-12 {
    width: auto;
  }
  .comp_container {
    padding: 1rem;
    width: auto;
  }
  .list-width {
    width: auto;
  }
}

@media (max-width: 575.98px) {
  .linear-container {
    background: linear-gradient(90deg, #5E60CE 66.28%, #48BFE3 100%);
    padding: 1rem;
  }
  .img_wrapper {
    margin-top: 4rem;
  }
}

</style>