<template>
  <rpm-layout>
    <div class="bg-white rounded-lg p-2">
      <p class="text-gray-700 text-2xl font-bold mb-2">Status Report</p>
      <div class="flex justify-between items-center p-2">
        <div>
          <a target="_blank" :download="`${clientName}-${date_from}-${date_to}-${period}-status-report.csv`" :href="csvLink()" class="m-2 py-2.5 px-5 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200" v-if="Object.keys(tableData).length">Download Report CSV</a>
        </div>
        <div>
          <el-button :disabled="loading" type="default" @click="getData">Refresh</el-button>
        </div>
      </div>
      <div class="flex gap-2 items-center mb-2">
        <v-select
            v-model="period"
            :items="periodOptions"
            label="Period"
            placeholder="Select Period"
            filterable
        ></v-select>

        <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="date_from"
              label="Date From"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
          <v-date-picker
              v-model="date_from"
              no-title
              scrollable
              @input="menu1 = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date_to"
                label="Date To"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date_to"
              no-title
              scrollable
              @input="menu2 = false"
          ></v-date-picker>
        </v-menu>

        <v-select
            v-model="client"
            :items="availableClients.map(client => ({ value: client.id, text: client.name }))"
            label="Client"
            placeholder="Select Client"
            filterable
        ></v-select>

        <v-select
            v-model="dataFilter"
            :items="dataFilterOptions"
            label="Data Filter"
            placeholder="Select Data Filter"
            filterable
        ></v-select>

      </div>

      <v-data-table
          :headers="tableHeaders"
          :items="tableRows"
          class="elevation-1"
          :items-per-page="-1"
          :loading="loading"
      >
        <template v-slot:item="props">
          <tr>
            <td class="freeze">{{ props.item.status }}</td>
            <td v-for="(header, index) in tableHeaders.slice(1)" :key="index" v-html="props.item[header.text] || 0"></td>
          </tr>
        </template>
      </v-data-table>

      <div v-if="downloadReportPatient">
        <patient-report :patient="downloadReportPatient" :category="category" v-on:downloaded="downloadReport(null)"/>
      </div>
    </div>
  </rpm-layout>
</template>

<style>
body {
  margin: 0;
}
</style>

<script>
import RpmLayout from '../../layouts/RpmLayout.vue';
import PatientReport from './PatientReport.vue';
import moment from "moment-timezone";
import Vue from "vue";
import PatientMixin from "@/mixins/Patient";


export default {
  mixins: [PatientMixin],
  components: {RpmLayout, PatientReport},
  data() {
    return {
      client: null,
      period: 'monthly',
      date_from: moment().startOf('year').format('YYYY-MM-DD'),
      date_to: moment().add(1, 'days').format('YYYY-MM-DD'),
      menu1: false,
      menu2: false,
      tableData: [],
      tableHeaders: [],
      tableRows: [],
      token: null,
      loading: true,
      downloadReportPatient: null,
      periodOptions: [
        { value: 'daily', text: 'Daily' },
        { value: 'weekly', text: 'Weekly' },
        { value: 'monthly', text: 'Monthly' },
        { value: 'quarterly', text: 'Quarterly' },
        { value: 'yearly', text: 'Yearly' }
      ],
      dataFilter: 'totals_and_changes',
      dataFilterOptions: [
        { value: 'totals_and_changes', text: 'Totals and Changes' },
        { value: 'totals', text: 'Totals' },
        { value: 'changes', text: 'Changes' }
      ]
    };
  },
  created: async function () {
    this.token = await this.$auth.getTokenSilently();
    Promise.all([
      !this.$store.state.patients.authUser.id ? this.$store.dispatch('getAuthUser') : null,
      !this.$store.state.patients.patients.length ? this.$store.dispatch('getPatients') : null,
      !this.$store.state.patients.providers.length ? this.$store.dispatch('getProviders') : null,
    ]).finally(async () => {
      this.client = this.availableClients[0].id;
      await this.getData();
      this.loading = false;
    });
  },
  mounted: function () {
  },
  computed: {
    clientName() {
      if (!this.client) return '';
      return this.availableClients.find((c) => c.id == this.client).name;
    },
    availableClients() {
      let available_clients = this.$store.state.patients.authUser.available_clients;
      return available_clients && available_clients.length ? available_clients : [];
    },
    objectToCSV() {
      if (!this.tableRows.length) {
        return '';
      }
      const escapeField = field => `"${(''+field).replace(/"/g, '""').replace(/\n/g, '\\n')}"`;
      const stripHTML = field => field.replace(/<\/?[^>]+(>|$)/g, "");
      const csvHeaders = this.tableHeaders.map(header => escapeField(header.text)).join(',');

      const csvRows = this.tableRows.map(row => {
        return this.tableHeaders.map((header, index) => {
          if (index === 0) {
            return escapeField(row[header.value] || '');
          } else {
            if (this.dataFilter == 'totals_and_changes' || this.dataFilter == 'changes') {
              return escapeField(stripHTML(row[header.text] || '0'));
            }
            return escapeField(row[header.text] || '0');
          }
        }).join(',');
      }).join('\n');

      return `${csvHeaders}\n${csvRows}`;
    }
  },
  watch: {
    date_from() {
      this.getData();
    },
    date_to() {
      this.getData();
    },
    period() {
      this.getData();
    },
    client() {
      this.getData();
    },
    dataFilter() {
      this.transformTableData();
    }
  },
  methods: {
    dateFormatter(value) {
      if (!value) return '';

      switch (this.period) {
        case 'daily':
          return moment(value).format("MMM D, YYYY");
        case 'weekly':
          return `Week of ${moment(value).startOf('week').format("MMM D, YYYY")}`;
        case 'monthly':
          return moment(value).format("MMM YYYY");
        case 'quarterly':
          return `Q${moment(value).quarter()} ${moment(value).format("YYYY")}`;
        case 'yearly':
          return moment(value).format("YYYY");
        default:
          return moment(value).format("MMM D, YYYY");
      }
    },
    csvLink() {
      const file = new File([this.objectToCSV], 'report.csv', {type: 'text/csv',});
      return URL.createObjectURL(file);
    },
    async getData() {
      if (!this.client || !this.period || !this.date_from || !this.date_to) {
        return;
      }

      if (this.date_to <= this.date_from) {
        this.$awn.warning('Date from/to need to be in valid ranges');
        return;
      }

      this.loading = true;
      return Vue.$http
          .get("/api/rpm_ccm_dashboard/status-report-data", {
            params: {
              token: await this.token,
              period: this.period,
              client: this.client,
              date_from: this.date_from,
              date_to: this.date_to,
            },
          })
          .then((res) => {
            this.tableData = res.data.data;
            this.transformTableData();
          })
          .catch((error) => this.$awn.warning(error.response.exception))
          .finally(() => {
            this.loading = false;
          });
    },
    transformTableData() {
      const statuses = new Set();
      const periods = Object.keys(this.tableData.dataTotal);

      periods.forEach(period => {
        Object.keys(this.tableData.dataTotal[period]).forEach(status => {
          statuses.add(status);
        });
      });

      this.tableHeaders = [
        { text: 'Status', value: 'status', sortable: false },
        ...periods.map(period => ({ text: this.dateFormatter(period), value: period, sortable: false }))
      ];

      this.tableRows = Array.from(statuses).map(status => {
        const row = { status };
        periods.forEach(period => {
          let data;
          if (this.dataFilter === 'totals_and_changes') {
            data = this.tableData.dataTotal[period][status] || 0;
            let added = this.tableData.dataAdded[period][status] || 0;
            let subtracted = this.tableData.dataSubtracted[period][status] || 0;
            data += ` (<span style="color: green;">+${added}</span>, <span style="color: red;">-${subtracted}</span>)`;
          } else if (this.dataFilter === 'totals') {
            data = this.tableData.dataTotal[period][status] || 0;
          } else if (this.dataFilter === 'changes') {
            let added = this.tableData.dataAdded[period][status] || 0;
            let subtracted = this.tableData.dataSubtracted[period][status] || 0;
            data = ` (<span style="color: green;">+${added}</span>, <span style="color: red;">-${subtracted}</span>)`;
          }

          row[this.dateFormatter(period)] = data;
        });
        return row;
      });
    }
  }
};
</script>

<style scoped>
.freeze {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}
</style>