<template>
  <div class="flex">
    <div class="sidebar">
      <div v-for="route in routes" :key="route.name">
        <div v-bind:class="{
          active_tab: route.name === getCurrentRoute,
          inactive_tab: route.name !== getCurrentRoute,
        }" @click="goTo(route)">
          <div class="flex flex-col items-center text-white py-5 cursor-pointer">
            <img :src="route.icon" width="30" height="30">
            <p>{{ route.label }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routes: [
        {
          name: "rpm-dashboard-patients",
          label: "Patients",
          icon: require("@/assets/rpm-ccm-tabs/patients.svg"),
        },
        {
          name: "rpm-dashboard-order-forms",
          label: "Order Forms",
          icon: require("@/assets/rpm-ccm-tabs/patients.svg"),
        },
        {
          name: "rpm-dashboard-tasks",
          label: "Your Tasks",
          icon: require("@/assets/rpm-ccm-tabs/fa-file-lines.svg"),
        },
        {
          name: "rpm-dashboard-alerts",
          label: "Alerts",
          icon: require("@/assets/rpm-ccm-tabs/alerts.svg"),
        },
        {
          name: "rpm-dashboard-rpm-data",
          label: "RPM Data",
          icon: require("@/assets/rpm-ccm-tabs/rpm-data.svg"),
        },
        {
          name: "rpm-dashboard-ccm-data",
          label: "CCM Data",
          icon: require("@/assets/rpm-ccm-tabs/ccm-data.svg"),
        },
        {
          name: "rpm-dashboard-billing-summary",
          label: "Billing",
          icon: require("@/assets/rpm-ccm-tabs/bank-note.svg"),
        },
        {
          name: "rpm-dashboard-reports",
          label: "Reports",
          icon: require("@/assets/rpm-ccm-tabs/fa-file-lines.svg"),
        },
      ],
    };
  },
  computed: {
    getCurrentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    goTo(route) {
      if (this.$route.name !== route.name) {
        this.$router.push({ name: route.name });
      }
    },
  },
};
</script>

<style scoped>
.sidebar {
  background: linear-gradient(180deg, #5186ef 50%, #9affcd 100%);
  width: 100px;
  min-height: calc(100vh);
}

.content {
  background: #ddf1f7;
  width: calc(100vw - 100px);
  min-height: calc(100vh);
  overflow: auto;
  padding: 1rem;
}

.active_tab {
  background: rgba(255, 255, 255, 25%);
}

.inactive_tab:hover {
  background: rgba(255, 255, 255, 5%);
}
</style>
