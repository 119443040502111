<template>
  <div class="">
    <nav v-if="!checkRoutes" class="px-4 md:px-10 py-3 shadow-navbar bg-white">
        <!-- mobile nav -->
        <div class="flex md:hidden justify-between items-center">
          <a href="/">
            <img class="w-28 md:w-40" src="@/assets/logo.png"/>
          </a>
          <button @click="toogleShow" class="w-6">
            <span v-if="show === true" class="font-bold w-8 absolute right-[4px] top-[12px]">X</span>
            <img v-else src="@/assets/menu-icon.svg" alt="menu-icon">
          </button>
          <div v-if="show">
          <ul class="flex flex-col gap-2">

            <li v-if="!$auth.isAuthenticated && !$auth.loading" class="nav-item">
              <button
                  id="qsLoginBtn"
                  class="btn btn-margin login-btn rounded text-white"
                  @click.prevent="login"
              >
                Login
              </button>
            </li>
            <div class="absolute top-20 right-0 bg-white z-50">
              <div class="flex flex-col gap-2 py-3">
          
                <li class="mx-3">
                  <router-link to="/profile">Profile</router-link>
                </li>

<!--                <li class="mx-3">-->
<!--                  <router-link to="/memberships">Memberships</router-link>-->
<!--                </li>-->

                <li class="mx-3">
                  <router-link to="/access">Access</router-link>
                </li>

                <li class="mx-3">
                  <router-link to="/calendar">Calendar</router-link>
                </li>

                <li class="mx-3">
                  <a @click.prevent="logout" href="#">Logout</a>
                </li>

<!--                <li class="mx-3">-->
<!--                  <router-link to="/memberships">-->
<!--                    <button class="mt-2 green_button josefin_font">{{ profile.customerID ? 'Manage Subscription' : 'Upgrade' }}</button>-->
<!--                  </router-link>-->
<!--                </li>-->

              </div>
            </div>
          </ul>

        </div>
        </div>
        <div class="nav__container">
        <div class="hidden md:flex justify-between items-center ">
          <a href="/">
            <img class="w-28 md:w-40" src="@/assets/logo.png"/>
          </a>
          <ul>
            <div class="flex items-center">
              <li class="mx-3">
                <router-link to="/profile">Profile</router-link>
              </li>

<!--              <li class="mx-3">-->
<!--                <router-link to="/memberships">Memberships</router-link>-->
<!--              </li>-->

              <li class="mx-3">
                <router-link to="/access">Access</router-link>
              </li>

              <li class="mx-3">
                <router-link to="/calendar">Calendar</router-link>
              </li>

              <li class="mx-3">
                <a @click.prevent="logout" href="#">Logout</a>
              </li>

<!--              <li class="mx-3">-->
<!--                <router-link to="/memberships">-->
<!--                  <button class="mt-2 green_button josefin_font">{{ profile.customerID ? 'Manage Subscription' : 'Upgrade' }}</button>-->
<!--                </router-link>-->
<!--              </li>-->

            </div>

          </ul>
        </div>
        </div>
          <!-- <ul id="mobileAuthNavBar" class="navbar-nav flex md:hidden" v-if="$auth.isAuthenticated">

            <li class="nav-item">
              <span class="user-info">

                <img
                    :src="$auth.user.picture"
                    alt="User's profile picture"
                    class="nav-user-profile d-inline-block rounded-circle mr-3"
                    width="50"
                />

                <h6 class="d-inline-block">{{ $auth.user.name }}</h6>

              </span>
            </li>

            <li>
              <font-awesome-icon icon="power-off" class="mr-3"/>
              <a id="qsLogoutBtn" href="#" class @click.prevent="logout">Log out</a>
            </li>

          </ul> -->
      <!-- </div> -->
    </nav>

    <nav v-else class="px-4 md:px-10 py-3 shadow-navbar bg-white pointer-none">

      <div class="navbar-brand">
        <a href="/">
          <img class="logo-img" src="@/assets/logo.png"/>
        </a>
      </div>

    </nav>
  </div>


</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "NavBar",
  data() {
    return {
      show: false,
      notes:null,
      errors:[],
    }
  },
  computed: {
    checkRoutes() {
      return (
          this.$route.name === "verify-email" || this.$route.name === 'webinar-account-confirmation' || this.$route.name === 'sms-min'
      );
    },
    ...mapState(['profile'])
  },

  methods: {
    toogleShow() {
      this.show = !this.show
    },
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout();
      this.$router.push({path: "/"});
      sessionStorage.removeItem("imageFile");
    },
  }
};
</script>

<style>
/* #mobileAuthNavBar {
  min-height: 125px;
  justify-content: space-between;
} */
/* Dialog Task */

.pointer-none {
  pointer-events: none;
}

.main_container {
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav__container {
    max-width: 1560px;
    margin: auto;
    border: 0px;
    outline: none;
    background: none;
}

.shadow-navbar {
  box-shadow: 0 1px 2px 0 #dfe3ec;
}

.navbar {
  height: 5rem;
}

.logo-img {
  width: 13rem;
  object-fit: contain;
}

.all_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  color: #292929 !important;
  font-weight: 600;
  text-decoration: none !important;
}

a:hover {
  color: #02E864 !important;
}

a.router-link-exact-active {
  color: #02E864 !important;
  border-bottom: none !important;
}

.bold {
  font-weight: 600;
  font-size: 1.2rem;
}

.fs-3 {
  font-size: 1.2rem;
}

.dropdown-menu {
  left: -37px;
}

.nav-link {
  text-decoration: none !important;
}

.login-btn {
  background: #48BDE3;
}

</style>
