<script>
import moment from "moment-timezone";
import Vue from "vue";
import MessageList from "@/views/MessageList.vue";

export default {
  components: {MessageList},
  // name: "SmsMin"
  created: async function () {
    await this.$store.dispatch('getAuthUser');
    await this.$store.dispatch('getPatients');
    this.selectedPatient = this.recentSenders[0];
    this.loading = false;
  },
  data() {
    return {
      // sms: '',
      loading: true,
      selectedPatient: null,
      message: '',
    };
  },
  computed: {
    authUser() {
      return this.$store.state.patients.authUser
    },
    messages() {
      return this.$store.state.patients.authUser.sms;
    },
    selectedMessages() {
      if (!this.selectedPatient) return [];
      return this.$store.state.patients.authUser.sms.filter(m => m.sender_id === this.selectedPatient.id || m.recipient_id === this.selectedPatient.id);
    },
    recentSenders() {
      return this.messages.map(m => ({id: (m.sender_id === this.authUser.id ? m.recipient_id : m.sender_id), created_at: m.created_at}))
          .filter((v, i, a) => a.findIndex(val => val.id === v.id) === i)//unique
          .sort((a,b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
          .map(m => this.$store.state.patients.patients.find(p => p.id === m.id))
          .filter(u => u !== undefined);
    },
  },
  methods: {
    user(id, field) {
      if (id === this.authUser.id) return this.authUser[field];
      const user = this.$store.state.patients.patients.find(p => p.id === id);
      if (user) return user[field];
    },
    async refresh() {
      await this.$store.dispatch('getAuthUser');
    },
    async send() {
      if (!this.message) {
        return;
      }
      this.loading = true;
      return Vue.$http.post(`/api/rpm_ccm_dashboard/send-sms`, null, { params: { token: await this.$auth.getTokenSilently(), patient_id: this.selectedPatient.id, message: this.message } })
          .then(async () => {
            this.message = '';
            await this.$store.dispatch('getAuthUser');
            // this.$awn.success(res.message);

          })
          // .catch((error) => this.$awn.warning(error))
          .finally(() => {
            this.loading = false;
          });
    }
  },
}
</script>

<template>
  <div class="pt-4 px-4 w-screen top-0 pb-14 grid grid-cols-12 gap-1">
    <div class="col-span-2">
      <ul role="list" class="mb-16">
        <li class="cursor-pointer" @click="selectedPatient = patient" v-for="patient in recentSenders" v-bind:key="patient.id">
          <img :class="{'border-2': selectedPatient && selectedPatient.id === patient.id}" class="cursor-pointer border-blue-400 mx-auto flex h-12 w-12 p-1 items-center justify-center rounded-full" src="@/assets/user.svg" alt="">
          <span class="text-sm text-gray-700">{{user(patient.id, 'first_name')}}</span>
        </li>
      </ul>
    </div>
    <div class="col-span-10 h-full, overflow-x-scroll">
      <div class="relative flow-root">
        <MessageList :messages="selectedMessages" />
      </div>
    </div>
    <div class="col-span-12">
      <div class="fixed mt-6 px-2 flex gap-x-3 bottom-0 left-0 right-0 bg-white pb-3 w-100">
        <!--      <img src="@/assets/user.svg" alt="" class="h-6 w-6 flex-none rounded-full bg-gray-50">-->
        <div class="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
          <label for="comment" class="sr-only">Reply</label>
          <textarea v-model="message" rows="2" name="comment" id="comment" class="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" placeholder="Reply..."></textarea>
        </div>

        <div class=" inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
          <!--        <div class="flex items-center space-x-5">-->
          <!--        </div>-->
          <button @click="send" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Send</button>
          <button @click="refresh" class="ml-2 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
          </button>
        </div>
      </div>
    </div>

  </div>
</template>