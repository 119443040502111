import Vue from 'vue';

export default {
  state: {
    loader: false,
    customerID: '',
    subscriptionPlan: '',
    webinars: [],
    jobs: []
  },
  mutations: {
    mutate(state, payload) {
      state[payload.property] = payload.with
    }
  },
  actions: {
    updateProfilePicture({ commit }, data) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.patch(`/api/xchange/profile/picture`, null, {
          params: {
            user_id: data.user_id,
            image_url: data.image_url
          }
        })
          .then((res) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            resolve(res)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err)
          })
      })
    },

    updatePassword({ commit }, data) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.patch(`/api/xchange/profile/password`, null, {
          params: {
            email: data.email,
            current_password: data.current_password,
            new_password: data.new_password
          }
        })
          .then((res) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            resolve(res)

          })
          .catch((err) => {

            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err)

          })


      })
    },

    retrieveSubscription({ commit }, auth0_sub) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get(`/api/xchange/profile/membership`, {
          params: {
            ...auth0_sub
          }
        })
          .then((res) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            commit('mutate', {
              property: 'customerID',
              with: res.data.customer_id
            })
            commit('mutate', {
              property: 'subscriptionPlan',
              with: res.data.subscription_plan
            })
            resolve(res)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err);
          })
      })
    },

    fetchAllWebinars({ commit }) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get('/api/telehealth_events/vitel')
          .then((res) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            commit('mutate', {
              property: 'webinars',
              with: res.data
            })
            resolve(res.data)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err);
          })
      })
    },

    fetchAvailableJobs({ commit }) {
      commit('mutate', {
        property: 'loader',
        with: true,
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get('/api/vip/client/jobs')
          .then((res) => {
            commit('mutate', {
              property: 'jobs',
              with: res.data
            })
            resolve(res);
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err);
          })
      })
    }
  }
}