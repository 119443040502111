<script>

import moment from "moment-timezone";
import RpmDashboardErrors from "@/components/RpmDashboardErrors.vue";
import Vue from "vue";
import {mapState} from "vuex";

export default {
  name: 'OrderForm',
  components: {RpmDashboardErrors},
  props: {
    patient: Object,
    provider_id_prop: null,
    selected_devices_prop: null,
  },
  data() {
    return {
      order_type: 'both',
      errors: [],
      token: null,
      provider_id: this.provider_id_prop,
      device_types: [],
      selected_devices: this.selected_devices_prop,
      selected_rpm_careplan_id: null,
      selected_ccm_careplan_id: null,
      loading: false,
    };
  },
  watch: {
    provider_id_prop: function (val) {
      this.provider_id = val;
    },
    selected_devices_prop: function (newVal) {
      this.selected_devices = [...newVal];
    }
  },
  methods: {
    orderForm() {
      this.errors = [];

      let data = {
        order_type: this.order_type,
        provider_id: this.provider_id,
        patient_id: this.patient.id,
        selected_devices: this.selected_devices,
        rpm_care_plan_id: this.selected_rpm_careplan ? this.selected_rpm_careplan.id : null,
        ccm_care_plan_id: this.selected_ccm_careplan ? this.selected_ccm_careplan.id : null,
      }

      this.loading = true;

      Vue.$http
          .post(`/api/rpm_ccm_dashboard/order_form`, data,{params: { token: this.token }})
          .then(() => {
            this.$emit('get-patient-details');
            this.$emit('hide-order-form');
            this.$awn.success('Order form(s) created successfully');
          }).finally(() => {
            this.loading = false;
          })
          .catch(error => this.handleEhrApiErrors(error, this.errors, this.$refs.orderFormComponentRef));
    },
    loadDeviceTypes() {
      Vue.$http.get(`/api/rpm_ccm_dashboard/device_list`)
        .then((res) => {
          this.device_types = res.data.data;
        })
        .catch(error => this.handleEhrApiErrors(error, this.errors));
    },
  },
  created: async function () {
    //this.provider_id = this.provider_id_prop;
    this.token = await this.$auth.getTokenSilently();
    this.loadDeviceTypes();
  },
  computed: {
    ...mapState(['patients']),
    selected_rpm_careplan: function () {
      return this.selected_rpm_careplan_id ? this.patient.care_plans.find(careplan => careplan.id === this.selected_rpm_careplan_id) : null;
    },
    selected_ccm_careplan: function () {
      return this.selected_ccm_careplan_id ? this.patient.care_plans.find(careplan => careplan.id === this.selected_ccm_careplan_id) : null;
    },
    providers: function () {
      return this.$store.state.patients.providers.filter(p => p.behaviors.includes('is_provider'));
    },
    dob() {
      return this.patient.dob ? moment(this.patient.dob).format("MMM D, YYYY") : ''
    },
    age() {
      return this.patient.dob ? moment.duration(moment().diff(this.patient.dob)).years() : '';
    },
    orderFormValidated()
    {
      switch (this.order_type) {
        case 'both':
        case 'rpm':
          return this.provider_id && this.selected_rpm_careplan_id && this.selected_devices.length;
        case 'ccm':
          return this.provider_id && this.selected_ccm_careplan_id;
      }
    }
  }
}
</script>
<template>
  <div class="mb-4" ref="orderFormComponentRef">
    <RpmDashboardErrors v-bind:errors="errors" v-bind:title="'Order Form Errors'"/>

    <div class="grid gap-4">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="select_order_type"> Order Type </label>
        <el-select id="select_order_type" v-model="order_type" >
          <el-option value="both" label="Both" />
          <el-option value="ccm" label="CCM" />
          <el-option value="rpm" label="RPM" />
        </el-select>
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="select_physician"> Physician </label>
        <el-select id="select_physician" v-model="provider_id" filterable placeholder="Select a physician">
          <el-option
              v-for="provider in providers"
              :key="provider.id"
              :label="provider.first_name + ' ' + provider.last_name + ' ' + (provider.email ? provider.email : '')"
              :value="provider.id">
          </el-option>
        </el-select>
      </div>

      <div class="mb-2" v-if="this.order_type === 'both' || this.order_type === 'rpm'">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="select_physician"> RPM CarePlan </label>
        <el-select v-model="selected_rpm_careplan_id" placeholder="Select">
          <el-option
              v-for="careplan in this.patient.care_plans.filter(careplan => careplan.type === 'rpm')"
              :key="careplan.id"
              :label="careplan.id + ' - ' + careplan.health_condition"
              :value="careplan.id">
          </el-option>
        </el-select>
      </div>

      <div class="text-sm" v-if="this.order_type === 'both' || this.order_type === 'rpm'">
        <div>
          <div class="mb-2">
            <p class="text-gray-500">Health Condition</p>
            <p>{{ this.selected_rpm_careplan ? this.selected_rpm_careplan.health_condition : '' }}</p>
          </div>
          <div class="mb-2">
            <p class="text-gray-500">Notes</p>
            <p>{{ this.selected_rpm_careplan ? this.selected_rpm_careplan.notes : '' }}</p>
          </div>
          <div class="mb-2">
            <p class="text-gray-500">ICD Codes</p>
            <p v-if="selected_rpm_careplan && selected_rpm_careplan.icd_codes.length">{{ this.selected_rpm_careplan ? this.selected_rpm_careplan.icd_codes.map(icd_code => `${icd_code.code} - ${icd_code.short_desc}`).join(', ') : ''}}</p>
            <p v-else>No ICD Codes</p>
          </div>
        </div>
      </div>

      <div class="mb-4" v-show="['both', 'rpm'].includes(order_type)">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="select_physician">Devices</label>
        <el-select v-model="selected_devices" multiple placeholder="Select">
          <el-option
              v-for="device in device_types"
              :key="device.name"
              :label="device.name"
              :value="device.name">
          </el-option>
        </el-select>
      </div>

      <hr class="mb-4" v-if="order_type === 'both'"/>

      <div class="mb-2" v-if="this.order_type === 'both' || this.order_type === 'ccm'">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="select_physician"> CCM CarePlan </label>
        <el-select v-model="selected_ccm_careplan_id" placeholder="Select">
          <el-option
              v-for="careplan in this.patient.care_plans.filter(careplan => careplan.type === 'ccm')"
              :key="careplan.id"
              :label="careplan.id + ' - ' + careplan.health_condition"
              :value="careplan.id">
          </el-option>
        </el-select>
      </div>

      <div class="text-sm" v-if="this.order_type === 'both' || this.order_type === 'ccm'">
        <div>
          <div class="mb-2">
            <p class="text-gray-500">Health Condition</p>
            <p>{{ this.selected_ccm_careplan ? this.selected_ccm_careplan.health_condition : '' }}</p>
          </div>
          <div class="mb-2">
            <p class="text-gray-500">Notes</p>
            <p>{{ this.selected_ccm_careplan ? this.selected_ccm_careplan.notes : '' }}</p>
          </div>
          <div class="mb-2">
            <p class="text-gray-500">ICD Codes</p>
            <p v-if="selected_ccm_careplan && selected_ccm_careplan.icd_codes.length">{{ this.selected_ccm_careplan ? this.selected_ccm_careplan.icd_codes.map(icd_code => `${icd_code.code} - ${icd_code.short_desc}`).join(', ') : ''}}</p>
            <p v-else>No ICD Codes</p>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-lg font-bold mb-1">Patient</label>
        <p><strong>Name: </strong>{{ patient.first_name }} {{ patient.last_name }}</p>
        <p><strong>Sex: </strong>{{ patient.sex }}</p>
        <p><strong>Dob: </strong>{{ dob }}</p>
        <p><strong>Age: </strong>{{ age }}</p>
      </div>
    </div>
    <div class="flex justify-between items-center ">
      <button v-on:click="orderForm" class="m-2 py-2.5 px-5 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200" :disabled="loading" v-if="orderFormValidated">Create Order Form(s)</button>
    </div>
  </div>
</template>
