<template>
  <div>
    <div class="grid grid-cols-2 gap-4">
      <div class="mb-4">
        <v-combobox
            v-model="patient.tags"
            :items="patientTags"
            label="Tags"
            multiple
            chips
            clearable
            hide-selected
            solo
            outlined
        >
          <template v-slot:selection="data">
            <span v-if="authUser.behaviors.find(b => b === 'delete_tags')">
              <v-chip
                  :key="data.item"
                  close
                  @click="removeTag(data.item)"
                  @click:close="confirmDeleteTag(data.item)"
              >
                {{ data.item }}
              </v-chip>
            </span>
            <span v-else>
              <v-chip
                  :key="data.item"
              >
                {{ data.item }}
              </v-chip>
            </span>
          </template>
        </v-combobox>
      </div>
    </div>

    <v-dialog v-model="confirmDialogVisible" max-width="500">
      <v-card>
        <v-card-title class="headline">Confirm Deletion</v-card-title>
        <v-card-text>Are you sure you want to delete this tag? (All patients will lose this tag if previously assigned)</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="loading" color="green darken-1" text @click="confirmDialogVisible = false">Cancel</v-btn>
          <v-btn :disabled="loading" color="red darken-1" text @click="deleteTag">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Tags',
  props: {
    authUser: Object,
    patient: Object,
    patientTags: Array,
  },
  data() {
    return {
      confirmDialogVisible: false,
      tagToDelete: null,
      loading: false,
    };
  },
  methods: {
    removeTag(tag) {
      this.patient.tags = this.patient.tags.filter(t => t !== tag);
    },
    confirmDeleteTag(tag) {
      this.tagToDelete = tag;
      this.confirmDialogVisible = true;
    },
    async deleteTag() {
      this.loading = true;
      try {
        await this.$http.delete('/api/rpm_ccm_dashboard/tag-delete', {
          params: {tag: encodeURIComponent(this.tagToDelete), token: await this.$auth.getTokenSilently()},
        }).then(async () => {
          await this.$store.dispatch('getAuthUser');
          this.$store.dispatch('getPatients');
          this.$awn.success('Tag deleted successfully');
        });
        this.patient.tags = this.patient.tags.filter(t => t !== this.tagToDelete);
      } catch (error) {
        this.$awn.alert(error.response.data.message);
      } finally {
        this.loading = false;
        this.confirmDialogVisible = false;
        this.tagToDelete = null;
      }
    }
  }
}
</script>