<template>
  <div>
    <div class="">
      <div class="wrapper">
      <div class="flex flex-col linear-container px-6 md:px-14 py-5 md:py-12">
        <div class="flex">
          <img src="@/assets/white-back.svg" @click="goBack" style="cursor: pointer;" />
          <h3 class="header-text">Accredited CME</h3>
        </div>
        <p class="py-3 m-0 text-white font-light sub_header">
          Complete your continued medical education credits with our accredited courses.
        </p>
        </div>
      </div>
        <div class="comp_container flex">
          <div class="flex">
<!--            <button class="green_button">Upgrade to Enhanced</button>-->
<!--            <button class="ml-4 blue_button">Upgrade to Premier</button>-->
          </div>
          <div class="w-full md:w-3/4 items-center">
              <div class="mb-1 mt-4">
              <button class="mt-2 josefin_font green_button">Login to the CME Platform</button>
            </div>
            <section class="improve_efficiency list-width">

              <h5 class="josefin_font sub-heading">
                Accredited CME On-Demand
              </h5>

              <p class="gray-text">
                <br>
<!--                  TODO: Marginaly increase whitespace between header and text below. Model after Doctors Lounge-->
                Complete your continued medical education credits with our accredited courses. As a physician,
                you’re constantly learning more to provide your patients with the best care available. Our
                CME platform provides you with hundreds of free and paid CME courses to choose from!
              </p>

              <div>
                <br> <br>
                <iframe src="https://player.vimeo.com/video/276887906?h=6d54c1f599" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </div>

            </section>
          </div>
          <div class="w-full md:w-2/4 flex justify-center img_wrapper">
            <img src="@/assets/cme.svg" alt="" class="cme_image">
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style>
.wrapper {
  background: linear-gradient(90deg, #5e60ce 66.28%, #48bfe3 100%);
}
.linear-container {
  padding: 3rem 20px;
  max-width: 1440px;
  margin: auto;
  background: initial;
}
.comp_container {
  max-width: 1440px;
  margin: auto;
  padding: 0px 20px;
}
.comp_container .sub_header {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666666;
}
.comp_container .small_text {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #292929;
  margin-top: 1.5rem;
}
.comp_container .header-text {
  color: #292929;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding-left: 1rem;
  margin: 0;
}
.improve_efficiency {
  margin-top: 2rem !important;
}
.img_wrapper {
  margin-top: 4rem;
}

.img_wrapper img {
  width: 100%;
}
/* Responsive Design */
@media (max-width: 575.98px) {
  .comp_container {
    padding: 1rem;
  }
  .cme_image {
    width: 20rem;
    margin-top: 3rem;
  }
  .img_wrapper {
    margin-top: 0rem;
  }
}
</style>