<template>
  <div class="grid gap-4">
    <div v-if="carePlan.id" class="mb-4">
      <label class="text-gray-700 text-sm font-bold mb-2" for="active"> Active </label>
      {{ carePlanLocal.active ? 'Yes' : 'No' }}
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="health_condition"> Health condition <span class="required">*</span> </label>
      <input v-model="carePlanLocal.health_condition"
             class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
             id="health_condition" type="text" placeholder=""/>
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="template"> Select Template </label>
      <el-select v-on:change="templateChange" id="template" value-key="id" v-model="selectedTemplate" clearable filterable class="w-full">
        <el-option v-for="template in templates" :value="template" :label="template.name" :key="template.id" />
      </el-select>
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="notes"> Notes <span class="required">*</span> </label>
      <textarea ref="ccmNotesRef" @keydown.tab.prevent="tabTemplate('ccmNotesRef')" v-model="notes" rows="4" class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="notes" placeholder=""></textarea>
    </div>

    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="rpm_note">End Date</label>
      <input v-model="carePlanLocal.end_date" type="date"
             class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
             id="end_date" />
    </div>

    <div>
      <label class="block text-gray-700 text-sm font-bold mb-2" for="icd_codes">
        ICD Codes Selected:
      </label>
    </div>

    <div class="mb-4">
      <div v-if="icd_codes_selected.length">
        <ul>
          <li v-for="(code, index) in icd_codes_selected" :key="code.id">
            {{ code.code }} - {{ code.short_desc }}
            <el-button icon="el-icon-close" circle @click="removeCode(index)"></el-button>
          </li>
        </ul>
      </div>
      <div v-else>
        No ICD Codes Selected.
      </div>

      <hr class="mt-4 mb-4" />

      <div>
        <el-input style="width: 50%" v-model="icd_codes_search" @input="debounceIcdCodeSearch" placeholder="Search" prefix-icon="el-icon-search" clearable :disabled="loading" class="mb-4" />
        <div v-if="icd_codes.length > 0 && !loading">
          <ul>
            <li v-for="(code, index) in icd_codes" :key="index" v-if="!icd_codes_selected.find(selectedCode => selectedCode.id === code.id)">
              <a href="#" @click.prevent="addCode(index)">{{ code.code }} - {{ code.short_desc }}</a>
            </li>
          </ul>
        </div>
        <div v-else-if="icd_codes_search.length">
          <span v-if="loading" class="el-icon-loading"></span>
          <span v-else>No ICD Codes Found.</span>
        </div>
      </div>
    </div>
    <Collapse class="mb-4">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="start_date">Start Date</label>
        <input v-model="carePlanLocal.start_date" type="date"
               class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
               id="start_date" />
        <p>*Please do not update unless fixing equipment delivery date*</p>
      </div>
    </Collapse>
  </div>
</template>
<script>
import Template from "@/mixins/Template";
import Vue from "vue";
import _ from 'lodash';
import Collapse from "@/components/Collapse.vue";

export default {
  components: {Collapse},
  mixins: [Template],
  name: 'CcmCarePlan',
  model: {
    prop: 'carePlan',
    event: 'carePlanUpdated',
  },
  data() {
    return {
      token: null,
      loading: false,
      icd_codes: [],
      icd_codes_search: '',
      icd_codes_selected: [],
    }
  },
  props: {
    carePlan: {},
    templates: {}
  },
  watch: {
    notes: function(notes) {
      this.carePlan.notes = notes;
    },
    icd_codes_selected: function(icd_codes_selected) {
      this.carePlan.icd_codes = icd_codes_selected;
    }
  },
  created: async function () {
    this.token = await this.$auth.getTokenSilently();
  },
  mounted: function() {
    this.carePlan.active = !! this.carePlan.active;
    this.notesOriginal = this.carePlan.notes;
    this.notes = this.carePlan.notes;
    this.icd_codes_selected = this.carePlan.icd_codes;
  },
  computed: {
    carePlanLocal: {
      get: function() {
        return this.carePlan
      },
      set: function(value) {
        this.$emit('carePlanUpdated', value)
      }
    }
  },
  methods: {
    removeCode(index) {
      this.icd_codes_selected.splice(index, 1);
    },
    addCode(index) {
      this.icd_codes_selected.push(this.icd_codes[index]);
    },
    debounceIcdCodeSearch: _.debounce(function () {
      this.searchIcdCodes();
    }, 2000),
    searchIcdCodes: function() {
      if (this.icd_codes_search.length >= 2) {
        this.loading = true;
        Vue.$http.get(`/api/rpm_ccm_dashboard/search_icd_codes`, {params: { search: this.icd_codes_search, token: this.token }})
        .then((res) => {
          this.icd_codes = res.data.data;
        }).catch((error) => {
          this.$awn.alert(error.response.data.message);
        }).finally(() => {
          this.loading = false;
        });
      }
    },
  }
}
</script>
<style scoped>


.ico__actionPanel a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #292929;
  padding: 10px 0px;
}

.select__arrow-container svg {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translate(0, -50%);
  height: 12px;
  width: 12px;
  z-index: 999;
}

.btn__back-container svg {
  top: -2px;
  position: relative;
  margin-right: 5px;
}

.table__modify tr th div {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  text-transform: capitalize;
  align-items: center;
  color: #292929;
  white-space: nowrap;
}

.table__modify tr th div svg {
  color: #9a9a9a;
}

.table__modify tbody tr {
  font-family: "Lato";
  font-style: normal;
  color: #292929;
}

.grid-cols-2 div:nth-child(odd) {
  margin-right: 12px;
}

.grid-cols-2 div:nth-child(even) {
  margin-left: 12px;
}

.dialog__body label {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #292929;
}

.panel__agreement .txt__location strong {
  font-weight: 700;
}

.input__registerPatient {
  background: #eeeeee;
  color: #292929;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

@media (max-width: 575.98px) {

}
</style>