<template>
  <div>
    <div class="bg-white rounded-lg mt-3 p-4">
      <div class="mb-2">
        <button type="button" @click="addDeviceModal = true" class="green_button ml-3 px-3">
          Order Device
        </button>
      </div>
      <v-data-table :loading="loading" :headers="dtheaders" :items="dtdevices">
        <template v-slot:loading>
          <v-progress-circular indeterminate></v-progress-circular>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <button @click="deactivate(item)" class="text-red-600 bg-transparent ring-1 ring-red-600 hover:text-white hover:bg-red-600 rounded-full text-sm font-medium text-center px-2 py-1">
            Deactivate
          </button>
        </template>
      </v-data-table>
    </div>
    <el-dialog :close-on-click-modal="false" title="Add Device" :visible.sync="addDeviceModal" width="50%" @close="clearOrder">
      <div ref="orderDeviceModalBody" class="dialog__body">
        <RpmDashboardErrors v-bind:errors="orderDeviceErrors" v-bind:title="'Order Device Errors'"/>
        <div class="grid gap-4">
          <div v-if="!orderType">
            <el-button @click="orderType = 'dropship'">Drop-ship</el-button>
            <el-button @click="orderType = 'existing'" type="default">Existing</el-button>
          </div>
          <div v-if="orderType === 'existing'" class="mb-4">
            <label for="gateway_id" class="mt-4 block text-sm font-medium leading-6 text-gray-900">Gateway Id</label>
            <div class="mt-2">
              <input v-model="gateway_id" name="gateway_id" id="gateway_id" class="mt-2 block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div v-if="orderType" class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="select_device"> Select Device </label>
            <el-select id="select_device" v-model="selectedDevice" value-key="name" class="w-full" filterable clearable>
              <el-option v-for="device in deviceTypes" :value="device" :key="device.name" :label="device.name"/>
            </el-select>
          </div>
        </div>
      </div>
      <div v-if="orderType" slot="footer" class="josefin dialog-footer">
        <el-button @click="orderDevice" type="primary" :disabled="loading">Order Device</el-button>
        <el-button @click="clearOrder" type="cancel">Cancel</el-button>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" title="Are you sure you want to deactivate this device?" :visible.sync="showDeactiveDeviceModal" @close="() => (this.showDeactiveDeviceModal = false)">
      <div class="flex justify-end">
        <el-button @click="deactivate(confirmDeactivate)" type="danger" :disabled="loading">Deactivate</el-button>
        <el-button @click="() => (this.showDeactiveDeviceModal = false)" type="default">Cancel</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import RpmDashboardErrors from "../../../../components/RpmDashboardErrors.vue";

export default {
  components: {RpmDashboardErrors},
  props: {
    patient: Object,
    accessToken: null,
  },
  data() {
    return {
      loading: false,
      orderType: null,
      gateway_id: null,
      deviceTypes: [],
      selectedDevice: null,
      addDeviceModal: false,
      orderDeviceErrors: [],
      confirmDeactivate: null,
    };
  },
  computed: {
    dtheaders() {
      return [
        {text: 'Medical Device', value: 'category'},
        {text: 'Device Model', value: 'name'},
        {text: 'Fulfillment Status', value: 'fulfillment_status'},
        {text: 'Gateway', value: 'gateway_id'},
        {text: 'Actions', value: 'actions'},
      ];
    },
    dtdevices() {
      return [
        ...this.patient.devices.map((device) => ({
          ...device,
          'gateway_id': device.gateway_id || "--",
        })),
      ]
    },
    showDeactiveDeviceModal: {
      get() {
        return this.confirmDeactivate !== null;
      },
      set(value) {
        if (!value) {
          this.confirmDeactivate = null;
        }
      },
    },
  },
  methods: {
    clearOrder() {
      this.loading = false;
      this.orderType = null;
      this.orderDeviceErrors = [];
      this.addDeviceModal = false;
    },
    loadDeviceTypes() {
      Vue.$http
          .get(`/api/rpm_ccm_dashboard/device_list`, {params: {device_id: this.device_id}})
          .then((res) => {
            this.deviceTypes = res.data.data;
          })
          .catch(error => this.handleEhrApiErrors(error, this.errors));
    },
    orderDevice() {
      if ((this.orderType === 'dropship' && !this.checkOrderDeviceForm()) || (this.orderType === 'existing' && !this.checkAddDeviceForm())) {
        this.$nextTick(() => {
          this.$refs.orderDeviceModalBody.scrollIntoView();
        });
        return;
      }

      this.loading = true;

      if (this.orderType === 'dropship') {
        Vue.$http.post(`/api/rpm_ccm_dashboard/order_device`, null, {params: {key: this.selectedDevice.name, patient_id: this.patient.id, token: this.accessToken,},})
            .then(() => {
              this.$emit('get-patient-details');
              this.clearOrder();
            })
            .catch(error => this.handleEhrApiErrors(error, this.orderDeviceErrors, this.$refs.orderDeviceModalBody)).finally(() => {
          this.loading = false;
        });
      } else if (this.orderType === 'existing') {
        Vue.$http.post(`/api/rpm_ccm_dashboard/add_device`, null, {params: {key: this.selectedDevice.name, patient_id: this.patient.id, token: this.accessToken, gateway_id: this.gateway_id},})
            .then(() => {
              this.$emit('get-patient-details');
              this.clearOrder();
            })
            .catch(error => this.handleEhrApiErrors(error, this.orderDeviceErrors, this.$refs.orderDeviceModalBody)).finally(() => {
          this.loading = false;
        });
      }
    },
    checkOrderDeviceForm() {
      const required = ["Device"];
      this.orderDeviceErrors = this.checkForm(required, {
        Device: this.selectedDevice,
      });
      return !this.orderDeviceErrors.length;
    },
    checkAddDeviceForm() {
      const required = ["Device", "Gateway"];
      this.orderDeviceErrors = this.checkForm(required, {
        Device: this.selectedDevice,
        "Gateway": this.gateway_id,
      });
      return !this.orderDeviceErrors.length;
    },
    checkForm(required, formValuesObj) {
      return required.filter((key) => !formValuesObj[key]).map((key) => key.replace("_", " ") + " is required.");
    },
    deactivate(device) {
      if (!this.confirmDeactivate) {
        this.confirmDeactivate = device;
      } else {
        this.loading = true;

        Vue.$http.delete(`/api/rpm_ccm_dashboard/deactivate_device`, {params: {device_id: device.id, token: this.accessToken}})
            .then(() => {
              this.$emit('get-patient-details');
              this.showDeactiveDeviceModal = false;
            })
            .catch((error) => this.handleEhrApiErrors(error, this.errors)).finally(() => {
          this.loading = false;
        });
      }
    },
  },
  created: function () {
    this.loadDeviceTypes();
  },
};
</script>
