<template>

  <div class="">

    <div class="wrapper">
      <div class="flex flex-col linear-container px-6 md:px-14 py-5 md:py-12">

        <div class="flex items-center">

          <img
            src="@/assets/white-back.svg"
            @click="goBack"
            style="cursor: pointer"
          />

          <h3 class="header-text josefin_font">Teleboard</h3>

        </div>

        <p class="py-3 m-0 text-white font-light w-auto">
          See how you can optimize your available time and see more patients.
        </p>

      </div>
    </div>

    <div class="comp_container flex flex-col md:flex-row">

      <div class="w-full md:w-3/4 items-center">

        <section class="improve_efficiency list-width">

          <h5 class="josefin_font sub-heading mt-5 mb-3">
            Post your job interests for our telehealth employers to find or search telehealth employment opportunities posted on our job board, “TeleBoard.”
          </h5>

          <p class="text-gray--color text-description--teleboard mb-2">
            When you upgrade to the <a @click="goToMemberShipPage" class="pointer text-blue--color">Enhanced Membership Subscription</a>, you can post your available hours, rates, speciality, and more to connect with employers and patients to grow your income and provide a better work/life balance. Or search our telehealth employment opportunities posted on our TeleBoard. We use AI to match you to your perfect opportunity.
          </p>



        </section>

        <div class="flex items-center w-full button__container">

          <button
            class="mt-2 green_button josefin_font w-auto"
            @click="goToJobBoard">
            View Employers
          </button>

          <button  v-if="profile.subscriptionPlan != 'Essentials'"
            class="mt-2 blue_button josefin_font"
            @click="goToCalendar">
            Calendar
          </button> 

        </div>

      </div>

      <div class="w-full md:w-2/4 flex justify-center img_wrapper">
        <img src="@/assets/available.svg" alt="" class="physical_img" />
      </div>

    </div>

  </div>

</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      subscriptionPlan: ''
    }
  },
  // async mounted() {
  //   let result = await this.$auth.getIdTokenClaims()
  //     .then(function(response) {
  //       return response;
  //     })
  //
  //     let subscription = Object.entries(result)[1]
  //     let subscription_plan = subscription[1]['subscription_plan']
  //     this.subscriptionPlan = subscription_plan
  // },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goToMemberShipPage() {
      this.$router.push({ name: 'memberships', params: {scroll: true}})
    },
    goToJobBoard() {
      this.$router.push('/jobs')
    },
    goToCalendar() {
      this.$router.push({ name: 'calendar'})
    }
},
computed: {
      ...mapState(['profile'])
    }

  }
</script>

<style>
.pointer {
  cursor: pointer;
}
.wrapper {
  background: linear-gradient(90deg, #5e60ce 66.28%, #48bfe3 100%);
}
.button__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0px;
}
.linear-container {
  padding: 3rem 20px;
  max-width: 1440px;
  margin: auto;
  background: initial;
}
.comp_container {
  max-width: 1440px;
  margin: auto;
  padding: 0px 20px;
}
.membershipLink {
  cursor: pointer;
  color: #48bfe3;
}
.membershipLink:hover {
  cursor: pointer;
  color: #068eb8fa;
}


.comp_container .sub_header {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #666666;
}
.comp_container .small_text {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #292929;
  margin-top: 1.5rem;
}
.comp_container .header-text {
  color: #292929;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding-left: 1rem;
  margin: 0;
}
.improve_efficiency {
  margin-top: 2rem;
}

.img_wrapper {
  margin-top: 4rem;
}

.img_wrapper img {
  width: 100%;
}
/* Responsive Design */

@media (max-width: 991px) {
  .button__container {
    margin-top: 10px;
  }
}

@media (max-width: 575.98px) {
  .linear-container {
    background: linear-gradient(90deg, #5e60ce 66.28%, #48bfe3 100%);
    padding: 1rem;
  }
  .comp_container {
    padding: 1rem;
  }

  .physical_img {
    width: 20rem;
    margin-top: 3rem;
  }

  .img_wrapper {
    margin-top: 0rem;
  }
}

.text-blue--color {
  color:#48BFE3 !important;
  font-weight: 700;
}

.text-blue--color:hover {
  color:#48BFE3 !important;
}

.text-gray--color {
  color: #9A9A9A !important;
}

.text-description--teleboard {
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px !important;
  
}

.text-description--teleboard .text-blue--color {
font-weight: 700;
}
</style>
