<template>
  <div>
    <div class="grid grid-cols-2 gap-4">
      <div class="mb-4" v-if="this.authUser && this.authUser.available_clients">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="client">
          Client <span class="required">*</span>
        </label>
        <div class="select__arrow-container">
          <el-select :disabled="!!patient.id" v-model="patient.primary_client_id" id="client" class="w-full" clearable filterable>
            <el-option v-for="client in this.authUser.available_clients" :value="client.id" :key="client.id" :label="client.name" />
          </el-select>
        </div>
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="status">
          Status <span class="required">*</span>
        </label>
        <div class="select__arrow-container">
          <svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg' style="pointer-events: none;">
            <g>
              <path
                  d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z'
                  fill='black'/>
            </g>
          </svg>
          <select v-model="patient.status" id="status"
                  class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            <option v-for="status in this.statuses" v-bind:value="status.status" v-bind:key="status.status">
              {{ status.status }}
            </option>
          </select>
        </div>
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="first_name">
          First name <span class="required">*</span>
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="first_name"
            type="text"
            placeholder=""
            v-model="patient.first_name"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="middle_name">
          Middle name
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="middle_name"
            type="text"
            placeholder=""
            v-model="patient.middle_name"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="last_name">
          Last name
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="last_name"
            type="text"
            placeholder=""
            v-model="patient.last_name"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
          Email address
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            placeholder=""
            v-model="patient.email"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="Dob">
          Date of birth
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="Dob"
            type="date"
            placeholder=""
            v-model="patient.dob"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="Timezone">
          Timezone
        </label>
        <el-select v-model="patient.timezone" class="w-full" filterable>
          <el-option value="Pacific/Honolulu" label="Pacific/Honolulu GMT-10:00" />
          <el-option value="America/Anchorage" label="America/Anchorage GMT-9:00" />
          <el-option value="America/Los_Angeles" label="America/Los_Angeles GMT-8:00" />
          <el-option value="America/Boise" label="America/Boise GMT-7:00" />
          <el-option value="America/Denver" label="America/Denver GMT-7:00" />
          <el-option value="America/Phoenix" label="America/Phoenix GMT-7:00" />
          <el-option value="America/Chicago" label="America/Chicago GMT-6:00" />
          <el-option value="America/Detroit" label="America/Detroit GMT-5:00" />
          <el-option value="America/New_York" label="America/New_York GMT-5:00" />
        </el-select>
      </div>
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="address1">
        Address
      </label>
      <input
          class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="address1"
          type="text"
          placeholder=""
          v-model="patient.address1"
      />
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="address2">
        Address 2
      </label>
      <input
          class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="address2"
          type="text"
          placeholder=""
          v-model="patient.address2"
      />
    </div>
    <div class="grid grid-cols-2">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="city">
          City
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="city"
            type="text"
            placeholder=""
            v-model="patient.city"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="state">
          State <span class="required">*</span>
        </label>
        <el-select v-model="patient.state" class="w-full" filterable>
          <el-option value="AL" label="Alabama" />
          <el-option value="AK" label="Alaska" />
          <el-option value="AZ" label="Arizona" />
          <el-option value="AR" label="Arkansas" />
          <el-option value="CA" label="California" />
          <el-option value="CO" label="Colorado" />
          <el-option value="CT" label="Connecticut" />
          <el-option value="DE" label="Delaware" />
          <el-option value="DC" label="District Of Columbia" />
          <el-option value="FL" label="Florida" />
          <el-option value="GA" label="Georgia" />
          <el-option value="HI" label="Hawaii" />
          <el-option value="ID" label="Idaho" />
          <el-option value="IL" label="Illinois" />
          <el-option value="IN" label="Indiana" />
          <el-option value="IA" label="Iowa" />
          <el-option value="KS" label="Kansas" />
          <el-option value="KY" label="Kentucky" />
          <el-option value="LA" label="Louisiana" />
          <el-option value="ME" label="Maine" />
          <el-option value="MD" label="Maryland" />
          <el-option value="MA" label="Massachusetts" />
          <el-option value="MI" label="Michigan" />
          <el-option value="MN" label="Minnesota" />
          <el-option value="MS" label="Mississippi" />
          <el-option value="MO" label="Missouri" />
          <el-option value="MT" label="Montana" />
          <el-option value="NE" label="Nebraska" />
          <el-option value="NV" label="Nevada" />
          <el-option value="NH" label="New Hampshire" />
          <el-option value="NJ" label="New Jersey" />
          <el-option value="NM" label="New Mexico" />
          <el-option value="NY" label="New York" />
          <el-option value="NC" label="North Carolina" />
          <el-option value="ND" label="North Dakota" />
          <el-option value="OH" label="Ohio" />
          <el-option value="OK" label="Oklahoma" />
          <el-option value="OR" label="Oregon" />
          <el-option value="PA" label="Pennsylvania" />
          <el-option value="RI" label="Rhode Island" />
          <el-option value="SC" label="South Carolina" />
          <el-option value="SD" label="South Dakota" />
          <el-option value="TN" label="Tennessee" />
          <el-option value="TX" label="Texas" />
          <el-option value="UT" label="Utah" />
          <el-option value="VT" label="Vermont" />
          <el-option value="VA" label="Virginia" />
          <el-option value="WA" label="Washington" />
          <el-option value="WV" label="West Virginia" />
          <el-option value="WI" label="Wisconsin" />
          <el-option value="WY" label="Wyoming" />
        </el-select>
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="zip_code">
          Zip Code
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="zip_code"
            type="text"
            placeholder=""
            v-model="patient.zip"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="phone">
          Phone
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="phone"
            type="text"
            placeholder=""
            v-model="patient.phone"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="cell">
          Cell
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="cell"
            type="text"
            placeholder=""
            v-model="patient.cell"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="sex">
          Sex
        </label>
        <div class="select__arrow-container">
          <svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg' style="pointer-events: none;">
            <g>
              <path
                  d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z'
                  fill='black'/>
            </g>
          </svg>

          <select v-model="patient.sex" id="sex"
                  class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            <option value="m" selected>Male</option>
            <option value="f">Female</option>
          </select>
        </div>
      </div>
    </div>
    <hr class="mb-4"/>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2"> Insurance Info: </label>
    </div>
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="member_id">
        Payer
      </label>
      <div class="select__arrow-container">

        <el-select filterable clearable v-model="patient.payer_id" id="payer_id">
          <el-option v-for="payer in this.payers" :value="payer.id" :key="payer.id" :label="payer.name"></el-option>
        </el-select>
      </div>
    </div>

    <div class="grid grid-cols-2">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="member_id">
          Member ID
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="member_id"
            type="text"
            placeholder=""
            v-model="patient.member_id"
        />

      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="member_group_id">
          Member Group ID / Medical Insurance Card
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="city"
            type="text"
            placeholder=""
            v-model="patient.member_group_id"
        />

      </div>
    </div>
    <div class="grid grid-cols-1 gap-4">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="member_id">
          Patient Tags
        </label>
        <Tags :authUser="authUser" :patient="patient" :patient-tags="authUser.patient_tags" />
      </div>
    </div>

    <Collapse :text="'Emergency Contact'">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="emergency_contact.first_name">
          First name
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="emergency_contact.first_name"
            type="text"
            v-model="patient.emergency_contact.first_name"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="emergency_contact.last_name">
          Last name
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="emergency_contact.last_name"
            type="text"
            v-model="patient.emergency_contact.last_name"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="emergency_contact.email">
          Email address
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="emergency_contact.email"
            type="text"
            v-model="patient.emergency_contact.email"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="emergency_contact.phone">
          Phone
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="emergency_contact.phone"
            type="text"
            v-model="patient.emergency_contact.phone"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="emergency_contact.cell">
          Cell
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="emergency_contact.cell"
            type="text"
            v-model="patient.emergency_contact.cell"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="emergency_contact.address1">
          Address
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="emergency_contact.address1"
            type="text"
            v-model="patient.emergency_contact.address1"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="emergency_contact.address2">
          Address 2
        </label>
        <input
            class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="emergency_contact.address2"
            type="text"
            v-model="patient.emergency_contact.address2"
        />
      </div>
      <div class="grid grid-cols-2">
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="emergency_contact.city">
            City
          </label>
          <input
              class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="emergency_contact.city"
              type="text"
              v-model="patient.emergency_contact.city"
          />
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="emergency_contact.state">
            State
          </label>
          <el-select v-model="patient.emergency_contact.state" class="w-full" filterable>
            <el-option value="AL" label="Alabama" />
            <el-option value="AK" label="Alaska" />
            <el-option value="AZ" label="Arizona" />
            <el-option value="AR" label="Arkansas" />
            <el-option value="CA" label="California" />
            <el-option value="CO" label="Colorado" />
            <el-option value="CT" label="Connecticut" />
            <el-option value="DE" label="Delaware" />
            <el-option value="DC" label="District Of Columbia" />
            <el-option value="FL" label="Florida" />
            <el-option value="GA" label="Georgia" />
            <el-option value="HI" label="Hawaii" />
            <el-option value="ID" label="Idaho" />
            <el-option value="IL" label="Illinois" />
            <el-option value="IN" label="Indiana" />
            <el-option value="IA" label="Iowa" />
            <el-option value="KS" label="Kansas" />
            <el-option value="KY" label="Kentucky" />
            <el-option value="LA" label="Louisiana" />
            <el-option value="ME" label="Maine" />
            <el-option value="MD" label="Maryland" />
            <el-option value="MA" label="Massachusetts" />
            <el-option value="MI" label="Michigan" />
            <el-option value="MN" label="Minnesota" />
            <el-option value="MS" label="Mississippi" />
            <el-option value="MO" label="Missouri" />
            <el-option value="MT" label="Montana" />
            <el-option value="NE" label="Nebraska" />
            <el-option value="NV" label="Nevada" />
            <el-option value="NH" label="New Hampshire" />
            <el-option value="NJ" label="New Jersey" />
            <el-option value="NM" label="New Mexico" />
            <el-option value="NY" label="New York" />
            <el-option value="NC" label="North Carolina" />
            <el-option value="ND" label="North Dakota" />
            <el-option value="OH" label="Ohio" />
            <el-option value="OK" label="Oklahoma" />
            <el-option value="OR" label="Oregon" />
            <el-option value="PA" label="Pennsylvania" />
            <el-option value="RI" label="Rhode Island" />
            <el-option value="SC" label="South Carolina" />
            <el-option value="SD" label="South Dakota" />
            <el-option value="TN" label="Tennessee" />
            <el-option value="TX" label="Texas" />
            <el-option value="UT" label="Utah" />
            <el-option value="VT" label="Vermont" />
            <el-option value="VA" label="Virginia" />
            <el-option value="WA" label="Washington" />
            <el-option value="WV" label="West Virginia" />
            <el-option value="WI" label="Wisconsin" />
            <el-option value="WY" label="Wyoming" />
          </el-select>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="emergency_contact.zip">
            Zip Code
          </label>
          <input
              class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="emergency_contact.zip"
              type="text"
              v-model="patient.emergency_contact.zip"
          />
        </div>
      </div>
    </Collapse>
  </div>
</template>
<script>

import Tags from "@/components/Tags.vue";
import Collapse from "@/components/Collapse.vue";

export default {
  name: 'Patient',
  components: {Tags, Collapse},
  props: {
    authUser: {},
    patient: {},
    statuses: null,
    hide_insurance: false,
    payers: {
      type: Array,
      required: true
    }
  },
  watch: {
  'patient.payer_id': function (newVal) {
      if (newVal === '') {
        this.patient.payer_id = null;
      }
    }
  }
}
</script>
<style scoped>


.ico__actionPanel a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #292929;
  padding: 10px 0px;
}

.select__arrow-container {
  position: relative;
  margin-left: 0px !important;
}

.select__arrow-container svg {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translate(0, -50%);
  height: 12px;
  width: 12px;
  z-index: 999;
}

.btn__back-container svg {
  top: -2px;
  position: relative;
  margin-right: 5px;
}

.table__modify tr th div {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  text-transform: capitalize;
  align-items: center;
  color: #292929;
  white-space: nowrap;
}

.table__modify tr th div svg {
  color: #9a9a9a;
}

.table__modify tbody tr {
  font-family: "Lato";
  font-style: normal;
  color: #292929;
}

.grid-cols-2 div:nth-child(odd) {
  margin-right: 12px;
}

.grid-cols-2 div:nth-child(even) {
  margin-left: 12px;
}

.dialog__body label {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #292929;
}

.panel__agreement .txt__location strong {
  font-weight: 700;
}

.input__registerPatient {
  background: #eeeeee;
  color: #292929;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

@media (max-width: 575.98px) {

}
</style>