<template>
    <div class="bg-white rounded-lg mt-3 p-4">
        <div v-if="!isNotes">
          <el-button type="primary" @click="() => startTaskModal = true">Add Task</el-button>
        </div>

        <v-data-table :headers="dtheaders" :items="dttasks" sort-by="created_at" sort-desc>
          <template v-slot:[`item.description`]="{ item }">
            <task-edit class="inline" :health-coaches="healthCoaches" :is-notes="isNotes" :task="item" :patient="patient" v-on:get-patient-details="$emit('get-patient-details')"/>{{ item.description }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span class="uppercase text-xs font-medium tracking-tight text-white px-1.5 py-0.5 rounded-full"
                  v-bind:class="{ 'bg-blue-500': item.status === 'open', 'bg-green-500': item.status === 'closed', 'bg-yellow-500': item.status === 'in_progress' }">
                {{ item.status }}
            </span>
          </template>
          <template v-slot:[`item.category`]="{ item }">
            <span class="uppercase text-xs font-medium tracking-tight text-white px-1.5 py-0.5 rounded-full"
                  v-bind:class="{ 'bg-blue-500': item.category === 'rpm', 'bg-orange-500': item.category === 'ccm' }">
                {{ item.category }}
            </span>
          </template>
          <template v-slot:[`item.duration`]="{ item }">
            {{ item.duration | duration }}
          </template>
          <template v-slot:[`item.note`]="{ item }">
            <button v-if="item.note" class="text-blue-500 underline" @click="setSelectedTask(item)">
              See Note
            </button>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <p>{{ item.created_at | datetime}}</p>
          </template>
          <template v-if="!isNotes" v-slot:[`item.due_date`]="{ item }">
            <p>{{ item.due_date | dateNonLocal }}</p>
          </template>
        </v-data-table>

        <el-dialog :close-on-click-modal="false" title="Notes" :visible.sync="showTaskNotesModal" @close="closeTaskNotesModal">
            <div v-if="selectedTask">
                <p class="whitespace-pre-wrap">{{ selectedTask.note }}</p>
                <Collapse  text="Sms Messages" class="mb-4">
                  <div class="mb-4">
                    <message-list :messages="patient.sms.filter(s => s.tasks_id === selectedTask.id)" :patient="patient" />
                  </div>
                </Collapse>
            </div>
        </el-dialog>

      <el-dialog :close-on-click-modal="false" title="Start Task" :visible.sync="startTaskModal" width="50%"
                 @close="() => (this.startTaskModal = false)">
        <div ref="startTaskModalBody" class="dialog__body">
          <RpmDashboardErrors v-bind:errors="startTaskErrors" v-bind:title="'Start Task Errors'" />
          <div class="grid gap-4">
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="select_task">
                Select Task Type
              </label>
              <select id="select_task" v-model="selectedTaskType"
                      class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                <option v-for="taskType in taskTypes" v-bind:value="taskType" v-bind:key="taskType">
                  {{ taskType }}
                </option>
              </select>
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="select_assigned_user"> Assigned User </label>
              <el-select v-model="selectedAssignedUser" value-key="id" clearable placeholder="Health Coach" filterable>
                <el-option v-for="health_coach in healthCoaches" :value="health_coach" :label="`${health_coach.first_name} ${health_coach.last_name}`" :key="health_coach.id" ></el-option>
              </el-select>
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="notes">
                Description <span class="required">*</span>
              </label>
              <input v-model="taskDescription"
                     class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                     id="notes" placeholder="" />
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="due_date">
                Due Date
              </label>
              <input v-model="taskDueDate" type="date"
                     class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                     id="due_date" />
            </div>
          </div>
        </div>
        <span slot="footer" class="josefin dialog-footer">
                <el-button @click="createTask" type="primary" :disabled="loading">Start Task</el-button>
                <el-button @click="() => (this.startTaskModal = false)" type="cancel">Cancel</el-button>
            </span>
      </el-dialog>
    </div>
</template>

<script>
import {mapState} from "vuex";
import TaskEdit from "@/views/rpm/patient-info/components/TaskEdit.vue";
import RpmDashboardErrors from "@/components/RpmDashboardErrors.vue";
import Vue from "vue";
import MessageList from "@/views/MessageList.vue";
import Collapse from "@/components/Collapse.vue";

export default {
  components: {MessageList, RpmDashboardErrors, TaskEdit, Collapse},
    props: {
        isNotes: Boolean, // Notes (in progress, closed) vs Tasks (open, optional due date)
        patient: Object,
    },
    data() {
        return {
          accessToken: null,
          selectedTask: null,
          startTaskModal: false,
          selectedTaskType: null,
          selectedAssignedUser: null,
          taskDescription: "",
          taskDueDate: null,
          startTaskErrors: [],
          loading: false,
          taskTypes: ["ccm", "rpm"],
        };
    },
    async created() {
      this.accessToken = await this.$auth.getTokenSilently();
      this.selectedAssignedUser = this.healthCoaches.find(hc => hc.id === this.patients.authUser.id);
    },
    computed: {
        ...mapState(["patients"]),
      dtheaders() {
        let headers = [
          {text: 'Description', value: 'description'},
          {text: 'Category', value: 'category'},
          {text: 'Status', value: 'status'},
          {text: 'Duration', value: 'duration'},
          {text: 'Note', value: 'note'},
          {text: 'Due Date', value: 'due_date'},
          {text: 'Assigned To', value: 'assigned_to'},
          {text: 'Created By', value: 'created_by'},
          {text: 'Created At', value: 'created_at'},
        ];

        if (this.isNotes) {
          headers = headers.filter((h) => h.value !== 'due_date');
        }

        return headers;
      },
      dttasks() {
        return this.patient.tasks.map((task) => ({
          ...task,
          'created_by': this.getProviderName(task.creator_id),
          'assigned_to': this.getProviderName(task.assigned_id),
        })).filter((task) => this.isNotes ? task.status !== 'open' : task.status === 'open');
      },
      showTaskNotesModal: {
        get() {
          return this.selectedTask !== null;
        },
        set(value) {
          if (!value) {
            this.selectedTask = null;
          }
        },
      },
      healthCoaches() {
        return this.$store.state.patients.providers.filter(p => p.behaviors.includes('is_health_coach') && !p.disabled);
      },
    },
  methods: {
    checkForm(required, formValuesObj) {
      return required.filter(key => !formValuesObj[key]).map(key => key.replace("_", " ") + " is required.");
    },
    checkCreateTaskForm() {
      const required = ["Description", "TaskType", "AssignedUser"];
      this.startTaskErrors = this.checkForm(required, {
        TaskType: this.selectedTaskType,
        Description: this.taskDescription,
        AssignedUser: this.selectedAssignedUser,
      });
      return !this.startTaskErrors.length;
    },
    createTask() {
      if (!this.checkCreateTaskForm()) {
        this.$nextTick(() => {
          this.$refs.startTaskModalBody.scrollIntoView();
        });
        return;
      }

      this.loading = true;

      Vue.$http.post(`/api/rpm_ccm_dashboard/create_task`, null, {params: {type: this.selectedTaskType, description: this.taskDescription, assigned_id: this.selectedAssignedUser.id, patient_id: this.patient.id, token: this.accessToken, status: 'open', due_date: this.taskDueDate},})
          .then((res) => {
            if (!res.error) {
              this.startTaskModal = false;
              this.$emit('get-patient-details');
            }
          })
          .catch(error => this.handleEhrApiErrors(error, this.startTaskErrors, this.$refs.startTaskModalBody)).finally(() => {
        this.loading = false;
      });
    },
    setSelectedTask(task) {
      if (this.selectedTask && this.selectedTask.id === task.id) {
        this.selectedTask = null;
      } else {
        this.selectedTask = task;
        this.selectedAssignedUser = this.healthCoaches.find(hc => hc.id === this.patients.authUser());
      }
    },
    closeTaskNotesModal() {
      this.showTaskNotesModal = false;
    },
    getProvider(id) {
      return this.patients.providers.find((p) => p.id === id);
    },
    getProviderName(id) {
      const p = this.getProvider(id);
      return p ? p.first_name + " " + p.last_name : "None";
    },
  },
  filters: {
    duration(value) {
      if (!value) return "";
      value = Number(value);
      const h = Math.floor(value / 60);
      const m = value % 60;
      return (h ? `${h}hr ` : "") + `${m}min`;
    },
  },
};
</script>
