<template>
  <div class="py-10 px-10 md:px-32">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
      <div
        class="border border-bordergray rounded-lg p-6 cursor-pointer w-full"
        v-for="(item, index) in appliedJobs"
        :key="index"
        @click="showJobDescription = true"
        >
        <div class="flex justify-between items-center">
          <h6 class="font-bold text-black">{{ item.job_type }}</h6>
          <p class="font-normal text-maingrey text-sm">{{ item.date }}</p>
        </div>
        <div class="font-normal text-maingrey text-sm flex items-center pt-2">
          <span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.3334 4.6665H2.66671C1.93033 4.6665 1.33337 5.26346 1.33337 5.99984V12.6665C1.33337 13.4029 1.93033 13.9998 2.66671 13.9998H13.3334C14.0698 13.9998 14.6667 13.4029 14.6667 12.6665V5.99984C14.6667 5.26346 14.0698 4.6665 13.3334 4.6665Z" stroke="#9A9A9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.6667 14V3.33333C10.6667 2.97971 10.5262 2.64057 10.2762 2.39052C10.0261 2.14048 9.687 2 9.33337 2H6.66671C6.31309 2 5.97395 2.14048 5.7239 2.39052C5.47385 2.64057 5.33337 2.97971 5.33337 3.33333V14" stroke="#9A9A9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
          <p class="pl-1">{{ item.category }}</p>
        </div>
        <p class="py-3 md:py-2 text-base text-maingrey font-normal">
          {{ item.description }}
        </p>
        <div class="flex justify-between flex-col md:flex-row items-start md:items-center py-2">
          <div class="flex items-center">
            <div class="text-sm font-bold px-4 py-2 rounded-full text-maingrey bg-gray-100">{{ item.field_type }}</div>
            <div class="text-sm font-bold ml-2 px-4 py-2 rounded-full text-maingrey bg-gray-100">{{ '$' + item.rate + '/hr'}}</div>
          </div>
          <div class="flex items-center bg-lightpurple px-4 py-2 rounded-full text-mainpurple">
            <span>
              <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 7.1665C13 11.8332 7 15.8332 7 15.8332C7 15.8332 1 11.8332 1 7.1665C1 5.5752 1.63214 4.04908 2.75736 2.92386C3.88258 1.79864 5.4087 1.1665 7 1.1665C8.5913 1.1665 10.1174 1.79864 11.2426 2.92386C12.3679 4.04908 13 5.5752 13 7.1665Z" stroke="#5E60CE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 9.1665C8.10457 9.1665 9 8.27107 9 7.1665C9 6.06193 8.10457 5.1665 7 5.1665C5.89543 5.1665 5 6.06193 5 7.1665C5 8.27107 5.89543 9.1665 7 9.1665Z" stroke="#5E60CE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
            <p class="pl-2 text-sm font-bold">{{ item.location }}</p>
          </div>
        </div>
      </div>
    </div>
    <job-description-modal v-if="showJobDescription" v-model="showJobDescription"></job-description-modal>
    <submit-application-modal v-if="showSubmitApplication" v-model="showSubmitApplication" @sendEmit="openModal"></submit-application-modal>
  </div>
</template>

<script>
import JobDescriptionModal from './JobDescriptionModal.vue'
import SubmitApplicationModal from './SubmitApplication.vue'

export default {
  components: {
    JobDescriptionModal,
    SubmitApplicationModal
  },
  data() {
    return {
      showJobDescription: false,
      showSubmitApplication: false,
      appliedJobs: [
        {
          job_type: 'Physician Advisor',
          date: 'Applied on Aug 11',
          category: 'MedSchoolCoach',
          description: 'We are especially interested in physicians who are retired and want...',
          field_type: 'Anesthesiology',
          rate: 80,
          location: 'Austin, TX'
        },
        {
          job_type: 'Psychologist / Therapist (PhD, LCSW)',
          date: 'Applied on Jul 5',
          category: 'Tembo Health',
          description: 'As a Clinician in Psychology + Therapy, you will primarily be ...',
          field_type: 'Hematology, Internal Medicine',
          rate: 130,
          location: 'Manhattan, NY'
        }
      ],
    }
  },
  methods: {
    openModal() {
      this.showJobDescription = false
      this.showSubmitApplication = true
    }
  }
}
</script>

<style>

</style>