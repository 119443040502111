<template>
  <div class="py-10 px-32">
    <div class="grid grid-cols-3 gap-10">
      <div>
        <p class="font-bold pb-2">Line of business</p>
        <el-select v-model="value" style="width: 100%" placeholder="Specify line of business">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div>
        <p class="font-bold pb-2">Rates</p>
        <el-select v-model="value" style="width: 100%" placeholder="Specify preferred rates">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div>
        <p class="font-bold pb-2">Specialty/Subspecialty</p>
        <el-select v-model="value" style="width: 100%" placeholder="Select specialty/subspecialty">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <p class="py-2 lato">Please note that jobs are displayed based on your already set availability. Check your calendar to <span class="text-blue font-bold cursor-pointer" @click="$router.push('/calendar')">update availability</span>.</p>
    <div class="py-5">
      <div class="flex justify-between items-center">
        <p class="font-bold">Showing {{ profile.jobs.length }} jobs</p>
        <div class="flex items-center">
          <p class="uppercase">sort by: <span class="font-bold">most relevant</span></p>
          <span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.3333 6.1133C11.2084 5.98913 11.0395 5.91943 10.8633 5.91943C10.6872 5.91943 10.5182 5.98913 10.3933 6.1133L8.00001 8.4733L5.64001 6.1133C5.5151 5.98913 5.34613 5.91943 5.17001 5.91943C4.99388 5.91943 4.82491 5.98913 4.70001 6.1133C4.63752 6.17527 4.58792 6.249 4.55408 6.33024C4.52023 6.41148 4.50281 6.49862 4.50281 6.58663C4.50281 6.67464 4.52023 6.76177 4.55408 6.84301C4.58792 6.92425 4.63752 6.99799 4.70001 7.05996L7.52667 9.88663C7.58865 9.94911 7.66238 9.99871 7.74362 10.0326C7.82486 10.0664 7.912 10.0838 8.00001 10.0838C8.08801 10.0838 8.17515 10.0664 8.25639 10.0326C8.33763 9.99871 8.41136 9.94911 8.47334 9.88663L11.3333 7.05996C11.3958 6.99799 11.4454 6.92425 11.4793 6.84301C11.5131 6.76177 11.5305 6.67464 11.5305 6.58663C11.5305 6.49862 11.5131 6.41148 11.4793 6.33024C11.4454 6.249 11.3958 6.17527 11.3333 6.1133Z" fill="#292929"/>
            </svg>
          </span>
        </div>
      </div>
      <div class="py-6 grid grid-cols-2 gap-10">
        <div
          class="border border-bordergray rounded-lg p-6 cursor-pointer"
          v-for="(item, index) in profile.jobs"
          :key="index"
          @click="showJobDescription = true"
          >
          <div class="flex justify-between items-center">
            <h6 class="font-bold text-black">{{ item.job_title }}</h6>
            <p class="font-normal text-maingrey text-sm">{{ item.start_date }}</p>
          </div>
          <!-- <div class="font-normal text-maingrey text-sm flex items-center pt-2">
            <span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3334 4.6665H2.66671C1.93033 4.6665 1.33337 5.26346 1.33337 5.99984V12.6665C1.33337 13.4029 1.93033 13.9998 2.66671 13.9998H13.3334C14.0698 13.9998 14.6667 13.4029 14.6667 12.6665V5.99984C14.6667 5.26346 14.0698 4.6665 13.3334 4.6665Z" stroke="#9A9A9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.6667 14V3.33333C10.6667 2.97971 10.5262 2.64057 10.2762 2.39052C10.0261 2.14048 9.687 2 9.33337 2H6.66671C6.31309 2 5.97395 2.14048 5.7239 2.39052C5.47385 2.64057 5.33337 2.97971 5.33337 3.33333V14" stroke="#9A9A9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
            <p class="pl-1">{{ item.category }}</p>
          </div> -->
          <p v-html="truncateStr(item.job_description)" class="py-2 text-base text-maingrey font-normal">
          </p>
          <div class="flex justify-between items-center py-2">
            <div class="flex items-center">
              <!-- <div class="text-sm font-bold px-4 py-2 rounded-full text-maingrey bg-gray-100">{{ item.field_type }}</div> -->
              <div class="text-sm font-bold px-4 py-2 rounded-full text-maingrey bg-gray-100">{{ '$' + item.hourly_rate + '/hr'}}</div>
            </div>
            <div class="flex items-center bg-lightpurple px-4 py-2 rounded-full text-mainpurple">
              <span>
                <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 7.1665C13 11.8332 7 15.8332 7 15.8332C7 15.8332 1 11.8332 1 7.1665C1 5.5752 1.63214 4.04908 2.75736 2.92386C3.88258 1.79864 5.4087 1.1665 7 1.1665C8.5913 1.1665 10.1174 1.79864 11.2426 2.92386C12.3679 4.04908 13 5.5752 13 7.1665Z" stroke="#5E60CE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7 9.1665C8.10457 9.1665 9 8.27107 9 7.1665C9 6.06193 8.10457 5.1665 7 5.1665C5.89543 5.1665 5 6.06193 5 7.1665C5 8.27107 5.89543 9.1665 7 9.1665Z" stroke="#5E60CE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
              <p class="pl-2 text-sm font-bold">{{ item.timezone }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      value: '',
      // appliedJobs: [
      //   {
      //     job_type: 'Physician Advisor',
      //     date: 'Applied on Aug 11',
      //     category: 'MedSchoolCoach',
      //     description: 'We are especially interested in physicians who are retired and want...',
      //     field_type: 'Anesthesiology',
      //     rate: 80,
      //     location: 'Austin, TX'
      //   },
      //   {
      //     job_type: 'Psychologist / Therapist (PhD, LCSW)',
      //     date: 'Applied on Jul 5',
      //     category: 'Tembo Health',
      //     description: 'As a Clinician in Psychology + Therapy, you will primarily be ...',
      //     field_type: 'Hematology, Internal Medicine',
      //     rate: 130,
      //     location: 'Manhattan, NY'
      //   },
      //   {
      //     job_type: 'Psychologist / Therapist (PhD, LCSW)',
      //     date: 'Applied on Jul 5',
      //     category: 'Tembo Health',
      //     description: 'As a Clinician in Psychology + Therapy, you will primarily be ...',
      //     field_type: 'Hematology, Internal Medicine',
      //     rate: 130,
      //     location: 'Manhattan, NY'
      //   },
      //   {
      //     job_type: 'Psychologist / Therapist (PhD, LCSW)',
      //     date: 'Applied on Jul 5',
      //     category: 'Tembo Health',
      //     description: 'As a Clinician in Psychology + Therapy, you will primarily be ...',
      //     field_type: 'Hematology, Internal Medicine',
      //     rate: 130,
      //     location: 'Manhattan, NY'
      //   }
      // ],
      options: [{
          value: 'Option1',
          label: 'Option1'
        }, {
          value: 'Option2',
          label: 'Option2'
        }, {
          value: 'Option3',
          label: 'Option3'
        }, {
          value: 'Option4',
          label: 'Option4'
        }, {
          value: 'Option5',
          label: 'Option5'
        }],
    }
  },
  computed: {
    ...mapState(['profile'])
  },
  methods: {
    ...mapActions(['fetchAvailableJobs']),
    truncateStr(string) {
      return string.substring(0, 250) + '...'
    },
  },
  mounted() {
    this.fetchAvailableJobs()
  }
}
</script>

<style>
.el-tabs__nav-scroll {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.el-tabs__active-bar {
  border-radius: 24px 24px 0px 0px;
  height: 5px;
}

.el-tabs__item.is-active {
  color: #222;
  font-weight: 700;
}

.el-tabs__item {
  color: #9A9A9A;
}
.text-blue {
  color:#48BFE3 !important;
}
</style>