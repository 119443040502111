import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VCalendar from 'v-calendar';
import { Auth0Plugin } from "./auth";
import store from "./store";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { domain, clientId } from "../auth_config.json";
import appPlugins from './helpers/plugins'
import VueAWN from "vue-awesome-notifications"
import 'vue-awesome-notifications/dist/styles/style.css'
import vuetify from '@/helpers/vuetify'

import {
  Dialog,
  Divider,
  Button,
  Form,
  FormItem,
  Input,
  Link,
  Loading,
  Tabs,
  Tag,
  TabPane,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  DatePicker,
  Select,
  TimeSelect,
  Table,
  TableColumn,
  Option,
  Pagination,
  Popover,
  Tooltip,
  Notification,
} from 'element-ui'

import Cloudinary, { CldImage, CldTransformation, CldPlaceholder } from "cloudinary-vue";

import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

import './assets/css/app.css'
import './assets/css/tailwind.css'
import './assets/css/datatables.css'
import moment from "moment-timezone";
import 'element-ui/lib/theme-chalk/index.css';


Vue.config.productionTip = false;

// configure language
locale.use(lang)

Vue.prototype.$notify = Notification

Vue.use(Cloudinary, {
  configuration: { cloudName: "vitel-health" },
  components: {
    CldImage,
    CldTransformation,
    CldPlaceholder,
  }
})

Vue.use(appPlugins)

Vue.use(Dialog);
Vue.use(Divider);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Link);
Vue.use(Input);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(DatePicker);
Vue.use(Select);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Option);
Vue.use(Pagination);
Vue.use(Popover);
Vue.use(Tag)
Vue.use(Tooltip)
Vue.use(TimeSelect);

Vue.use(Loading.directive);

Vue.use(VueAWN);

Vue.use(VCalendar, {
  componentPrefix: 'vc'
});

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.prototype.Hereanyname = window.hereanyname;

Vue.filter('date', function (date) {
  return date && moment(date).isValid() ? moment.utc(date).local().format("MMM D, YYYY") : ''
});

Vue.filter('dateNonLocal', function (date) {
  return date && moment(date).isValid() ? moment.utc(date).format("MMM D, YYYY") : ''
});

Vue.filter('dob', function (date) {
  return date && moment(date).isValid() ? moment(date).format("MMM D, YYYY") : ''
});
Vue.filter('datetime', function (date) {
  return date && moment(date).isValid() ? moment.utc(date).local().format("MMM D, YYYY h:mm a") : ''
});

Vue.mixin({
  methods: {
    handleEhrApiErrors(errorResponse, vueErrors, ref) {
      let response = errorResponse.response.data;
      if (response.errors && response.errors.length > 0) {
        for (const key in response.errors) {
          if (response.errors.hasOwnProperty(key)) {
            for (const fieldKey in response.errors[key]) {
              if (response.errors[key].hasOwnProperty(fieldKey)) {
                vueErrors.push(key + ": " + response.errors[key][fieldKey]);
              }
            }
          }
        }
      } else {
        vueErrors.push(response.message);
      }
      this.$nextTick(() => {
        if (ref) ref.scrollIntoView();
      });
    },
  },
});

Vue.filter('fill', function (value) {
  return value ? value : '--'
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
