<template>
  <div class="relative m-auto chart">
    <chart :data="data" :options="options" :height="200"/>
  </div>
</template>

<script>
import α from "color-alpha";
import Chart from "../../../../components/Chart.vue";
import vitalsHelper from "../../../../helpers/vitals";

export default {
  components: {
    Chart,
  },
  props: {
    vitalReadings: Array,
    alerts: Array,
    unit: '',
    attribute: '',
  },
  computed: {
    data() {
      return vitalsHelper.getChartjsData(this.vitalReadings, this.attribute);
    },
    options() {
      var options = vitalsHelper.getChartjsOptions(this.unit);
      const vitalReadings = this.vitalReadings;
      const attribute = this.attribute;
      return {
        ...options,
        responsive: true,
        maintainAspectRatio: false,
        annotation: this.getAnnotation(),
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              if (attribute === 'blood_pressure') {
                // Vital readings are in same order as tooltip items
                const bp = vitalReadings[tooltipItem.index].value;
                return `${tooltipItem.value} (${bp})`;
              } else {
                return `${tooltipItem.value}`;
              }
            },
          }
        }
      };
    },
  },
  methods: {
    getAnnotation() {
      let annotations = [];
      this.alerts.forEach((alert, index) => {
        const color = vitalsHelper.getAttributeColor(alert.type);
        annotations = [
          ...annotations,
          {
            id: `line-1${index}`,
            type: "line",
            drawTime: "afterDraw",
            mode: "horizontal",
            scaleID: "y-axis-0",
            value: alert.min,
            borderColor: α(color, 0.3),
            borderWidth: 1,
          },
          {
            id: `line-2${index}`,
            type: "line",
            drawTime: "afterDraw",
            mode: "horizontal",
            scaleID: "y-axis-0",
            value: alert.max,
            borderColor: α(color, 0.3),
            borderWidth: 1,
          },
          {
            id: `box-1${index}`,
            type: "box",
            drawTime: "beforeDatasetsDraw",
            yScaleID: "y-axis-0",
            yMin: alert.min,
            yMax: alert.max,
            backgroundColor: α(color, 0.15),
          },
        ];
      });
      return {annotations};
    },
  },
};
</script>

<style scoped>
.chart {
  width: 60vw;
}

@media (max-width: 1023px) {
  .chart {
    width: 75vw;
  }
}

@media (max-width: 767px) {
  .chart {
    width: 70vw;
  }
}

@media (max-width: 639px) {
  .chart {
    width: 60vw;
  }
}
</style>