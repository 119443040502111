<template>
  <main>
    <div class="border border-bordergray rounded-lg p-6">
      <div class="flex items-center text-xs Lato" style="color: #9A9A9A">
        <div class="flex items-center">
          <span>
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.6667 2.6665H2.33333C1.59695 2.6665 1 3.26346 1 3.99984V13.3332C1 14.0696 1.59695 14.6665 2.33333 14.6665H11.6667C12.403 14.6665 13 14.0696 13 13.3332V3.99984C13 3.26346 12.403 2.6665 11.6667 2.6665Z" stroke="#9A9A9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.66663 1.33325V3.99992" stroke="#9A9A9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.33337 1.33325V3.99992" stroke="#9A9A9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1 6.6665H13" stroke="#9A9A9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
          <span class="ml-1">{{ event.date }}</span>
        </div>
         <div class="flex items-center ml-4">
          <span>
           <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6818 14.6667 7.99992C14.6667 4.31802 11.6819 1.33325 8.00004 1.33325C4.31814 1.33325 1.33337 4.31802 1.33337 7.99992C1.33337 11.6818 4.31814 14.6666 8.00004 14.6666Z" stroke="#9A9A9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 4V8L10.6667 9.33333" stroke="#9A9A9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
           </svg>
          </span>
          <span class="ml-1">{{ event.time }}</span>
        </div>
      </div>
      <h3 class="font-medium josefin_font pt-4 pb-2">{{ event.title }}</h3>
      <p style="color: #9A9A9A" class="pb-1">{{ truncate(event.description, 80) }}</p>
      <p style="color: #9A9A9A"><span class="font-bold">Speakers:</span> {{ event.speakers.join(', ').toString() }}</p>
      <div class="pt-10 flex justify-between items-center">
        <div class="flex items-center">
          <span
            class="text-xs font-bold px-3 py-2 rounded-2xl"
            style="background: #F6F6F7; color: #9A9A9A"
          >
            Duration: {{ event.duration }}mins
          </span>
          <span
            v-if="event.featured === true"
            class="text-xs font-bold px-3 py-2 rounded-2xl uppercase flex ml-2"
            style="background: #F2F3FF; color: #5E60CE"
          >
           <span>
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00004 1.83325L10.06 6.00659L14.6667 6.67992L11.3334 9.92659L12.12 14.5133L8.00004 12.3466L3.88004 14.5133L4.66671 9.92659L1.33337 6.67992L5.94004 6.00659L8.00004 1.83325Z" fill="#5E60CE" stroke="#5E60CE" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
           </span>
           <span class="ml-2">Featured</span>
          </span>
        </div>
        <div>
          <span @click="register" class="cursor-pointer" style="color: #48BFE3">
            Register now <span class="ml-1 mt-0.5">></span>
          </span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    event: {
      type: Object
    }
  },
  mounted() {

  },
  methods: {
    truncate(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },
    register() {
      window.open(this.event.registration_link, "_blank")
    }
  }

}
</script>

<style>

</style>