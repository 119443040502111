<template>
  <rpm-layout>
    <div class="flex justify-between items-center bg-white rounded-lg p-2 mb-2 space-x-1">
      <div class="relative w-1/4">
        <RpmSearch v-model="search"></RpmSearch>
      </div>
      <div>
        <el-select v-model="client" value-key="id" clearable placeholder="Client" filterable>
          <el-option v-for="client in clients" :value="client" :label="client.name" :key="client.id" ></el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="health_coach" value-key="id" clearable placeholder="Health Coach" filterable>
          <el-option v-for="health_coach in healthCoaches" :value="health_coach" :label="`${health_coach.first_name} ${health_coach.last_name}`" :key="health_coach.id" ></el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="pcp" value-key="id" clearable placeholder="PCP" filterable>
          <el-option v-for="pcp in pcps" :value="pcp" :label="`${pcp.first_name} ${pcp.last_name}`" :key="pcp.id" ></el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="status" clearable placeholder="Status">
          <el-option v-for="status in statuses" :value="status.status" :key="status.status">
            <el-tooltip :content="status.description" placement="top">
              <span>{{status.status}}</span>
            </el-tooltip>
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button type="default" @click="refresh">Refresh</el-button>
      </div>
    </div>
    <div class="relative overflow-x-auto sm:rounded-lg">
      <v-data-table :loading="loading" :headers="dtheaders" :items="dtpatients" :search="search">
        <template v-slot:loading><v-progress-circular indeterminate></v-progress-circular></template>
        <template v-slot:[`item.enrolled`]="{ item }">
          {{ item.created_at | date }}
        </template>
      </v-data-table>
    </div>
  </rpm-layout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RpmLayout from '../../layouts/RpmLayout.vue';
import RpmSearch from "@/components/RpmSearch.vue";
import PatientMixin from "@/mixins/Patient";
import Template from "@/mixins/Template";
import statuses from "@/helpers/patientStatuses";

export default {
  mixins: [PatientMixin],
  components: {Template, RpmSearch, RpmLayout },
  data() {
    return {
      search: "",
      loading: true,
      client: null,
      pcp: null,
      health_coach: null,
      status: 'active',
      statuses: statuses,
    };
  },
  created: async function () {
    Promise.all([
      !this.$store.state.patients.authUser.id ? this.$store.dispatch('getAuthUser').then(this.resetFilters) : null,
      !this.$store.state.patients.patients.length ? this.$store.dispatch('getPatients') : null,
      !this.$store.state.patients.providers.length ? this.$store.dispatch('getProviders') : null,
    ]).finally(() => {
      this.loading = false;
      this.resetFilters();
    });
  },
  computed: {
    ...mapState(["patients"]),
    authUser() {
      return this.$store.state.patients.authUser
    },
    healthCoaches() {
      return this.$store.state.patients.providers.filter(p => p.behaviors.includes('is_health_coach') && !p.disabled);
    },
    pcps() {
      return this.$store.state.patients.providers.filter(p => p.behaviors.includes('is_provider') && !p.disabled);
    },
    clients() {
      return this.$store.state.patients.authUser.available_clients;
    },
    dtheaders() {
      return [
        {text: 'Patient Name', value: 'name'},
        {text: 'Client', value: 'client'},
        {text: 'PCP', value: 'pcp'},
        {text: 'Health Coach', value: 'health_coach'},
        {text: 'Days with Data', value: 'days_with_data'},
        {text: 'Enrolled', value: 'enrolled'},
        {text: 'Review Time This Month', value: 'review_time_this_month'},
        {text: 'BP', value: 'bp'},
        {text: 'HR/Pulse', value: 'hr_pulse'},
        {text: 'TEMP', value: 'temp'},
        {text: 'WT', value: 'wt'},
        {text: 'BMI', value: 'bmi'},
        {text: 'Steps', value: 'steps'},
        {text: 'SpO2', value: 'spo2'},
        {text: 'BG', value: 'bg'},
        {text: 'PEF/FEV', value: 'pef_fev'},
      ]
    },
    dtpatients() {
      return this.filteredRPMPatients.map((patient) => ({
        ...patient,
        name: `${patient.first_name} ${patient.last_name}`,
        pcp: patient.cache.careTeam ? this.getProviderName(patient.cache.careTeam.pcp) : "None",
        client: this.$store.getters.clientName(patient.primary_client_id),
        health_coach: patient.cache.careTeam ? this.getProviderName(patient.cache.careTeam.care_manager) : "None",
        days_with_data: patient.cache.vitals_monthly_readings_count || '--',
        review_time_this_month: patient.cache.task_duration_rpm_sum_monthly || '--',
        bp: `${this.getVitalsLatest(patient, 'systolic') || ''}/${this.getVitalsLatest(patient, 'diastolic') || ''}`,
        hr_pulse: this.getVitalsLatest(patient, 'pulse') || '--',
        temp: `${this.getVitalsLatest(patient, 'temperature') || '--'} F`,
        wt: `${this.getVitalsLatest(patient, 'weight') || '--'} lbs`,
        bmi: this.getBMI(patient) || '',
        steps: this.getVitalsLatest(patient, 'steps') || '--',
        spo2: this.getVitalsLatest(patient, 'oxy') || '--',
        bg: this.getVitalsLatest(patient, 'blood_glucose_nonfasting') || '--',
        pef_fev: this.getVitalsLatest(patient, 'perfusion_index') || '--',
        enrolled: patient.created_at,
      }));
    },
    rpmPatients: function () {
      return this.patients.patients.filter(p => p.cache && p.cache.rpm_date);
    },
    filteredRPMPatients() {
      let patients = this.rpmPatients;
      if (this.client) {
        patients = patients.filter((p) => p.primary_client_id === this.client.id);
      }
      if (this.health_coach) {
        patients = patients.filter((p) => p.cache.careTeam && p.cache.careTeam.care_manager === this.health_coach.id);
      }
      if (this.pcp) {
        patients = patients.filter((p) => p.cache.careTeam && p.cache.careTeam.pcp === this.pcp.id);
      }
      if (this.status) {
        patients = patients.filter((p) => p.status === this.status);
      }
      return patients;
    },
  },
  methods: {
    ...mapActions(["getPatients"]),
    resetFilters() {
      this.status = 'active';
      if (this.authUser.behaviors.includes('is_provider') && this.pcps.some(pcp => pcp.id === this.authUser.id)) {
        this.pcp = this.authUser;
      } else if (this.healthCoaches.some(hc => hc.id === this.authUser.id)) {
        this.health_coach = this.authUser;
      }
    },
    refresh() {
      this.loading = true;
      this.$store.dispatch('getPatients').finally(() => this.loading = false);
    },
    getVitalsLatest(patient, vital) {
      return (patient.cache && patient.cache.vitals_latest && patient.cache.vitals_latest[vital]) ? patient.cache.vitals_latest[vital].value : null;
    },
    getBMI(patient) {
      if (patient.cache && patient.cache.vitals_latest && patient.cache.vitals_latest.height && patient.cache.vitals_latest.weight) {
        const weight = patient.cache.vitals_latest.weight.value * 2.20462;
        const height = patient.cache.vitals_latest.height.value * 0.0254;
        return (weight / (height * height)).toFixed(1);
      }
      return null;
    },
    getProvider(id) {
      return this.patients.providers.find((p) => p.id === id);
    },
    getProviderName(id) {
      const p = this.getProvider(id);
      return p ? p.first_name + " " + p.last_name : "None";
    },
  },
};
</script>
