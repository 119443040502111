<template>
    <div>
        <div>
            <div class="wrapper">
                <div class="flex flex-col linear-container px-6 md:px-14 py-5 md:py-12">
                    <div class="flex items-center">
                        <img src="@/assets/white-back.svg" @click="goBack" style="cursor: pointer;" />
                        <h3 class="header-text josefin_font">Telehealth EHR Platform</h3>
                    </div>
                    <p class="py-3 m-0 text-white font-light w-auto">
                        Secure encrypted end-to-end connections and HIPAA compliant

                    </p>
                </div>
            </div>
            <div class="comp_container flex flex-col md:flex-row">
                <div class="w-full md:w-3/4">
                    <section class="improve_efficiency">

							

                        <div v-if="profile.subscriptionPlan === 'Essentials'">
							<h5 class="josefin_font sub-heading mb-4">
                                Get unlimited access to our HIPAA compliant and secure Telehealth EHR platform when upgrading to an Enhanced Membership. See your patients from anywhere at any time with our industry leading telehealth platform technology.
                            </h5>
                            <p class=" description-color--text lato">
                                Looking to add telehealth technology to your practice or becoming and independent virtual physician? When you upgrade to Enhanced, you have unlimited access to our secure telehealth EHR platform.
                            </p>
							<div class="mb-2 mt-5">
                                <a href="https://www.vitelhealth.com/contact-us" target="_blank">
                                    <button class="mt-2 green_button josefin_font">View Demo Video</button>
                                </a>
                            </div>

							<div class="mb-2">
                                <a href="">
                                    <button class="mt-2 blue_button josefin_font">EHR Platform</button>
                                </a>
                            </div>

							<div class="mb-2">
                                <a href="">
                                    <button class="mt-2 blue_button josefin_font">Test Environment</button>
                                </a>
                            </div>

							<div class="mb-2">
                                <a href="">
                                    <button class="mt-2 blue_button josefin_font">Meeting Request</button>
                                </a>
                            </div>
                        </div>

                        <div v-else-if="profile.subscriptionPlan === 'Enhanced'">
                            <div class="mb-4 mt-5">
                                <a href="https://www.vitelhealth.com/contact-us" target="_blank">
                                    <button class="mt-2 green_button josefin_font">Request a Demo</button>
                                </a>
                            </div>

                            <h5 class="josefin_font sub-heading">
                                See patients remotely, anywhere, anytime
                            </h5>
                            <p class="gray-text">
                                ViTel delivers an industry leading telehealth platform to allow you to quickly and easily begin seeing patients remotely for initial and follow-up visits. Take a patient from injury or illness to cure
                                remotely, greatly improving your patient’s access to medical services and recovery time.
                            </p>
                            <p class="gray-text pt-2.5">
                                Looking for specialty telehealth platform solutions?
                                <br />
                                Upgrading to our <span @click="goToMemberShipPage" class="membershipLink">Premier Membership Subscription</span> provides access to our full suite of specialty telehealth platforms, in addition to our general
                                health telehealth platform, including workers’ compensation, ER and Urgent Care, Skilled Nursing Facilities, RPM (Remote Patient Monitoring), CCM (Chronic Care Management), or Telepsych.
                            </p>
                        </div>

                        <div v-else-if="profile.subscriptionPlan === 'Premier'">
                            <div class="mb-4 mt-5">
                                <a href="https://www.vitelhealth.com/contact-us" target="_blank">
                                    <button class="mt-2 green_button josefin_font">Request a Demo</button>
                                </a>
                            </div>

                            <h5 class="josefin_font sub-heading">
                                See patients remotely, anywhere, anytime
                            </h5>
                            <p class="gray-text">
                                ViTel delivers an industry leading telehealth platform to allow you to quickly and easily begin seeing patients remotely for initial and follow-up visits. Take a patient from injury or illness to cure
                                remotely, greatly improving your patient’s access to medical services and recovery time.
                            </p>

                            <p class="gray-text pt-2.5">
                                Your Premier Membership Subscription provides you access to our telehealth EHR for general health telehealth platform, including workers’ compensation, ER and Urgent Care, Skilled Nursing Facilities, RPM
                                (Remote Patient Monitoring), CCM (Chronic Care Management), or Telepsych.
                            </p>
                        </div>

                        <div v-else-if="profile.subscriptionPlan === ''">
							<h5 class="josefin_font sub-heading mb-4 mt-10">
                                Get unlimited access to our HIPAA compliant and secure Telehealth EHR platform when upgrading to an Enhanced Membership. See your patients from anywhere at any time with our industry leading telehealth platform technology.
                            </h5>
                            <p class=" description-color--text lato">
                                Looking to add telehealth technology to your practice or becoming and independent virtual physician? When you upgrade to Enhanced, you have unlimited access to our secure telehealth EHR platform.
                            </p>
							<div class="mb-2 mt-5">
                                <a href="https://www.vitelhealth.com/contact-us" target="_blank">
                                    <button class="mt-2 green_button josefin_font">View Demo Video</button>
                                </a>
                            </div>

							<div class="mb-2">
                                <a href="">
                                    <button class="mt-2 blue_button josefin_font">EHR Platform</button>
                                </a>
                            </div>

							<div class="mb-2">
                                <a href="">
                                    <button class="mt-2 blue_button josefin_font">Test Environment</button>
                                </a>
                            </div>

							<div class="mb-2">
                                <a href="">
                                    <button class="mt-2 blue_button josefin_font">Meeting Request</button>
                                </a>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="w-full md:w-2/4 flex justify-center img_wrapper">
                    <img src="@/assets/telehealth.svg" alt="" class="telehealth_image" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";

    export default {
        methods: {
            goBack() {
                this.$router.go(-1);
            },
            goToMemberShipPage() {
                this.$router.push({ name: "memberships", params: { scroll: true } });
            },
        },
        computed: {
            ...mapState(["profile"]),
        },
    };
</script>

<style>

.description-color--text {
    color: #9A9A9A !important;
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 400 ;

}
    .wrapper {
        background: linear-gradient(90deg, #5e60ce 66.28%, #48bfe3 100%);
    }
    .linear-container {
        padding: 3rem 20px;
        max-width: 1440px;
        margin: auto;
        background: initial;
    }
    .comp_container {
        max-width: 1440px;
        margin: auto;
        padding: 0px 20px;
    }

    .comp_container .sub_header {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #666666;
    }
    .membershipLink {
        cursor: pointer;
        color: #48bfe3;
    }

    .membershipLink:hover {
        cursor: pointer;
        color: #068eb8fa;
    }

    .comp_container .small_text {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: #292929;
        margin-top: 1.5rem;
    }
    .comp_container .header-text {
        color: #292929;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.5rem;
        padding-left: 1rem;
        margin: 0;
    }
    .improve_efficiency {
        margin-top: 2rem;
    }

    .img_wrapper img {
        width: 100%;
    }
    /* Responsive Design */
    @media (max-width: 575.98px) {
        .linear-container {
            background: linear-gradient(90deg, #5e60ce 66.28%, #48bfe3 100%);
            padding: 1rem;
        }
        .comp_container {
            padding: 1rem;
        }
        .telehealth_image {
            width: 20rem;
            margin-top: 3rem;
        }
        .img_wrapper {
            margin-top: 0rem;
        }
    }
</style>
