import Vue from 'vue';
import Vuex from 'vuex';
import secretKeys from './env'
import profile from './profile'
import constants from './constants'
import stripe from './stripe'
import calendar from './calendar'
import patients from './patients'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    secretKeys,
    profile,
    constants,
    stripe,
    calendar,
    patients,
  },
  state: {
    allSubscriptions: [
      {
        name: 'Essentials',
        amount: null,
        description: 'Designed for physicians exploring telehealth employment and income opportunities who want to ' +
          'securely store and own their medical credentials, automating the hiring process and putting the ' +
          'physician in control of their data.',
        list_items: [
          {
            name: 'Physician Vault'
          },

          {
            name: 'Doctors Lounge'
          },

          {
            name: 'Provider Legal'
          },

          {
            name: 'Telehealth Training Webinars'
          },

          {
            name: 'Events Calendar'
          },

          {
            name: 'Unlimited Accredited CME'
          },

          {
            name: 'Job Board Preview'
          }
        ]
      },

      {
        name: 'Enhanced',
        amount: 295,
        description: 'Designed to expand revenue opportunities for independent physicians and medical practices.',
        list_items: [
          {
            name: 'All in Essentials ... plus'
          },

          {
            name: 'Accredited Digital Credentialing'
          },

          {
            name: 'Provider Network Contract Credentialing'
          },

          {
            name: 'Job Board Posting'
          },

          {
            name: 'Provider Legal Consultations'
          },

          {
            name: 'Billing and Collection Services'
          },

          {
            name: 'Telehealth EHR Platform for Virtual Visits'
          }
        ]
      },

      {
        name: 'Premier',
        amount: 495,
        description: 'Designed for independent click-and-mortar physician practices looking to grow their telehealth ' +
          'practice and expand their telehealth services into specialty markets',
        list_items: [
          {
            name: 'All in Enhanced ... plus'
          },

          {
            name: 'Telehealth Practice Management'
          },

          {
            name: 'ViTel Xchange Telehealth Technology Platforms'
          },

          {
            name: 'Revenue Cycle Management'
          },

          {
            name: 'Website, SEO, & Marketing Support'
          }
        ],
        other: "ViTel Specialty Platforms include: Workers' Compensation, RPM (remote patient monitoring), CCM (chronic care management), ER - Urgent Care, Skilled Nursing Facilities, and Telepsych."
      },
    ],
  },
})