<template>
  <div class="bg-white rounded-lg mt-3 p-4">
    <v-select
        v-model="selectedSeverity"
        :items="['All', 'Alerts', 'Warning']"
        label="Alert Severity Filter"
        class="mb-4 w-28"
    ></v-select>

    <v-data-table :headers="dtHeaders" :items="filteredAlerts" sort-by="created_at" class="v-data-table">
      <template v-slot:[`item.alert_severity`]="{ item }">
          <v-chip :color="item.alert_severity === 'alert' ? 'red' : 'yellow'" small class="capitalize ml-1">{{ item.alert_severity }}</v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">{{ item.created_at | datetime }}</template>
      <template v-slot:[`item.actions`]="{ item }">
        <button type="button" @click.stop="openResolveVitalAlertModal(item.actions)" class="blue_button josefin_font ml-3 p-1" :disabled="loading">Resolve</button>
        <button v-if="item.alert_severity === 'warning'" type="button" @click.stop="autoResolveVitalAlert(item.actions)" class="blue_button josefin_font ml-3 p-1 m-1" :disabled="loading">Auto Resolve</button>
      </template>
    </v-data-table>
    <el-dialog :close-on-click-modal="false" :title="'Resolve Vital Alert'" :visible.sync="resolveVitalAlertModalVisible" width="50%" @close="closeResolveVitalAlertModal()">
      <div class="dialog__body" ref="modalResolveVitalAlert">
        <RpmDashboardErrors v-bind:errors="resolveVitalAlertErrors" v-bind:title="'Resolve Vital Alert Errors'"></RpmDashboardErrors>

        <div class="mb-4">
          <label class="block text-gray-700 text-lg font-bold mb-1">Patient</label>
          <p>{{ patient.first_name }} {{ patient.last_name }} </p>
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-lg font-bold mb-1">Alert</label>
          <p><strong>Health Condition:</strong> {{ resolveVitalAlert.care_plan.health_condition }}</p>
          <p><strong>Notes:</strong> {{ resolveVitalAlert.care_plan.notes }}</p>
          <div v-if="resolveVitalAlert.vital_reading.attribute !== 'irregular_heartbeat'">
            <p><strong><span v-if="resolveVitalAlert.care_plan.alerts.find((carePlanAlert) => carePlanAlert.type === resolveVitalAlert.vital_reading.attribute).type === 'weight'">Max Lost</span><span v-else>Min:</span></strong> {{ resolveVitalAlert.care_plan.alerts.find((carePlanAlert) => carePlanAlert.type === resolveVitalAlert.vital_reading.attribute).min }} </p>
            <p><strong><span v-if="resolveVitalAlert.care_plan.alerts.find((carePlanAlert) => carePlanAlert.type === resolveVitalAlert.vital_reading.attribute).type === 'weight'">Max Gained</span><span v-else>Max:</span></strong> {{ resolveVitalAlert.care_plan.alerts.find((carePlanAlert) => carePlanAlert.type === resolveVitalAlert.vital_reading.attribute).max }} </p>
          </div>
          <p class="capitalize">{{ resolveVitalAlert.vital_reading.attribute }}: {{ resolveVitalAlert.vital_reading.value }}</p>
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="resolving_action"> Resolving Action </label>
          <textarea class="h-40 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="resolving_action" type="text" v-model="resolveVitalAlertAction"/>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="taskDuration">Duration in minutes <span class="required">*</span></label> <input v-model="taskDuration" type="number" interval="1" min="0" id="taskDuration" required class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
        </div>
        <span slot="footer" class="josefin dialog-footer">
          <el-button @click="saveResolveVitalAlert" type="primary" :disabled="loading">Resolve Alert</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import RpmDashboardErrors from "@/components/RpmDashboardErrors.vue";
import Vue from "vue";
import Template from "@/mixins/Template";

export default {
  components: {Template, RpmDashboardErrors},
  props: {
    patient: Object,
  },
  data() {
    return {
      page: 0,
      pageCount: 0,
      loading: false,
      selectedSeverity: "All",
      resolveVitalAlertErrors: [],
      resolveVitalAlertModalVisible: false,
      resolveVitalAlertAction: "",
      taskDuration: 0,
      resolveVitalAlert: {
        care_plan: {
          notes: "",
          alerts: [
            {
              type: "",
              min: "",
              max: "",
            },
          ],
        },
        vital_reading: {
          attribute: "",
          value: "",
        },
      },
    };
  },
  computed: {
    ...mapState(["patients"]),

    dtHeaders() {
      return [
        // {text: 'Name', value: 'name'},
        // {text: 'Client', value: 'client'},
        {text: 'Alert Severity', value: 'alert_severity'},
        {text: 'Alert Type', value: 'alert_type'},
        {text: 'Health Condition', value: 'health_condition'},
        {text: 'Notes', value: 'notes'},
        {text: 'Min', value: 'min', width: 80},
        {text: 'Max', value: 'max', width: 80},
        {text: 'Value', value: 'value', width: 80},
        {text: 'Created At', value: 'created_at', width: 180},
        {text: 'Actions', value: 'actions', sortable: false},
      ]
    },
    dtData() {
      return this.patient.vital_alerts_unresolved.concat(this.patient.vital_warnings_unresolved).map((alert) => ({
        // name: `${this.patient.first_name} ${this.patient.last_name}`,
        // client: this.$store.getters.clientName(this.patient.primary_client_id),
        alert_type: alert.vital_reading.attribute,
        alert_severity: alert.severity,
        health_condition: alert.care_plan.health_condition,
        notes: alert.care_plan.notes,
        min: this.getAlertMin(alert),
        max: this.getAlertMax(alert),
        value: alert.vital_reading.value,
        created_at: alert.created_at,
        actions: alert,
      }))
    },
    filteredAlerts() {
      if (this.selectedSeverity === 'All') {
        return this.dtData;
      }
      return this.dtData.filter(alert =>
          (this.selectedSeverity === 'Alerts' && alert.alert_severity === 'alert') ||
          (this.selectedSeverity === 'Warning' && alert.alert_severity === 'warning')
      );
    },
    showTaskNotesModal: {
      get() {
        return this.selectedTask !== null;
      },
      set(value) {
        if (!value) {
          this.selectedTask = null;
        }
      },
    },
  },
  methods: {
    prepareVitalAlertForResolve(alert) {
      this.resolveVitalAlert = alert;
      let action = "Health Condition: " + this.resolveVitalAlert.care_plan.health_condition + "\n";
      action += "Care Plan: " + this.resolveVitalAlert.care_plan.notes + "\n";

      if(this.resolveVitalAlert.vital_reading.attribute !== 'irregular_heartbeat') {
        action += "Min: " + this.getAlertMin(this.resolveVitalAlert) + "\n";
        action += "Max: " + this.getAlertMax(this.resolveVitalAlert) + "\n";
      }

      action += this.resolveVitalAlert.vital_reading.attribute.charAt(0).toUpperCase() + this.resolveVitalAlert.vital_reading.attribute.slice(1) + ": " + this.resolveVitalAlert.vital_reading.value + "\n";
      action += "Resolving Action:\n";
      this.resolveVitalAlertAction = action;
    },
    autoResolveVitalAlert(alert) {
      this.prepareVitalAlertForResolve(alert);
      this.resolveVitalAlertAction += "Reviewed Patients Matrix";
      this.taskDuration = 1;
      this.saveResolveVitalAlert();
    },
    openResolveVitalAlertModal(alert) {
      this.prepareVitalAlertForResolve(alert);
      this.resolveVitalAlertModalVisible = true;
    },
    closeResolveVitalAlertModal() {
      this.resolveVitalAlertModalVisible = false;
    },
    checkVitalsAlertsForm() {
      const required = ["Duration"];
      this.resolveVitalAlertErrors = this.checkForm(required, {
        Duration: this.taskDuration,
      });
      return !this.resolveVitalAlertErrors.length;
    },
    checkForm(required, formValuesObj) {
      return required.filter((key) => !formValuesObj[key]).map((key) => key.replace("_", " ") + " is required.");
    },
    async saveResolveVitalAlert() {
      if (this.loading) return;
      if (!this.checkVitalsAlertsForm()) {
        this.$nextTick(() => this.$refs.modalResolveVitalAlert.scrollIntoView());
        return;
      }

      this.loading = true;

      Vue.$http.post(`/api/rpm_ccm_dashboard/resolve_vital_alert`,
          {id: this.resolveVitalAlert.id, resolving_action: this.resolveVitalAlertAction, duration: this.taskDuration},
          {params: {token: await this.$auth.getTokenSilently()}}
      ).then(() => {
        this.$emit('get-patient-details');
        this.closeResolveVitalAlertModal();
      }).catch((error) => this.handleEhrApiErrors(error, this.resolveVitalAlertErrors, this.$refs.modalResolveVitalAlert))
          .finally(() => this.loading = false);
    },
    handleEhrApiErrors(errorResponse, vueErrors, ref) {
      let response = errorResponse.response.data;
      if (response.errors) {
        for (const key in response.errors) {
          if (response.errors.hasOwnProperty(key)) {
            for (const fieldKey in response.errors[key]) {
              if (response.errors[key].hasOwnProperty(fieldKey)) {
                vueErrors.push(key + ": " + response.errors[key][fieldKey]);
              }
            }
          }
        }
        this.$nextTick(() => ref.scrollIntoView());
      }
    },
    getAlertMin(alert) {
      const carePlanAlert = alert.care_plan.alerts.find(carePlanAlert => carePlanAlert.type === alert.vital_reading.attribute);
      return carePlanAlert ? carePlanAlert.min : undefined;
    },
    getAlertMax(alert) {
      const carePlanAlert = alert.care_plan.alerts.find(carePlanAlert => carePlanAlert.type === alert.vital_reading.attribute);
      return carePlanAlert ? carePlanAlert.max : undefined;
    },
    getProvider(id) {
      return this.patients.providers.find((p) => p.id === id);
    },
    getProviderName(id) {
      const p = this.getProvider(id);
      return p ? p.first_name + " " + p.last_name : "None";
    },
  },
  filters: {
    duration(value) {
      if (!value) return "";
      value = Number(value);
      const h = Math.floor(value / 60);
      const m = value % 60;
      return (h ? `${h}hr ` : "") + `${m}min`;
    },
  },
};
</script>