<template>
  <div class="mt-5 px-10 md:px-56 pb-10 md:pb-20 wrapper__home">
    <h2 class="text-center josefin_font text-2xl font-bold">Apps</h2>
    <p class="text-center text-muted fw-light mb-5">Utilize your multitude of membership tools</p>
    <div class="access_card_container row">
      <div 
        v-for="(i, index) in apps"
        :key="index"
        class="access_card"
        @click="goToPage(i.url)"
      >
        <div class="img_container flex justify-center items-center">
          <img :src="i.img_url" alt="">
        </div>
        <h4 class="text-center mt-2 josefin_font">{{ i.name }}</h4>
        <p class="text-muted fs-lighter text-center">{{ i.more }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      apps: [
        {
          img_url: require("@/assets/vault.svg"),
          name: 'Physician Vault',
          more: 'Powered by Intiva',
          url: '/access/physician-vault'
        },

        {
          img_url: require("@/assets/lounge.svg"),
          name: 'Doctors Lounge',
          more: 'Powered by Handzin',
          url: '/access/doctors-lounge'
        },

        {
          img_url: require("@/assets/openings.svg"),
          name: 'TeleBoard',
          more: 'Full-Time, Part-Time, Shift Work',
          url: '/access/teleboard'
        },

        {
          img_url: require("@/assets/credentialing.svg"),
          name: 'Credentialing',
          more: 'Digital Credentialing',
          url: '/access/credentialing'
        },

        {
          img_url: require("@/assets/ehr.svg"),
          name: 'Telehealth EHR Platform',
          more: 'Anywhere, Anytime',
          url: '/access/telehealth-ehr'
        },

        {
          img_url: require("@/assets/training.svg"),
          name: 'Telehealth Education',
          more: 'Webinars and Online Modules',
          url: '/access/telehealth-education'
        },

        {
          img_url: require("@/assets/sme.svg"),
          name: 'Accredited CME',
          more: 'Continuing Medical Education',
          url: '/access/accredited-cme'
        },

        {
          img_url: require("@/assets/rpm.svg"),
          name: 'RPM/CCM',
          more: 'Remote Patient Monitoring',
          url: '/access/rpm'
        },

        {
          img_url: require("@/assets/news.svg"),
          name: 'Provider Legal',
          more: 'Knowledge is Power',
          url: '/access/provider-legal'
        },
      ]
    }
  },

  // TODO: We're currently make multiple API calls to using this.fetchSecretKeys(). Since this is an SPA,
  // TODO: it should be possible to make a single API when the user logs in and use the relevant data to populate all
  // TODO: relevant variable through binding. (find resolution).

  // TODO: a potential bug with the fix above would be a race condition where the user clicks on the button before
  // TODO: the url has been populated. if this happens, don't do anything (or maybe refresh the page)
  mounted() {
    // DO NOTHING FOR NOW
  },
  computed: {
    ...mapActions([
      'fetchRecord',
      'createRecord',
      'updateRecord'
    ]),
    ...mapState(['secretKeys', 'profile']),
    },
  methods: {
    goToPage(e) {
      this.$router.push(e)
      this.scrollToTop()
    },

    scrollToTop() {
      window.scrollTo(0,0);
    },
  }
}
</script>

<style scoped>
.wrapper__home {
  padding: 5rem 30px;
    max-width: 1140px;
    width: 100%;
    margin: auto;
}
.access_card_container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}
.access_card_container .access_card {
  border: 0.063rem solid #D4D4D4;
  border-radius: 0.5rem;
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.access_card_container .access_card .img_container {
  width: 2.9rem;
  height: 2.9rem;
  margin: auto;
  background: #EEEEEE;
  text-align: center;
  border-radius: 3.125rem;
}
.access_card_container h4 {
  font-size: 1.125rem;
  padding-top: 0.7rem;
}
.access_card_container p {
  font-size: 0.875rem;
}
@media (max-width: 991px) {
  .access_card_container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
    margin-bottom: 3rem;
  }
}
/* Responsive Design */
@media (max-width: 575.98px) {
  .access_card_container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 3rem;
    margin-bottom: 3rem;
  }
}
</style>