<template>
  <div>
    <div class="">
      <div class="wrapper">
      <div class="flex flex-col linear-container px-6 md:px-14 py-5 md:py-12">
        <div class="flex josefin_font">
          <img src="@/assets/white-back.svg" @click="goBack" style="cursor: pointer;" />
          <h3 class="header-text">Physician Vault</h3>
        </div>

        <p class="py-3 m-0 text-white font-light w-auto">
          Upload, share, and track documents of any type or file format. A healthcare-specific folder structure
          provides a convenient method to organize all of your provider documentation and medical credentials.
          Welcome to the Physician Vault: where you won your own data
        </p>
</div>
      </div>
      <div class="comp_container flex flex-col md:flex-row">
        <div class="w-full md:w-3/4">
          <div class="mt-40-pb-0">
            <button @click="intiva_sso" class="josefin_font green_button">Enter the Vault</button>
          </div>

          <section class="improve_efficiency">

            <div v-if="profile.subscriptionPlan !== 'Essentials'">

              <h5 class="josefin_font sub-heading mt-40-pb-0">Physician Vault</h5>

              <p class="m-0 font-weight-normal gray-text mt-8-pb-0">
                The Physician Vault allows you to store all of your provider credentials in an accessible and
                easy-to-use, secure environment, with the ability to share with employers, provider networks, or
                payors as needed, with automated reminders to stay on top of your important renewal dates? Welcome to
                the Physician Vault: where you own your data. Enter the vault to get started entering your credential
                information today.
              </p>

            </div>

            <div v-else>

              <h5 class="josefin_font sub-heading mt-40-pb-0">Improve efficiencies</h5>

              <p class="m-0 font-weight-normal gray-text mt-8-pb-0">
                The Physician Vault allows you to store all of your provider credentials in an accessible and easy-to-use,
                secure environment, with the ability to share with employers, provider networks, or payors as needed,
                with automated reminders to stay on top of your important renewal dates? Welcome to the
                Physician Vault: where you own your data. Enter the vault to get started entering your credential
                information today.
              </p>

            </div>

          </section>

          <section class="improve_efficiency" v-if="profile.subscriptionPlan === 'Essentials'">
            <h5 class="josefin_font font-weight-600 sub-heading heading--font mt-40-pb-0">Boost security & compliance</h5>

            <h6 class="font-weight-700 sub-heading--font lato mt-8-pb-0">Professional Credentialing</h6>
            <p class="gray-text list-width mt-8-pb-0" style="font-size: 1rem;">
              Your ViTel Access Essentials Membership provides you access to the Physician Vault to securely store all
              of your physician credentials needed for employment, joining provider or payor networks. Unlimited
              credentialing is included in our <span @click="goToMemberShipPage" class="membershipLink">Enhanced Member
              Subscription</span>.
            </p>

            <ul class="m-0 pl-4 font-weight-normal gray-text list-disc mt-8-pb-0">
              <li class="list-width mt-8-pb-0">Remain compliant with automated renewal reminders.</li>
              <li class="list-width mt-8-pb-0">Log in, modify, and submit applications online from anywhere.</li>
              <li class="list-width mt-8-pb-0">Eliminate the costly and time-consuming process of manual data entry, printing, and mailing of credentials.</li>
            </ul>

          </section>

<!--          <section class="improve_efficiency" v-if="profile.subscriptionPlan === 'Essentials'">-->
<!--            <h6 class="font-weight-bold pb-1">Credentialing Quotes</h6>-->

<!--            <p class="gray-text list-width" style="font-size: 1rem;">-->
<!--              ViTel also provides individual credentialing contracts to Essential Members. If you are looking for-->
<!--              credentialing for an employer or specific provider or payer network, visit our-->
<!--              <span @click="goToCredentailing" class="membershipLink">Credentialing</span> page to request a quote.-->
<!--            </p>-->

<!--          </section>-->

          <section class="improve_efficiency" v-else>
            <h5 class="josefin_font font-weight-600 sub-heading heading--font mb-8_">Boost security & compliance</h5>

            <h6 class="font-weight-700 sub-heading--font mb-8_ lato">Professional Credentialing</h6>

            <p class="gray-text list-width" style="font-size: 1rem;">
              Your ViTel {{ profile.subscriptionPlan }} Membership provides access to unlimited credentialing for
              employers, provider, and payor networks.
            </p>

            <ul class="m-0 pl-4 font-weight-normal gray-text list-disc">
              <li class="list-width">Remain compliant with automated renewal reminders.</li>
              <li class="list-width">Log in, modify, and submit applications online from anywhere.</li>
              <li class="list-width">Eliminate the costly and time-consuming process of manual data entry, printing, and mailing of credentials.</li>
            </ul>

            <p class="gray-text list-width" style="font-size: 1rem;">
              Once you have entered your data into your Physician Vault, you are ready to be credentialed. You will be
              able to send a secure digital credentialing package to any third-party as needed. Visit our
              <span @click="goToCredentailing" class="membershipLink">Credentialing</span> page to get started.
            </p>

          </section>

        </div>
        <div class="w-full md:w-2/4 flex justify-center">
          <img src="@/assets/physical_vault.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      username: '',
    }
  },
  created() {
    this.fetchSecretKeys()
  },
  methods: {
    ...mapActions(['fetchSecretKeys']),
    goBack() {
      this.$router.go(-1)
    },

    goToMemberShipPage() {
      this.$router.push({ name: 'memberships', params: { scroll: true } })
    },

    intiva_sso() {
      window.open(this.secretKeys.intivaSSoURL, '_blank')
    },

    goToCredentailing() {
      this.$router.push({ name: 'credentialing' })
    }
  },
  computed: {
    ...mapState(['secretKeys', 'profile'])
  }
}
</script>

<style>
.mt-8-pb-0 {
  margin-top: 8px;
  padding-bottom: 0px;
}
.mt-32-pb-0 {
  margin-top: 32px;
  padding-bottom: 0px;
}
.mt-40-pb-0 {
  margin-top: 40px;
  padding-bottom: 0px;
}
.heading--font {
  color: #292929;
  font-size: 22px;
line-height: 26px;
}
.sub-heading--font {
  color: #292929;
  font-size: 16px;
line-height: 24px;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-600 {
  font-weight: 600;
}

.mb-8_ {
  margin-bottom: 8px;
}
.improve_efficiency {
  margin-top: 2rem;
}
.wrapper {
  background: linear-gradient(90deg, #5e60ce 66.28%, #48bfe3 100%);
}
.linear-container {
  padding: 3rem 20px;
  max-width: 1440px;
  margin: auto;
  background: initial;
}
.comp_container {
  max-width: 1440px;
  margin: auto;
  padding: 0px 20px;
}
/* Responsive Design */
@media (max-width: 991.98px) {
  .w8-12 {
    width: auto;
  }
  .comp_container {
    padding: 1rem;
    width: auto;
  }
  .list-width {
    width: auto;
  }
}
</style>