<template>
  <div class="flex justify-center items-center flex-col mt-20">
    <h3 class="text-center text-prime josefin_font text-3xl font-bold">{{ profile.subscriptionPlan }} Member</h3>
    <div class="mt-16 profile_container">
      <div class="cloudinary-container">
        <cld-image cloudName="vitel-health" :publicId="publicId" v-if="publicId !== ''">
          <cld-transformation width="168" height="168" gravity="face" crop="thumb" />
          <cld-placeholder type="pixelate"></cld-placeholder>
          <cld-transformation radius="500" />
        </cld-image>
        <img
        v-else
        :src="imgUrl == null ? $auth.user.picture : imgUrl"
        alt="User's profile picture"
        class="rounded-full object-cover w-40 h-40"
      >
      <div>
        <span class="upload-icon">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" fill="#48BFE3" stroke="white"/>
            <path d="M15.5 8.5C15.6313 8.36868 15.7872 8.26451 15.9588 8.19344C16.1304 8.12236 16.3143 8.08578 16.5 8.08578C16.6857 8.08578 16.8696 8.12236 17.0412 8.19344C17.2128 8.26451 17.3687 8.36868 17.5 8.5C17.6313 8.63132 17.7355 8.78722 17.8066 8.9588C17.8776 9.13038 17.9142 9.31428 17.9142 9.5C17.9142 9.68572 17.8776 9.86961 17.8066 10.0412C17.7355 10.2128 17.6313 10.3687 17.5 10.5L10.75 17.25L8 18L8.75 15.25L15.5 8.5Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
        <input
          type="file"
          name="upload"
          id="upload"
          class=""
          placeholder="Upload File"
          @change="profileUpload"
          ref="fileInput"
          enctype="multipart/form-data"
          accept=".png, .jpg, .jpeg"
        > 
      </div> 
      </div>
      <h4 class="text-center text-prime mt-3 font-bold text-xl">{{ $auth.user.name }}</h4>
      <div class="flex items-center">
        <p class="text-small mr-2">{{ $auth.user.email }}</p>
        <span v-if="$auth.user.email_verified !== true">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="8" fill="#F76B6B"/>
            <path d="M10 6L6 10" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 6L10 10" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
        <span v-else>
          <img src="@/assets/check.svg" alt="" class="check-green">
        </span>
      </div>
      <button @click.prevent="goToMemberShip" class="josefin_font upgrade_button font-semibold">Update Password</button>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      title="Update Password"
      :visible.sync="dialogVisible"
      @close="closeDialogModal()"
      width="40%">
      <div class="mx-5">
        <el-form
          ref="updateForm"
          :model="updateForm"
        >
          <el-form-item prop="current_password">
            <label for="password" class="lato bold">Current Password</label>
            <el-input
              v-model="updateForm.current_password"
              placeholder="********"
              type="password"
              class="border-bg--gray"
            ></el-input>
            <span
              v-if="errorMessage"
              class="text-xs text-red-600 mt-0.5"
            > {{errorMessage}}</span>
          </el-form-item>
          <el-form-item prop="new_password">
            <label for="password" class="lato bold">New Password</label>
            <el-input
              v-model="updateForm.new_password"
              placeholder="********"
              type="password"
              class="border-bg--gray"
              @input="validatePassword"
            ></el-input>
            <div class="py-4">
              <h3 class="text-payazablue-800 font-medium text-sm pb-5">
                Create a password that:
              </h3>
              <div class="flex items-center pb-3">
                <img
                  v-if="passwordLength"
                  src="@/assets/check-outline-green-bg.svg"
                  alt=""
                  class="w-5 h-5"
                />
                <img
                  v-else
                  src="@/assets/check-plain.svg"
                  alt=""
                  class="w-5 h-5"
                />
                <p
                  :class="[
                    passwordLength
                      ? 'text-green-400 pl-2 text-sm'
                      : 'text-gray-400 pl-2 text-sm',
                  ]"
                >
                  Contains at least 8 Characters
                </p>
              </div>
              <div class="flex items-center pb-3">
                <img
                  v-if="capitalLetter && smallLetter"
                  src="@/assets/check-outline-green-bg.svg"
                  alt=""
                  class="w-5 h-5"
                />
                <img
                  v-else
                  src="@/assets/check-plain.svg"
                  alt=""
                  class="w-5 h-5"
                />
                <p
                  :class="[
                    capitalLetter && smallLetter
                      ? 'text-green-400 pl-2 text-sm'
                      : 'text-gray-400 pl-2 text-sm',
                  ]"
                >
                  Contains both lower (a-z) and upper case letters (A - Z)
                </p>
              </div>
              <div class="flex items-center pb-3">
                <img
                  v-if="hasNumber"
                  src="@/assets/check-outline-green-bg.svg"
                  alt=""
                  class="w-5 h-5"
                />
                <img
                  v-else
                  src="@/assets/check-plain.svg"
                  alt=""
                  class="w-5 h-5"
                />
                <p
                  :class="[
                    !hasNumber
                      ? 'text-gray-400 pl-2 text-sm'
                      : 'text-green-400 pl-2 text-sm',
                  ]"
                >
                  Contains at least 1 number
                </p>
              </div>
              <div class="flex items-center pb-3">
                <img
                  v-if="hasSpecialCharacters"
                  src="@/assets/check-outline-green-bg.svg"
                  alt=""
                  class="w-5 h-5"
                />
                <img
                  v-else
                  src="@/assets/check-plain.svg"
                  alt=""
                  class="w-5 h-5"
                />
                <p
                  :class="[
                    !hasSpecialCharacters
                      ? 'text-gray-400 pl-2 text-sm'
                      : 'text-green-400 pl-2 text-sm',
                  ]"
                >
                  Contains a special character
                </p>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="confirm_password">
            <label for="password" class="lato bold">Confirm New Password</label>
            <el-input
              v-model="updateForm.confirm_password"
              placeholder="********"
              type="password"
              class="border-bg--gray"
            ></el-input>
            <span
              v-if="updateForm.confirm_password !== '' && !validateConfirmPassword"
              class="text-xs text-red-600 mt-0.5"
            >Password does not match</span>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
           :disabled="updateForm.new_password == '' || !validateConfirmPassword || updateForm.current_password == '' || updateForm.confirm_password == ''"
          type="primary"
          @click="handleUpdatePassword"
        >
          {{ secretKeys.loader ? 
          'Submitting...' :
          'Save changes'
          }}
        </el-button>
       
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { trimUserId } from '../helpers/functions'

export default {
  data() {
    return {
      loader: false,
      errorMessage:'',
      userData: {},
      imageFile: '',
      publicId: '',
      dialogVisible: false,
      passwordLength: false,
      capitalLetter: false,
      smallLetter: false,
      hasNumber: false,
      imgUrl:sessionStorage.getItem("imageFile"),
      hasSpecialCharacters: false,
      updateForm: {
        current_password: '',
        new_password: '',
        confirm_password: ''
      },
      rules: {
        current_password: [
          {
            required: true,
            message: 'Please enter your current password'
          }
        ],
        new_password: [
          {
            required: true,
            message: 'Please enter a new password'
          }
        ],
        confirm_password: [
          {
            required: true,
            message: 'Please confirm your new password'
          }
        ]
      }
    }
  },
  beforeMount() {
    const response = this.$auth.user;
    if(response['https://custom_claims/role'] === 'Clinician' || 'Provider') {
      return
    } else {
      window.location.href = "https://vip.vitelhealth.com";
    }
  },
  async created() {
    if(this.$auth.user !== undefined) {
     await this.fetchSubscriptionData()
     await this.fetchSecretKeys()
    }
  },
  methods: {
    ...mapActions([
      'fetchSecretKeys',
      'updateProfilePicture',
      'updatePassword',
      'retrieveSubscription'
    ]),
    goToMemberShip() {
      this.dialogVisible = true
    },
    async handleUpload(file) {
      this.loader = true;
      const cloudinary = {
        apiKey: this.secretKeys.cloudinaryKey,
        cloudName: this.secretKeys.cloudinaryName,
        uploadPreset: this.secretKeys.cloudinaryUploadPreset
      }
   
      try {
        const clodinaryURL = `https://api.cloudinary.com/v1_1/${cloudinary.cloudName}/upload`
        const formData = new FormData()
        formData.append("file", file);
        formData.append("upload_preset", cloudinary.uploadPreset);
        const response = await fetch(clodinaryURL, {
          method: 'POST',
          body:  formData,
        })
        let commits = await response.json()
        this.loader = false;
        this.imageFile = commits.secure_url
        this.publicId = commits.public_id
        const payload = {
          user_id: trimUserId(this.$auth.user.sub),
          image_url: this.imageFile
        }
        sessionStorage.setItem("imageFile",this.imageFile)
        await this.updateProfilePicture(payload)
        .then((response) => {
          if (response.statusCode === 200) {
            this.$notify.success({
              title: 'Success',
              message: 'Profile picture updated successfully',
              type: 'success',
           })
          }
        })
      } catch (e) {
        this.loader = false;
        this.$notify.error({
          title: 'Success',
          message: `${e.response.data}`,
          type: 'error',
        })
      }
    },
    async profileUpload(e) {
        var fileName = document.getElementById("upload").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        var theFile = document.getElementById('upload');
        if (theFile.value.length) {    
          if (extFile=="jpg" || extFile=="jpeg" || extFile=="png"){
            this.loader = false;
            if(e.target.files) {
              const file = e.target.files[0];
              await this.handleUpload(file)
              this.loader = false;
            }
          } else {
            this.$awn.alert("Only jpg/jpeg and png files are allowed!");
          }   
       }
    },

     fetchSubscriptionData() {
       if(this.$auth.user) {
         const payload = {
          auth0_sub: this.$auth.user.sub
        }
        this.retrieveSubscription(payload)
       }
    },

    async handleUpdatePassword() {        
      try {
      const payload = {
        email: this.$auth.user.email,
        current_password: this.updateForm.current_password,
        new_password: this.updateForm.new_password
      }
      await this.updatePassword(payload)
      .then((response) => {
        if(response.statusCode === 200) {
          this.$notify.success({
            title: 'Success',
            message: `${response.data}`,
            type: 'success',
          })
          this.dialogVisible = false
          this.$auth.logout();
        }             
      })
    } catch (e) {
      this.loader = false;
      this.errorMessage = e.response.data.detail;
    
      }
    },
    closeDialogModal() {
      this.updateForm.current_password ="";
      this.updateForm.new_password ="";
      this.updateForm.confirm_password ="";
      this.passwordLength= false;
      this.capitalLetter= false;
      this.smallLetter= false;
      this.hasNumber= false;
      this.hasSpecialCharacters= false;
      this.errorMessage="";
    },
    validatePassword(event) {
      this.updateForm.new_password = event
      if (/(?=.*[a-z])/.test(event)) {
        this.smallLetter = true
      } else {
        this.smallLetter = false
      }

      // check for upperccase
      if (/(?=.*[A-Z])/.test(event)) {
        this.capitalLetter = true
      } else {
        this.capitalLetter = false
      }

      // check for number
      if (/(?=.*[0-9])/.test(event)) {
        this.hasNumber = true
      } else {
        this.hasNumber = false
      }

      // check for length
      if (event.length >= 8) {
        this.passwordLength = true
      } else {
        this.passwordLength = false
      }

      // check for special characters
      const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
      if (format.test(event)) {
        this.hasSpecialCharacters = true
      } else {
        this.hasSpecialCharacters = false
      }
    },
  },
  computed: {
    ...mapState(['secretKeys', 'profile']),
    validateConfirmPassword() {
      return this.updateForm.new_password === this.updateForm.confirm_password
    }
  },
}
</script>

<style scoped>
.text-small {
  color: #666666;
  font-weight: 400;
}
.profile-picture {
  width: 10.5rem;
  height: 10.5rem;
}
.profile_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mt-16 {
  margin-top: 4rem;
}
.plan_container {
  margin: 1.7rem 0;
  padding: 0.2rem 1.5rem;
  background: #FFEDD1;
  border-radius: 3.125rem;
  color: #E4961F;
}
.check-green {
  width: 1rem;
  height: 1rem;
}
.upgrade_button {
  border: none;
  margin-top: 1.5rem;
  background: #48BFE3;
  border-radius: 8px;
  padding: 1rem;
  color: #ffffff;
  width: 100%;
}
.upgrade_button:active, .upgrade_button:focus {
  outline: none;
}

.cloudinary-container {
  position: relative;
}
#upload {
  position: absolute;
  opacity: 0;
  top: 6.7rem;
  right: 0rem;
  width: 2rem;
}

.upload-icon {
  position: absolute;
  right: 0;
  top: 7rem;
  cursor: pointer;
}

.update_membership {
  text-align: center;
  color: #DF5959;
  /*update color above to green*/
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  line-height: 1.5rem;
  padding-top: 1.5rem;
}

/* Responsive Design */
@media (max-width: 575.98px) {
  .upgrade_button {
    width: 63%;
  }
  * >>> .el-dialog{
    width: 95% !important;
  }
  * >>> .el-dialog .el-dialog__body{
    padding: 10px 0px !important;
  }
}

* >>>  .border-bg--gray .el-input__inner {
  background: #EEEEEE !important;
  border: none;
  border-radius: 8px;
}


</style>