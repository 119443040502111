<script>
import Vue from "vue";
import RpmDashboardErrors from "@/components/RpmDashboardErrors.vue";

export default {
  name: 'Comment',
  components: {RpmDashboardErrors},
  props: {
    patient: Object,
    comment: Object,
  },
  created() {
    this.setComment();
  },
  data() {
    return {
      loading: false,
      note: null,
      editCommentErrors: [],
      errors: [],
    };
  },
  watch: {
    // patient: function () {
    //   this.setCareTeam();
    // },
  },
  methods: {
    setComment() {
      this.note = this.comment.note || null
    },
    async deleteComment() {
      this.note = null;
      this.updateComment();
    },
    async updateComment() {
      this.errors = [];
      const token = await this.$auth.getTokenSilently();
      const data = {
        note: this.note,
        patient_id: this.patient.id
      };
      if (this.comment.id) {
        data.id = this.comment.id;
      }

      this.loading = true;

      Vue.$http.post(`/api/rpm_ccm_dashboard/comment`, data, {params: {token: token}})
          .then(() => {
            this.$emit("get-patient-details");
            this.$emit('hide-edit-comment');
            if (data.note) {
              this.$awn.success("Comment Saved");
            } else {
              this.$awn.success("Comment Deleted");
            }
          })
          .catch((error) => this.handleEhrApiErrors(error, this.errors, this.$refs.editCareTeamRef)).finally(() => {
            this.loading = false;
          });
    },
  },
  computed: {
  }
}
</script>
<template>
  <el-dialog :close-on-click-modal="false" :title="comment.id ? 'Edit Comment' : 'Create Comment'" width="80%" @close="$emit('hide-edit-comment')" :visible="true">
    <div class="dialog__body">
      <div ref="editCommentTeamRef">
        <RpmDashboardErrors v-bind:errors="errors" v-bind:title="'Comment Errors'"/>
        <div class="grid gap-4">
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="notes">Notes</label>
            <textarea ref="commentNoteRef" v-model="note" rows="4" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="note"></textarea>
          </div>
        </div>
      </div>
    </div>
    <el-button type="primary" @click="updateComment" :disabled="loading">{{this.comment.id ? 'Edit Comment' : 'Create Comment'}}</el-button>
    <el-button v-show="this.comment.id" type="button" @click="deleteComment" :disabled="loading">Delete Comment</el-button>
  </el-dialog>
</template>
<style scoped>

</style>