<template>
  <div>
    <p id="timerData">{{ timer }}</p>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "stopwatch",
  props: {
    start: null,
  },
  mounted() {
    setInterval(() => this.now = Date.now(), 1000)
  },
  data() {
    return {
      now: Date.now(),
    };
  },
  computed: {
    timer: function () {
      if (!this.start) return "00:00:00";
      let d = moment.duration(moment.utc(this.now).diff(moment.utc(this.start)));
      return `${d.hours().toString().padStart(2, '0')}:${d.minutes().toString().padStart(2, '0')}:${d.seconds().toString().padStart(2, '0')}`;
    },
  },
};
</script>

<style scoped>
p {
  font-weight: bold;
  font-size: x-large;
}
</style>
  