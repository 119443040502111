<template>
  <rpm-layout>
    <div class="bg-white rounded-lg p-2">
      <p class="text-gray-700 text-2xl font-bold mb-2">
        Task Report
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
          </template>
          <span>
            Report shows total hours entered by task for each health coach.<br/>
            The first line displays hours between the 1st and 15th of the month.<br/>
            The second line displays hours between the 16th and the end of the month.<br />
            The third line displays the total hours for the month.
          </span>
        </v-tooltip>
      </p>
      <div class="flex justify-between items-center p-2">
        <div>
          <a target="_blank" :download="`${clientName}-${date_from}-${date_to}-task-report.csv`" :href="csvLink()" class="m-2 py-2.5 px-5 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200" v-if="Object.keys(tableData).length">Download Report CSV</a>
        </div>
        <div>
          <el-button :disabled="loading" type="default" @click="getData">Refresh</el-button>
        </div>
      </div>
      <div class="flex gap-2 items-center mb-2">
        <v-text-field
            placeholder="Search"
            v-model="search"
        />

        <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date_from"
                label="Date From"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date_from"
              type="month"
              no-title
              scrollable
              @input="menu1 = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date_to"
                label="Date To"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date_to"
              type="month"
              no-title
              scrollable
              @input="menu2 = false"
          ></v-date-picker>
        </v-menu>

        <v-select
            v-model="client"
            :items="availableClients.map(client => ({ value: client.id, text: client.name }))"
            label="Client"
            placeholder="Select Client"
            filterable
        ></v-select>
      </div>

      <v-data-table
          :headers="tableHeaders"
          :items="tableRows"
          class="elevation-1"
          :items-per-page="-1"
          :loading="loading"
      >
        <template v-slot:item="props">
          <tr>
            <td class="freeze">{{ props.item.name }}</td>
            <td v-for="(header, index) in tableHeaders.slice(1)" :key="index" v-html="props.item[header.value] || '0'"></td>
          </tr>
        </template>
      </v-data-table>

      <div v-if="downloadReportPatient">
        <patient-report :patient="downloadReportPatient" :category="category" v-on:downloaded="downloadReport(null)"/>
      </div>
    </div>
  </rpm-layout>
</template>

<style>
body {
  margin: 0;
}
</style>

<script>
import RpmLayout from '../../layouts/RpmLayout.vue';
import PatientReport from './PatientReport.vue';
import moment from "moment-timezone";
import Vue from "vue";
import PatientMixin from "@/mixins/Patient";


export default {
  mixins: [PatientMixin],
  components: {RpmLayout, PatientReport},
  data() {
    return {
      search: null,
      client: null,
      date_from: moment().format('YYYY-MM'),
      date_to: moment().format('YYYY-MM'),
      menu1: false,
      menu2: false,
      tableData: [],
      tableHeaders: [],
      tableRows: [],
      token: null,
      loading: true,
      downloadReportPatient: null,
    };
  },
  created: async function () {
    this.token = await this.$auth.getTokenSilently();
    Promise.all([
      !this.$store.state.patients.authUser.id ? this.$store.dispatch('getAuthUser') : null,
      !this.$store.state.patients.patients.length ? this.$store.dispatch('getPatients') : null,
      !this.$store.state.patients.providers.length ? this.$store.dispatch('getProviders') : null,
    ]).finally(async () => {
      this.client = this.availableClients[0].id;
      await this.getData();
      this.loading = false;
    });
  },
  mounted: function () {
  },
  computed: {
    filteredTableData() {
      if (!this.search) {
        return this.tableData;
      }
      const searchTerm = this.search.toLowerCase();
      return Object.keys(this.tableData).reduce((acc, period) => {
        acc[period] = this.tableData[period].filter(row => {
          const firstPayPeriodHours = (row.first_pay_period_duration || 0) / 60;
          const secondPayPeriodHours = (row.second_pay_period_duration || 0) / 60;
          const totalHours = firstPayPeriodHours + secondPayPeriodHours;
          return row.name.toLowerCase().includes(searchTerm) ||
              row.roles.toLowerCase().includes(searchTerm) ||
              firstPayPeriodHours.toString().includes(searchTerm) ||
              secondPayPeriodHours.toString().includes(searchTerm) ||
              totalHours.toString().includes(searchTerm);
        });
        return acc;
      }, {});
    },
    clientName() {
      if (!this.client) return '';
      return this.availableClients.find((c) => c.id == this.client).name;
    },
    availableClients() {
      let available_clients = this.$store.state.patients.authUser.available_clients;
      return available_clients && available_clients.length ? available_clients : [];
    },
    objectToCSV() {
      if (!this.tableRows.length) {
        return '';
      }
      const escapeField = field => `"${(''+field).replace(/"/g, '""').replace(/\n/g, '\\n')}"`;
      const stripHTML = field => field.replace(/<\/?[^>]+(>|$)/g, "");
      const csvHeaders = this.tableHeaders.map(header => escapeField(header.text)).join(',');

      const csvRows = this.tableRows.map(row => {
        return this.tableHeaders.map((header, index) => {
          if (index === 0) {
            return escapeField(row[header.value] || '');
          } else {
            return escapeField(stripHTML(row[header.value] || '0'));
          }
        }).join(',');
      }).join('\n');

      return `${csvHeaders}\n${csvRows}`;
    }
  },
  watch: {
    search() {
      this.transformTableData();
    },
    date_from() {
      this.getData();
    },
    date_to() {
      this.getData();
    },

    client() {
      this.getData();
    },
    dataFilter() {
      this.transformTableData();
    }
  },
  methods: {
    csvLink() {
      const file = new File([this.objectToCSV], 'task-report.csv', {type: 'text/csv',});
      return URL.createObjectURL(file);
    },
    async getData() {
      if (!this.client || !this.date_from || !this.date_to) {
        return;
      }

      if (this.date_to < this.date_from) {
        this.$awn.warning('Date from/to need to be in valid ranges');
        return;
      }

      this.loading = true;
      return Vue.$http
          .get("/api/rpm_ccm_dashboard/task-report-data", {
            params: {
              token: await this.token,
              client: this.client,
              date_from: this.date_from,
              date_to: this.date_to,
            },
          })
          .then((res) => {
            this.tableData = res.data.data;
            this.transformTableData();
          })
          .catch((error) => this.$awn.warning(error.response.exception))
          .finally(() => {
            this.loading = false;
          });
    },
    transformTableData() {
      const data = this.filteredTableData;
      const periods = Object.keys(data);

      this.tableHeaders = [
        { text: 'Name', value: 'name', sortable: false },
        ...periods.map(period => ({
          text: moment(period).format('MMM YYYY'),
          value: period,
          sortable: false
        }))
      ];

      const names = new Set();
      periods.forEach(period => {
        data[period].forEach(row => {
          names.add(row.name + " (" + row.roles + ")");
        });
      });

      this.tableRows = Array.from(names).map(name => {
        const row = { name };
        periods.forEach(period => {
          const periodData = data[period].find(item => item.name + " (" + item.roles + ")" === name) || {};
          const firstPayPeriodHours = (periodData.first_pay_period_duration || 0) / 60;
          const secondPayPeriodHours = (periodData.second_pay_period_duration || 0) / 60;
          const totalHours = firstPayPeriodHours + secondPayPeriodHours;
          row[period] = `${firstPayPeriodHours.toFixed(2)} <strong>hrs</strong><br/> ${secondPayPeriodHours.toFixed(2)} <strong>hrs</strong> <hr/> <span class="text-white bg-blue-500 rounded">${totalHours.toFixed(2)} <strong>hrs</strong></span>`;
        });

        return row;
      });
    }
  },
};
</script>

<style scoped>
.freeze {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}
</style>