<template>
  <div class="flex flex-col md:flex-row" id="physician_calender">
    <side-bar
      @removeWeekends="removeWeekends"
      @enableWeekends="enableWeekends"
      @handleSubmit="submit"
      :providerAvailability="providerAvailability"
      :loading="loading"
    ></side-bar>
    <div class="flex-none md:flex-1 flex-col md:flex-col overflow-hidden max-h-screen h-full">
      <main class="flex-none md:flex-1 overflow-x-auto md:overflow-x-hidden overflow-y md:overflow-y-auto p-10 my-6">
        <div class="flex justify-center items-center ml-auto w-full h-full lg:w-98">
          <vc-calendar
            class="w-auto"
            :attributes="attrs"
            :disabled-dates='disabledDates'
            is-expanded
            :min-date='new Date()'
          >
            <template v-slot:day-content="{ day, attributes }">
              <div class="flex flex-col h-full z-10 justify-center overflow-hidden pointer justify-start" @click="openModal(day)">
                <span class="day-label text-sm text-gray-900 pointer">{{ day.day }}</span>
                <div class="flex-grow overflow-y-auto overflow-x-auto">
                
                  <div
                    v-for="attr in attributes"
                    :key="attr.id"
                    class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                  >
                  <div class="main_dots_container" v-for="item in attr.customData" :key="item.id">
                    <div class="flex dots_container" v-if="item.shortDate == day.id">
                      <span class="dots"></span> <p class="txt_time"> {{ item.start_time }} - {{ item.end_time }}</p>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </template>
          </vc-calendar>
        </div>
      </main>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      title=""
      :visible.sync="dialogVisible"
      width="50%"
      class="josefin_font"
      :before-close="closeDialogModal"
      >
      <h3 class="relative md:absolute top-4 md:top-7 text-xl text-black font-semibold">Specify your available hours for {{ todaysDate }}</h3>
      <div class="flex justify-between relative flex-col mt-10 md:mt-0 mb-10" id="modal_container">
        <!-- <div
          v-for="(item, index) in providerAvailability.availability"
          :key="index"
          class="mb-2"
        >
          <div id="time_select" class="flex items-center" v-if="item.current === dateID">
            <el-time-select
              v-model="item.start_time"
              :picker-options="{
                start: '08:30',
                step: '00:15',
                end: '23:30'
              }"
              placeholder="Start Time">
            </el-time-select>
            <span class="px-3">-</span>
            <el-time-select
              v-model="item.end_time"
              :picker-options="{
                start: '08:30',
                step: '00:15',
                end: '23:30'
              }"
              placeholder="End Time">
            </el-time-select>
            <div
              @click="deleteItem(index)"
              class="ml-1 cursor-pointer flex items-center"
            >
              <p class="text-xs hidden md:block">DELETE</p>
              <p class="text-xs block md:hiddeb">
                <i class="el-dialog__close el-icon el-icon-close"></i>
              </p>
            </div>
          </div>
        </div> -->
        <el-form ref="timeLineForm" :model="timeLineForm" :rules="rules">
          <el-form-item prop="timeLines">
        <div
          v-for="(item, index) in timeLineForm.timeLines"
          :key="index"
          class="mb-2"
        >
          <div id="time_select" class="flex items-center" v-if="item.current === dateID">
            <el-time-select
            v-model="item.start_time"
              :value="start_time"
              @input="start_time = item.start_time"
              :picker-options="{
                start: '08:30',
                step: '00:15',
                end: '23:30'
              }"
              @change="switchSelect(start_time,'start')"
              placeholder="Start Time">
            </el-time-select>           
            <span class="px-3">-</span>
            <el-time-select
              v-model="item.end_time"
              :value="end_time"
              @input="end_time = item.end_time"
              :picker-options="{
                start: '08:30',
                step: '00:15',
                end: '23:30'
              }"
               @change="switchSelect(end_time,'end')"
              placeholder="End Time">
            </el-time-select>
            <div
              @click="deleteItem(index)"
              class="ml-1 cursor-pointer flex items-center"
            >
              <p class="text-xs hidden md:block">DELETE</p>
              <p class="text-xs block md:hidden">
                <i class="el-dialog__close el-icon el-icon-close"></i>
              </p>
            </div>
          </div>
        </div>
          </el-form-item>
        </el-form>
        <button
        :disabled="disableButton == true || start_time  == '' || end_time == '' ? true :false"
        :class="disableButton == true || start_time == '' || end_time == '' ? 'disabled_button' :''"
          @click="addNewTimeLine"
          class="uppercase text-gray-600 text-xs cursor-pointer relative md:absolute md:right-0 pl-mobile"
        >
          add new
        </button>
      </div>
      <!-- <div class="flex flex-col" v-if="timeLines.length > 0">
        <el-checkbox v-model="apply_to_only_dayInweek" class="mb-3">Apply to all {{ today }}s</el-checkbox>
        <el-checkbox v-model="apply_to_all_days" class="mb-3">Apply to all days</el-checkbox>
      </div> -->
      <span slot="footer" class="dialog-footer">
        <button
          class="bg-transparent border-none text-dark py-2 px-8 focus:outline-none"
          @click="cancelToTimelines()"
        >
          Cancel
        </button>
        <button
        :disabled="disableButton == true || start_time  == '' || end_time == '' ? true :false"
        :class="disableButton == true || start_time == '' || end_time == '' ? 'disabled_button_mouse' :'green_button'"
          class=" text-white py-2 px-8 focus:outline-none"
          @click.prevent="saveToTimelines('timeLines')"
        >
          Save
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import dayjs from 'dayjs';
import { trimUserId } from '../helpers/functions'
import SideBar from '../components/Sidebar.vue'

export default {
  components: {
    SideBar,
  },
  data() {
    return {
      providerAvailability: {
        availability: [],
      },
      timeLineForm: {
        timeLines: [],
      },
      rules: {
        timeLines: [
          {
            required: true,
            message: 'Please enter start time and end time'
          }
        ],
      },
      payloadObject: {},
      dialogVisible: false,
      today: null,
      loading: false,
      dateObject: {},
      todaysDate: null,
      fullDate: null,
      shortDate:null,
      disabledDates: { weekdays: [1, 7] },
      updatedTimelines: {},
      apply_to_only_dayInweek: false,
      apply_to_all_days: false,
      allWeekdays: [],
      disableButton:false,
      start_time:'',
      end_time:''
    }
  },
  async mounted() {
    await this.availabilityFetch()
    this.getDatesInRange(this.providerAvailability.start_date, this.providerAvailability.end_date)
    this.timeLineForm.timeLines.push(...this.providerAvailability.availability);
  },
  
  methods: {
    ...mapActions(['submitProviderAvailability', 'fetchProviderAvailability']),
    openModal(g) {
      this.fullDate = new Date(g.year, g.month - 1, g.day)
      this.shortDate = dayjs(g.date).format('YYYY-MM-DD')
      this.dateObject = g
      this.todaysDate = dayjs(g.date).format('dddd MMMM DD YYYY')
      this.today = dayjs(g.date).format('dddd')
      this.dateID = g.id
      this.dialogVisible = true;
      if(this.providerAvailability.availability.length > 0) {
        const c = this.providerAvailability.availability.filter(res => res.current === this.dateID)
        
        this.timeLineForm.timeLines.push({
        id: this.timeLineForm.timeLines.length + 1,
        start_time: '',
        end_time: '',
        current: this.dateID,
        fullDay: this.fullDate,
        shortDate:this.shortDate
      })
        if(c.length === 0) {
          return true
        } else {
          this.timeLines = c
          return c
        }
      
      }
      this.start_time = '';
      this.end_time = '';
    },
    switchSelect(value,type) {


      if(this.end_time != '') {
        if(type=='end' && value == this.start_time) {
        this.$notify({
          title: 'Error',
          message: 'Start time and end time has same value',
          type: 'error',
        })
        this.loading = false;
        this.disableButton = true;
      }
      else if(type=='end' && value < this.start_time) {
        this.$notify({
          title: 'Error',
          message: 'Start time should be greater than end time',
          type: 'error',
        })
        this.loading = false;
        this.disableButton = true;
      }
      else if(type=='start' && value > this.end_time) {
        this.$notify({
          title: 'Error',
          message: 'Start time should be greater than end time',
          type: 'error',
        })
        this.loading = false;
        this.disableButton = true;
      }
      else {
        this.disableButton = false;
      }
      }
     
    },
    removeWeekends() {
      this.disabledDates = null
    },
    enableWeekends() {
      this.disabledDates = { weekdays: [1, 7] }
    },
    cancelToTimelines() {
      this.start_time = '';
      this.end_time = '';
      this.disableButton = true;
      this.dialogVisible = false;
      const lastItem =  this.timeLineForm.timeLines.splice(-1)

      if(lastItem.end_time == "" || lastItem.start_time == "") {
        this.timeLineForm.timeLines.splice(-1)
      }
    
    },

    closeDialogModal() {
      this.start_time = '';
      this.end_time = '';
      this.dialogVisible = false;
      const lastItem =  this.timeLineForm.timeLines.splice(-1)

      if(lastItem.end_time == "" || lastItem.start_time == "") {
        this.timeLineForm.timeLines.splice(-1)
      }
    },

    addNewTimeLine() {
      this.start_time = "";
      this.end_time = "";
      this.timeLineForm.timeLines.push({
        id: this.timeLineForm.timeLines.length + 1,
        start_time: '',
        end_time: '',
        current: this.dateID,
        fullDay: this.fullDate,
        shortDate:this.shortDate
      })
    },
    deleteItem(i) {
      this.timeLineForm.timeLines.splice(i, 1)
    },
    submit() {
      this.loading = true
      const data = JSON.parse(localStorage.getItem('payloadData'))
      const submitAvailabilityFormData = JSON.parse(localStorage.getItem('submitAvailabilityFormData'))
      const payload = {
        ...data,
        encounter_rate: submitAvailabilityFormData.encounter_rate_amount == "" ? 0 : submitAvailabilityFormData.encounter_rate_amount,
        hourly_rate: submitAvailabilityFormData.hourly_rate_amount == "" ? 0 : submitAvailabilityFormData.hourly_rate_amount,
        availability: this.updatedTimelines,
        exclude_weekends: submitAvailabilityFormData.exclude_weekends === 11 ? true : false,
        user_id: trimUserId(this.$auth.user.sub),
      }

      console.log(this.updatedTimelines,"this.updatedTimelines",this.updatedTimelines.length)
      if(this.timeLineForm.timeLines.length === 0) {
         this.$notify({
          title: 'Error',
          message: 'Please enter start time and end time',
          type: 'error',
        })
        this.loading = false;
      } else if(this.updatedTimelines.length === undefined || this.updatedTimelines.length === null) {
         this.$notify({
          title: 'Error',
          message: 'Set a start time and end time first',
          type: 'error',
        })
        this.loading = false;
      } else {
        this.loading = true;
        this.submitProviderAvailability(payload)
        .then((res) => {
          this.$notify({
            title: 'Success',
            message: res.data,
            type: 'success',
          })
          this.availabilityFetch()
          this.loading = false;
        })
        .catch((err) => {
          this.$notify({
            title: 'Error',
            message: err.response_message || err.message || 'Something went wrong',
            type: 'error',
          })
        })
      
      }
     
    
    },
    async availabilityFetch() {
      const payload = {
        user_id: trimUserId(this.$auth.user.sub),
      }
      await this.fetchProviderAvailability(payload)
      .then((res) => {
        this.providerAvailability = res.data
      })
    },
    saveToTimelines() {
      const ids = this.timeLineForm.timeLines.map(o => o.id)
      console.log(ids,"ids");
      const filtered = this.timeLineForm.timeLines.filter(({id}, index) => !ids.includes(id, index + 1))
      console.log(filtered,"filtered");
      this.updatedTimelines = filtered
      this.dialogVisible = false;
      this.start_time = '';
      this.end_time = '';
      this.$notify({
          title: 'Success',
          message: 'Successfully added',
          type: 'success',
        })
        this.loading = false;
    },
    getDatesInRange(startDate, endDate) {
      const d1 = new Date(startDate)
      const d2 = new Date(endDate)
      const date = new Date(d1.getTime());
      const dates = [];
      while (date <= d2) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return dates;
    }
  },
  computed: {
    attrs() {
      return [
        {
          popover: true,
          customData:  this.providerAvailability.availability,
          dot: false,
          dates: this.dotDates,
        },
      ] 
    },    
    dotDates() {
      if(this.providerAvailability.availability) {
        return this.providerAvailability.availability.map((i) => {
          const newDate = new Date(i.fullDay)
          const year = newDate.getFullYear();
          const month = newDate.getMonth();
          const today = newDate.getDate()
          return new Date(year, month, today)
      })
      }
    },
  },
}
</script>

<style>
.el-date-editor .el-range-input {
  background: transparent;
}
#sidebar .el-radio__input.is-checked .el-radio__inner,
#hiring .el-radio__input.is-checked .el-radio__inner {
  border-color: #292929;
  background: #292929;
}

#sidebar .el-radio__inner,
#hiring .el-radio__inner {
  width: 1.5rem;
  height: 1.5rem;
}

#sidebar .el-radio__input.is-checked+.el-radio__label,
#hiring .el-radio__input.is-checked+.el-radio__label {
  color: #292929;
}

.vc-weeks {
  height: 42rem;
}

</style>

<style scoped>
* >>> .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 140px !important;
  background: transparent;
}
* >>> .dots {
  width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #48BFE3;
}

* >>> .dots_container {
  align-items: center;
  padding-top: 5px;
}

* >>> .main_dots_container {
  position: relative;
}

* >>> .justify-start {
  justify-content: start;
}

* >>> .txt_time {
  margin-left: 10px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
}
* >>> .is-disabled {
  pointer-events: none;
}
* >>> .pointer {
  cursor: pointer;
}
* >>> .vc-day-content.is-disabled {
  position: absolute;
    z-index: 9;
    height: 100%;
    background:#FFFFFF;
}
* >>> .vc-day {
  overflow: hidden;
  justify-content: center;
    display: flex;
    text-align: center;
}

@media only screen and (max-width: 991px) {

* >>> .el-dialog {
  width: 95% !important;
}
* >>> .el-dialog .el-dialog__body{
     padding: 10px !important;
}

.pl-mobile {
  padding-left: 20px;
}
}

.disabled_button {
  cursor: not-allowed;
  color: black;
}
.disabled_button_mouse {
  cursor: not-allowed;
}


</style>