<script>
import Chart from "chart.js";
import {Line} from "vue-chartjs";
import annotationPlugin from "chartjs-plugin-annotation";

Chart.plugins.register(annotationPlugin);

export default {
  extends: Line,
  props: ["data", "options"],
  mounted() {
    this.renderChart(this.data, this.options);
  },
  watch: {
    data() {
      this.renderChart(this.data, this.options);
    },
  },
};
</script>
