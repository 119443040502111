import pako from "pako";

export const trimUserId = (string) => {
  return string.slice(6);
}

export function inflateData(data) {
  let binaryString = atob(data);
  let len = binaryString.length;
  let bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return JSON.parse(pako.inflate(bytes, { to: 'string' }));
}