<template>
  <main>
    <div class="wrapper">
      <div class="flex flex-col linear-container px-6 md:px-14 py-5 md:py-12">

        <div class="flex items-center">

          <img src="@/assets/white-back.svg" @click="goBack" style="cursor: pointer" />

          <h3 class="header-text josefin_font">Upcoming Webinars</h3>

        </div>

      </div>
    </div>
    <div class="comp_container">
      <div class="flex justify-between items-center mt-20px">
        <span>Showing {{ profile.webinars.length }} {{ profile.webinars.length > 1 ? 'Webinars' : 'Webinar' }}</span>
        <span class="text-maingray uppercase text-sm txt-sort">
          sort by :
          <el-select v-model="sortBy" placeholder="Most Relevant">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </span>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
        <webinar-card v-for="(event, index) in profile.webinars" :key="index" :event="event"></webinar-card>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import WebinarCard from '../components/WebinarCard.vue'

export default {
  components: {
    WebinarCard
  },
  data() {
    return {
      sortBy: '',
      options: [
        {
          value: 'Most relevant',
          label: 'Most relevant'
        },
        {
          value: 'Most recent',
          label: 'Most recent'
        },
        {
          value: 'Featured webinars',
          label: 'Featured webinars'
        },
      ]
    }
  },
  mounted() {
    this.fetchAllWebinars();
  },
  computed: {
    ...mapState(['profile'])
  },
  methods: {
    ...mapActions(['fetchAllWebinars']),
    goBack() {
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped>
*>>>.el-input__inner::placeholder {
  outline: none;
  background-color: transparent !important;
  color: #292929;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px;
  line-height: 14px;
}

*>>>input[type='text']:focus {
  --tw-ring-color: white;
}

*>>>.el-input__inner {
  position: relative;
  top: 1px;
  outline: none;
  background-color: transparent !important;
  color: #292929;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px;
  line-height: 14px;
}

*>>>.el-select .el-input .el-select__caret {
  color: #000000 !important;
}

*>>>.el-input--suffix .el-input__inner {
  padding-right: 20px !important;
}

*>>>.el-input__inner {
  font-weight: 700 !important;
  text-transform: uppercase !important;
}

.wrapper {
  background: linear-gradient(90deg, #5e60ce 66.28%, #48bfe3 100%);
}

.linear-container {
  padding: 3rem 20px;
  max-width: 1440px;
  margin: auto;
  background: initial;
}

.comp_container {
  max-width: 1440px;
  margin: auto;
  padding: 0px 20px;
}

.mt-20px {
  margin-top: 20px;
}

.txt-sort {
  color: #292929;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
</style>