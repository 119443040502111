<template>
  <div>
    <span @click="() => this.editTaskModal = true"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-block cursor-pointer mr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
  </svg>
  </span>
    <slot></slot>
<!--    <el-button @click="() => this.editTaskModal = true" type="primary">Edit Task</el-button>-->
    <el-dialog :close-on-click-modal="false" title="Edit Task" :visible.sync="editTaskModal" width="50%" @close="() => (this.editTaskModal = false)">
    <div ref="editTaskModalBody" class="dialog__body">
      <RpmDashboardErrors v-bind:errors="editTaskErrors" v-bind:title="'Edit Task Errors'"/>
      <div class="grid gap-4">
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="select_task"> Task Type </label>
          <select id="select_task" v-model="selectedTaskType" class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            <option v-for="taskType in taskTypes" v-bind:value="taskType" v-bind:key="taskType">
              {{ taskType }}
            </option>
          </select>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="taskDescription"> Description <span class="required">*</span> </label> <input v-model="taskDescription" class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="taskDescription" placeholder=""/>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="select_template"> Select Template </label>
          <el-select id="select_template" @change="templateChange" v-model="selectedTemplate" class="w-full" clearable filterable>
            <el-option v-if="selectedTaskType === 'ccm'" v-for="template in ccmTaskTemplates" :value="template" :key="template.id" :label="template.name"/>
            <el-option v-if="selectedTaskType === 'rpm'" v-for="template in rpmTaskTemplates" :value="template" :key="template.id" :label="template.name"/>
          </el-select>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="select_assigned_user"> Assigned User </label>
          <el-select v-model="selectedAssignedUser" value-key="id" clearable placeholder="Health Coach" filterable>
            <el-option v-for="health_coach in healthCoaches" :value="health_coach" :label="`${health_coach.first_name} ${health_coach.last_name}`" :key="health_coach.id" ></el-option>
          </el-select>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="note"> Note <span class="required">*</span> </label>
          <textarea required rows="4" ref="tasknoteRef" @keydown.tab.prevent="tabTemplate('tasknoteRef')" v-model="notes" class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="note" placeholder=""/>
        </div>
        <Collapse  text="Sms Messages" class="mb-4">
          <div class="mb-4">
            <message-list :messages="patient.sms.filter(s => s.tasks_id === task.id)" :patient="patient" />
          </div>
        </Collapse>
        <div class="mb-4 flex flex-row justify-between">
          <div class="text-gray-700 text-sm font-bold mb-2">Next Appointment Date</div>
          <edit-appointment-date :patient-id="patient.id" :date="patient.nad" v-on:get-patient-details="$emit('get-patient-details')" >{{ patient.nad | dateNonLocal }} </edit-appointment-date>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="duration"> Duration in minutes <span class="required">*</span> </label> <input v-model="taskDuration" type="number" interval="1" min="0" class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="duration" :placeholder="taskDurationPlaceholder"/>
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="due_date">
            Due Date
          </label>
          <input v-model="taskDueDate" type="date"
                 class="shadow appearance-none border rounded w-full py-2 px-3 input__registerPatient text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                 id="due_date" />
        </div>
      </div>
    </div>
    <span slot="footer" class="josefin dialog-footer">
        <el-button @click="editTask(null)" type="primary" :disabled="loading">Save</el-button>
        <el-button @click="editTask('closed')" type="success" :disabled="loading">Close</el-button>
        <el-button @click="deleteTask" type="danger" :disabled="loading">Delete</el-button>
        <el-button @click="cancel" type="cancel">Cancel</el-button>
    </span>
  </el-dialog>
  </div>
</template>
<script>
import RpmDashboardErrors from "@/components/RpmDashboardErrors.vue"
import Vue from "vue";
import Template from "@/mixins/Template";
import {mapState} from "vuex";
import MessageList from "@/views/MessageList.vue";
import Collapse from "@/components/Collapse.vue";
import EditAppointmentDate from "@/views/rpm/patient-info/components/EditAppointmentDate.vue";

export default {
  name: 'task-edit',
  components: {EditAppointmentDate, MessageList, RpmDashboardErrors, Collapse},
  mixins: [Template],
  props: {
    isNotes: Boolean, // Notes (in progress, closed) vs Tasks (open, optional due date)
    patient: Object,
    task: Object,
    healthCoaches: null,
    // id: Number,
    // name: String,
    // note: String,
    // patient_id: Number,
    // due_date: String,
    // category: String,
    // description: String,
    // estimate: Number,
    // duration: Number,
    // priority: String,
  },
  computed: {
    ...mapState(["patients"]),
    templates: function () {
      return this.patients.templates;
    },
    rpmTaskTemplates: function () {
      return this.templates.filter(t => t.category === 'rpm_task');
    },
    ccmTaskTemplates: function () {
      return this.templates.filter(t => t.category === 'ccm_task');
    },
  },
  created() {
    this.setData();
  },
  data() {
    return {
      loading: false,
      selectedTemplate: null,
      selectedAssignedUser: null,
      editTaskErrors: [],
      taskTypes: ["rpm", "ccm"],
      selectedTaskType: "ccm",
      taskDescription: "",
      taskDuration: null,
      taskDurationPlaceholder: null,
      taskDueDate: null,
      accessToken: null,
      // : null,//notes set in templates mixin
      editTaskModal: false,
    };
  },
  methods: {
    cancel() {
      this.editTaskModal = false;
      this.setData();
    },
    setData() {
      this.selectedTaskType = this.task.category;
      this.selectedAssignedUser = this.healthCoaches.find(hc => hc.id === this.task.assigned_id);
      this.taskDescription = this.task.description;
      this.taskDuration = this.task.duration;
      this.notes = this.task.note;
      this.taskDueDate = this.task.due_date;
    },
    async deleteTask() {
      this.loading = true;

      Vue.$http.delete(`/api/rpm_ccm_dashboard/delete_task/${this.task.id}`, { params: { token: await this.$auth.getTokenSilently() } })
          .then(async () => {
            this.$awn.success("Task Deleted");
            await this.$emit('get-patient-details');
            this.editTaskModal = false;
          })
          .catch((error) => this.handleEhrApiErrors(error, this.editTaskErrors, this.$refs.editTaskModalBody)).finally(() => {
            this.loading = false;
          });
    },
    async editTask(status = null) {
      if (!this.checkEditTaskForm()) {
        this.$nextTick(() => this.$refs.editTaskModalBody.scrollIntoView());
        return;
      }
      let updated = Object.assign({}, this.task);
      updated.assigned_id = this.selectedAssignedUser.id;
      updated.note = this.notes;
      updated.duration = this.taskDuration;
      updated.category = this.selectedTaskType;
      updated.description = this.taskDescription;
      updated.due_date = this.taskDueDate;
      if (status) updated.status = status;

      this.loading = true;

      Vue.$http.post(`/api/rpm_ccm_dashboard/update_task`, updated, { params: { token: await this.$auth.getTokenSilently() } })
          .then(async () => {
            this.$awn.success("Task Saved");
            await this.$emit('get-patient-details');
            this.editTaskModal = false;
          })
          .catch((error) => this.handleEhrApiErrors(error, this.editTaskErrors, this.$refs.editTaskModalBody)).finally(() => {
            this.loading = false;
          });
    },
    checkEditTaskForm() {
      const required = ["Description", "Duration", "Note", "AssignedUser"];
      this.editTaskErrors = this.checkForm(required, {
        Description: this.taskDescription,
        Duration: this.taskDuration,
        Note: this.notes,
        AssignedUser: this.selectedAssignedUser,
      });
      return !this.editTaskErrors.length;
    },
    checkForm(required, formValuesObj) {
      return required.filter(key => !formValuesObj[key]).map(key => key.replace("_", " ") + " is required.");
    },
  },
}
</script>