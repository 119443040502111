<template>
  <div class="mt-3">
    <div v-if="vitals.length">
      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-2">
        <div v-for="vital in vitals" :key="vital.id">
          <div class="grid" @click="setSelectedVital(vital)">
            <div class="flex justify-between bg-white rounded-lg p-4 hover:cursor-pointer hover:bg-opacity-80">
              <div>
                <p class="uppercase text-sm font-bold tracking-tighter text-gray-700">
                  {{ vital.label }}
                </p>
                <div class="flex items-end">
                  <p class="text-4xl font-bold" v-bind:class="{
                    'text-green-500': isNormal(vital),
                    'text-red-500': !isNormal(vital),
                  }">
                    {{ vital.latestReading.value }}
                  </p>
                  <p class="text-sm text-gray-500">
                    {{ vital.unit }}
                  </p>
                </div>
                <p class="text-xs text-gray-500">
                  {{ vital.latestReading.reading_at.fromNow() }}
                </p>
              </div>
              <div class="vital_icon">
                <img class="w-full h-full object-contain" :src="vital.icon"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <div class="bg-white rounded-lg p-4">
          <p class="text-sm text-gray-500">Vital History</p>
          <select class="text-sm font-bold text-gray-700 p-0 border-none focus:border-none cursor-pointer" v-model="selectedVital">
            <option v-for="vital in vitals" :key="vital.id" :value="vital">
              {{ vital.label }}
            </option>
          </select>
          <select class="text-sm font-bold text-gray-700 p-0 border-none focus:border-none cursor-pointer" v-model="selectedDateRange">
            <option v-for="(dateRange, index) in ['This Week', 'This Month', 'Last 7 days', 'Last 30 days', 'All Time', 'Custom']" :key="index" :value="dateRange">
              {{ dateRange }}
            </option>
          </select>

          <div class="mt-5 mb-5">
            <a target="_blank" :download="`${this.patient.first_name}-${this.patient.last_name}-vitals.csv`" :href="csvLink()" class="py-2.5 px-5 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200" v-if="selectedReadingsSorted.length">Download Report CSV</a>
          </div>

          <div v-if="selectedDateRange === 'Custom'">
            <v-menu v-model="startMenu" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2 mt-2"
                    color="primary"
                >
                  <span v-if="startDate">{{ startDate | dateNonLocal }}</span>
                  <span v-else>Start Date</span>
                </v-btn>
              </template>
              <v-date-picker v-model="startDate" @input="startMenu = false"></v-date-picker>
            </v-menu>

            <v-menu v-model="endMenu" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mt-2"
                    color="primary"
                >
                  <span v-if="endDate">{{ endDate | dateNonLocal }}</span>
                  <span v-else>End Date</span>
                </v-btn>
              </template>
              <v-date-picker v-model="endDate" @input="endMenu = false"></v-date-picker>
            </v-menu>
          </div>

          <div class="p-2">
            <vital-chart :attribute="selectedVital.attribute" :unit="selectedVital.unit" :vitalReadings="selectedReadings" :alerts="getAlerts(selectedVital.attribute)"/>
          </div>
          <div class="p-2">
            <v-data-table :loading="loading" :headers="tableHeaders" :items="tableData" :footer-props="{'items-per-page-options': [5, 10, 25, 50, -1]}" :items-per-page="10" sort-by="reading_at_sort" sort-desc>
              <template v-slot:loading><v-progress-circular indeterminate></v-progress-circular></template>
              <template v-slot:[`item.reading_at_sort`]="{ item }">{{item.reading_at.format("MMM D, YYYY h:mm a")}}</template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="bg-white rounded-lg p-4">
        <p class="italic text-gray-500">
          This patient has no vital readings yet.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import vitalsHelper from "../../../../helpers/vitals";
import VitalChart from "./VitalChart.vue";

export default {
  components: {VitalChart},
  props: {
    patient: Object,
  },
  data() {
    return {
      loading: false,
      vitals: vitalsHelper.VITALS,
      selectedVital: null,
      selectedDateRange: 'This Month',
      startMenu: false,
      endMenu: false,
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    tableHeaders() {
      return [
        {text: 'Attribute', value: 'attribute'},
        {text: 'Value', value: 'value'},
        {text: 'Date', value: 'reading_at_sort'},
        {text: 'Device', value: 'deviceType'},
        // {text: 'Source', value: 'source'},
      ];
    },
    tableData() {
      return [
        ...this.selectedReadingsSorted.map((reading) => ({
          ...reading,
          'deviceType': this.getDeviceType(reading.device_id) || "--",
          'reading_at_sort': reading.reading_at.valueOf() || "--",
        })),
      ]
    },
    arrayToCSV() {
      if (!this.selectedReadingsSorted.length) {
        return '';
      }
      const escapeField = field => `"${(''+field).replace(/"/g, '""').replace(/\n/g, '\\n')}"`;

      const csvHeaders = Object.keys(this.selectedReadingsSorted[0]).map(escapeField).join(',');
      const csvRows = this.selectedReadingsSorted.map(row =>
          Object.keys(row).map(key => {
            let value = row[key];
            if (key === 'reading_at') {
              value = value.format("MMM D, YYYY h:mm a");
            }
            return escapeField(value);
          }).join(',')
      ).join('\n');

      return `${csvHeaders}\n${csvRows}`;
    },
    vitalReadings() {
      return this.patient.vital_readings.map((vital_reading) => {
        vital_reading.reading_at = moment.utc(vital_reading.reading_at).local();
        return vital_reading;
      });
    },
    selectedReadingsSorted() {
      return this.selectedReadings.toSorted((a, b) => b.reading_at.valueOf() - a.reading_at.valueOf());
    },
    selectedReadings() {
      if (this.selectedVital === null) return [];

      if (this.selectedDateRange === 'Custom' && this.startDate && this.endDate && moment(this.startDate).isAfter(moment(this.endDate))) {
        this.$awn.alert('Start date should be before end date');
      }
      return this.getReadings(this.selectedVital.attribute)
        .filter((reading) => {
          if(this.selectedDateRange==='This Month') {
            return reading.reading_at.isAfter(moment().startOf('month'))
          }
          else if(this.selectedDateRange==='This Week') {
            return reading.reading_at.isAfter(moment().startOf('week'))
          }
          else if(this.selectedDateRange==='Last 7 days') {
            return reading.reading_at.isAfter(moment().subtract(7,'days'))
          }
          else if(this.selectedDateRange==='Last 30 days') {
            return reading.reading_at.isAfter(moment().subtract(30,'days'))
          }
          else if(this.selectedDateRange==='All Time') {
            return true
          }
          else if(this.selectedDateRange==='Custom' && this.startDate && this.endDate) {
            return reading.reading_at.isSameOrAfter(moment(this.startDate)) && reading.reading_at.isSameOrBefore(moment(this.endDate + ' 23:59:59'))
          }
        });
    },
  },
  methods: {
    csvLink() {
      const file = new Blob([this.arrayToCSV], {type: 'text/csv'});
      return URL.createObjectURL(file);
    },
    getDeviceType(id) {
      const device = this.patient.devices.find(x => x.id === id);
      if (!device) return 'Unknown';
      return device.key;
    },
    getReadings(attribute) {
      return vitalsHelper.getReadings(this.vitalReadings, attribute);
    },
    getLatestReading(attribute) {
      if (attribute === "steps_daily") {
        const steps = this.getReadings(attribute).sort(function (a, b) {
          return b.reading_at - a.reading_at;
        })
        if (!steps.length) return null;
        const cutOff = moment(steps[0].reading_at).subtract(1, 'day');
        const sum = steps.filter(r => moment(r.reading_at).isSameOrAfter(cutOff)).reduce((acc, r) => +acc + +r.value, 0);
        return {
          value: sum,
          reading_at: steps[0].reading_at,
        };
      }
      if (attribute === "blood_pressure") {
        const systolic = this.getLatestReading("systolic");
        const diastolic = this.getLatestReading("diastolic");
        if (systolic && diastolic) {
          return {
            value: `${systolic.value}/${diastolic.value}`,
            reading_at: systolic.reading_at,
          };
        }
      }
      return this.getReadings(attribute).sort(function (a, b) {
        return b.reading_at - a.reading_at;
      })[0];
    },
    getAlerts(attribute) {
      const activeRpmCarePlan = this.patient.care_plans.find(care_plan => care_plan.active && care_plan.type === "rpm");
      if (activeRpmCarePlan && activeRpmCarePlan.alerts) {
        return activeRpmCarePlan.alerts.filter((alert) => {
          if (attribute === "blood_pressure") {
            return alert.type === "systolic" || alert.type === "diastolic";
          } else {
            return alert.type === attribute;
          }
        });
      } else {
        return [];
      }
    },
    setSelectedVital(vital) {
      this.selectedVital = vital;
    },
    isNormal(vital) {
      const alerts = this.getAlerts(vital.attribute);
      if (alerts.length) {
        let latestReading = vital.latestReading.value;
        if (vital.attribute === "blood_pressure") {
          latestReading = latestReading.split("/");
          const isNormalSystolic = this.isNormal({
            attribute: "systolic",
            latestReading: {value: latestReading[0]},
          });
          const isNormalDiastolic = this.isNormal({
            attribute: "diastolic",
            latestReading: {value: latestReading[1]},
          });
          return isNormalSystolic && isNormalDiastolic;
        } else {
          latestReading = Number(latestReading);
          return (
              latestReading >= Number(alerts[0].min) &&
              latestReading <= Number(alerts[0].max)
          );
        }
      } else {
        return true;
      }
    },
  },
  beforeMount() {
    this.loading = true;
    this.vitals = this.vitals.filter(
        (vital) => (vital.latestReading = this.getLatestReading(vital.attribute))
    );
    if (this.vitals.length > 0) {
      this.selectedVital = this.vitals[0];
    }
    this.loading = false;
  },
};
</script>

<style scoped>
.vital_icon {
  height: 60px !important;
  width: 60px;
}
</style>
