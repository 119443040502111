<script>
import moment from "moment-timezone";

export default {
  // name: 'MessageList',
  props: {
    messages: {},
    patient: {},
  },
  watch: {
    messages() {
      this.$nextTick(() => {
        this.$refs.scrollToMe.scrollIntoView({behavior: 'smooth'});
      });
    },
  },
  computed: {
    authUser() {
      return this.$store.state.patients.authUser
    },
  },
  methods: {
    imageSrc(id) {
      return this.userField(id, 'profilePicSmall');
    },
    senderName(id) {
      return `${this.userField(id, 'first_name')} ${this.userField(id, 'last_name')}`;
    },
    userField(id, field) {
      if (this.patient && id === this.patient.id) return this.patient[field];
      if (id === this.authUser.id) return this.authUser[field];
      const user = this.$store.state.patients.patients.find(p => p.id === id) || this.$store.state.patients.providers.find(p => p.id === id);
      if (user) return user[field];
    },
    diffForHumans(time) {
      if (!time) return '';
      return moment.duration(moment().diff(moment(time))).humanize()
    },
  },
}
</script>

<template>
  <ul role="list" class="-mb-8">
    <li v-if="!messages.length" class="p-8">
      <p>No Messages</p>
    </li>
    <li v-for="(message, messageIdx) in messages" v-bind:key="message.id">
      <div class="relative pb-8">
        <span v-if="messageIdx !== messages.length - 1" class="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"/>
        <div class="relative flex items-start space-x-3">
          <template v-if="message.type === 'sms'">
            <div class="relative">
              <img class="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white" :src="imageSrc(message.sender_id)" alt=""/>

              <span class="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
<!--                <ChatBubbleLeftEllipsisIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />-->
                <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 2c-2.236 0-4.43.18-6.57.524C1.993 2.755 1 4.014 1 5.426v5.148c0 1.413.993 2.67 2.43 2.902.848.137 1.705.248 2.57.331v3.443a.75.75 0 001.28.53l3.58-3.579a.78.78 0 01.527-.224 41.202 41.202 0 005.183-.5c1.437-.232 2.43-1.49 2.43-2.903V5.426c0-1.413-.993-2.67-2.43-2.902A41.289 41.289 0 0010 2zm0 7a1 1 0 100-2 1 1 0 000 2zM8 8a1 1 0 11-2 0 1 1 0 012 0zm5 1a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"/>
                </svg>
              </span>
            </div>
            <div class="min-w-0 flex-1">
              <div>
                <div class="text-sm">
                  <!--                  <a :href="activityItem.person.href" class="font-medium text-gray-900">{{ activityItem.person.name }}</a>-->
                  <span class="font-medium text-gray-900">{{ senderName(message.sender_id) }}</span>
                </div>
                <p class="mt-0.5 text-sm text-gray-500">Sent {{ diffForHumans(message.created_at) }} ago</p>
              </div>
              <div class="mt-2 text-sm text-gray-700">
                <p>{{ message.body_plain }}</p>
                <p v-for="file in message.attachments" :key="file.id"><img :src="file.src"/></p>
              </div>
            </div>
          </template>
          <!--          <template v-else-if="activityItem.type === 'assignment'">-->
          <!--            <div>-->
          <!--              <div class="relative px-1">-->
          <!--                <div class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">-->
          <!--                  <UserCircleIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="min-w-0 flex-1 py-1.5">-->
          <!--              <div class="text-sm text-gray-500">-->
          <!--                <a :href="activityItem.person.href" class="font-medium text-gray-900">{{ activityItem.person.name }}</a>-->
          <!--                {{ ' ' }}-->
          <!--                assigned-->
          <!--                {{ ' ' }}-->
          <!--                <a :href="activityItem.assigned.href" class="font-medium text-gray-900">{{ activityItem.assigned.name }}</a>-->
          <!--                {{ ' ' }}-->
          <!--                <span class="whitespace-nowrap">{{ activityItem.date }}</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </template>-->
          <!--          <template v-else-if="activityItem.type === 'tags'">-->
          <!--            <div>-->
          <!--              <div class="relative px-1">-->
          <!--                <div class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">-->
          <!--                  <TagIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="min-w-0 flex-1 py-0">-->
          <!--              <div class="text-sm leading-8 text-gray-500">-->
          <!--                <span class="mr-0.5">-->
          <!--                  <a :href="activityItem.person.href" class="font-medium text-gray-900">{{ activityItem.person.name }}</a>-->
          <!--                  {{ ' ' }}-->
          <!--                  added tags-->
          <!--                </span>-->
          <!--                {{ ' ' }}-->
          <!--                <span class="mr-0.5">-->
          <!--                  <template v-for="tag in activityItem.tags" :key="tag.name">-->
          <!--                    <a :href="tag.href" class="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">-->
          <!--                      <svg :class="[tag.color, 'h-1.5 w-1.5']" viewBox="0 0 6 6" aria-hidden="true">-->
          <!--                        <circle cx="3" cy="3" r="3" />-->
          <!--                      </svg>-->
          <!--                      {{ tag.name }}-->
          <!--                    </a>-->
          <!--                    {{ ' ' }}-->
          <!--                  </template>-->
          <!--                </span>-->
          <!--                <span class="whitespace-nowrap">{{ activityItem.date }}</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </template>-->
        </div>
      </div>
    </li>
    <li ref="scrollToMe"></li>
  </ul>
</template>