import Vue from 'vue';

export default {
  state: {
    loader: false,
    medicalSpecialities: []
  },
  mutations: {
    mutate(state, payload) {
      state[payload.property] = payload.with
    }
  },
  actions: {
    getAllConstants({ commit }) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get('/api/booking_search/specialties')
        .then((response) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          commit('mutate', {
            property: 'medicalSpecialities',
            with: response.data
          })
          resolve(response);
        })
        .catch((error) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          reject(error)
        })
      })
    }
  }
}