import axios from 'axios'

const http =  axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:8000/',
})

http.interceptors.request.use(config => {
  return config;
});

export default http;