import Vue from 'vue';
import { inflateData } from '@/helpers/functions';

export default {
  state: {
    loader: false,
    authUser: {}, // Auth user details
    clients: [],
    patients: [], //list of all patients for main dashboard page?? etc.
    patientsUpdatedTrigger: false, // used to trigger update of patients list
    providers: [],
    eligibilityResponse: {},
    templates: [],
    payers: [],
    tasks: [],
    legalForm: {},
    orderForms: [],
  },
  mutations: {
    mutate(state, payload) {
      state[payload.property] = payload.with
    },
    mutatePatientsUpdatedTrigger(state) {
      state.patientsUpdatedTrigger = !state.patientsUpdatedTrigger;
    },
  },
  getters: {
    authUserPrimaryClient: (state) =>  {
      return state.authUser.primary_clients ? state.authUser.primary_clients[0] : null;
    },
    clientName: (state) => (id) => {
      const c = state.clients.find(p => p.id === id);
      return c ? c.name : "";
    },
    providerName: (state) => (id) => {
      const p = state.providers.find(p => p.id === id);
      return p ? `${p.first_name} ${p.last_name}` : "";
    },
    providerEmail: (state) => (id) => {
      const p = state.providers.find(p => p.id === id);
      return p ? p.email : "";
    },
  },
  actions: {
    async getAuthUser({ commit }) {
      const data = { token: await this._vm.$auth.getTokenSilently() }

      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get(`/api/rpm_ccm_dashboard/get_auth_user`, {
          params: data
        })
            .then((res) => {
              commit('mutate', {
                property: 'loader',
                with: false
              })
              commit('mutate', {
                property: 'authUser',
                with: res.data.data
              })
              commit('mutate', {
                property: 'clients',
                with: res.data.data.available_clients
              });
              resolve(res)
            })
            .catch((err) => {
              commit('mutate', {
                property: 'loader',
                with: false
              })
              reject(err);
            })
      })
    },
    async getProviders({ commit }) {
      const data = { token: await this._vm.$auth.getTokenSilently() }

      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get(`/api/rpm_ccm_dashboard/get_providers`, {
          params: data
        })
          .then((res) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            commit('mutate', {
              property: 'providers',
              with: inflateData(res.data.data)
            })
            resolve(res)
          })
          .catch((err) => {
            commit('mutate', {
              property: 'loader',
              with: false
            })
            reject(err);
          })
      })
    },
    async getPatients({ commit }) {
      const data = { token: await this._vm.$auth.getTokenSilently() }

      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get(`/api/rpm_ccm_dashboard/get_patients`, {
          params: data
        })
        .then((res) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          commit('mutate', {
            property: 'patients',
            with: inflateData(res.data.data)
          })
          resolve(res)
          this.$store.commit('mutatePatientsUpdatedTrigger');
        })
        .catch((err) => {
          commit('mutate', {
            property: 'loader',
            with: false
          })
          reject(err);
        })
      })
    },
    async getTemplates({ commit }) {
      const data = { token: await this._vm.$auth.getTokenSilently() }

      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get(`/api/rpm_ccm_dashboard/dot_phrases`, {
          params: data
        })
            .then((res) => {
              commit('mutate', {
                property: 'loader',
                with: false
              })
              commit('mutate', {
                property: 'templates',
                with: inflateData(res.data.data)
              })
              resolve(res)
            })
            .catch((err) => {
              commit('mutate', {
                property: 'loader',
                with: false
              })
              reject(err);
            })
      })
    },
    async getPayers({ commit }) {
      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get(`/api/rpm_ccm_dashboard/get_payers`)
            .then((res) => {
              commit('mutate', {
                property: 'loader',
                with: false
              })
              commit('mutate', {
                property: 'payers',
                with: inflateData(res.data.data)
              })
              resolve(res)
            })
            .catch((err) => {
              commit('mutate', {
                property: 'loader',
                with: false
              })
              reject(err);
            })
      })
    },
    async getTasks({ commit }) {
      const data = { token: await this._vm.$auth.getTokenSilently() }

      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get(`/api/rpm_ccm_dashboard/get_tasks`, {
          params: data
        })
            .then((res) => {
              commit('mutate', {
                property: 'loader',
                with: false
              })
              commit('mutate', {
                property: 'tasks',
                with: inflateData(res.data.data),
              })
              resolve(res)
            })
            .catch((err) => {
              commit('mutate', {
                property: 'loader',
                with: false
              })
              reject(err);
            })
      })
    },
    async getLegalForm({ commit }) {
      const data = { token: await this._vm.$auth.getTokenSilently() }

      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get(`/api/rpm_ccm_dashboard/get_legal_form`, {
          params: data
        })
            .then((res) => {
              commit('mutate', {
                property: 'loader',
                with: false
              })
              commit('mutate', {
                property: 'legalForm',
                with: res.data.data
              })
              resolve(res)
            })
            .catch((err) => {
              commit('mutate', {
                property: 'loader',
                with: false
              })
              reject(err);
            })
      })
    },
    async getOrderForms({ commit }) {
      const data = { token: await this._vm.$auth.getTokenSilently() }

      commit('mutate', {
        property: 'loader',
        with: true
      })
      return new Promise((resolve, reject) => {
        Vue.$http.get(`/api/rpm_ccm_dashboard/get_order_forms`, {
          params: data
        })
            .then((res) => {
              commit('mutate', {
                property: 'loader',
                with: false
              })
              commit('mutate', {
                property: 'orderForms',
                with: res.data.data
              })
              resolve(res)
            })
            .catch((err) => {
              commit('mutate', {
                property: 'loader',
                with: false
              })
              reject(err);
            })
      })
    },
  }
}